<template>
    <div class="outcall-task">
        <div class="head">
            <span class="title">{{moduleName}}</span>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="content-in">
                <div class="modular-content">
                    <div class="modular-in">
                        <div class="modular-operation">
                            <div class="operation-group">
                                <ScreenColumn ref="child3"></ScreenColumn>
                                <a-divider class="divider" type="vertical" />
                                <a-button class="add-data" type="primary" @click="addData">
                                    <a-icon type="plus" />
                                    新增
                                </a-button>
                                <Export v-if="operationAuthority.EXPORT" ref="child4"></Export>
                                <MoreOperation ref="child5"></MoreOperation>
                            </div>
                        </div>
                        <div class="table-data">
                            <a-table
                                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                                :columns="columns"
                                :data-source="tableData"
                                :pagination='false'
                                :scroll="{ x: 'max-content' }"
                                :loading='loading'
                            >
                                <span slot="dataTitle" slot-scope="text, record">
                                    <a @click="viewRecords(record)">{{ record.dataTitle }}</a>
                                </span>
                                <span slot="address" slot-scope="text, record">
                                    <a @click="getUrl(record.address)">获取地址</a>
                                </span>
                                <span slot="users" slot-scope="text, record">
                                    <span v-for="(item,index) in record.users" :key='index' :class="{'online-type':item.type=='online','offline-type':item.type=='offline'}">{{item.value}}</span>
                                </span>
                            </a-table>
                            <div class="pagination-table">
                                <a-pagination
                                    show-size-changer
                                    :total="total"
                                    :show-total="total => `共 ${total} 条     `"
                                    v-model="currentPage"
                                    @change="pageChange"
                                    @showSizeChange="onShowSizeChange"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <textarea style="position:fixed;z-index:-10000;left:-1000" id="copyHtml1"></textarea>
        <a-drawer
            title='待呼号码-手机号码'
            placement="right"
            :closable="true"
            :visible="drawerVisible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span :title="digitalDetail.moduleName+'-'+digitalDetail.dataTitle" class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="onClose" type="close" />
                        </div>
                    </div>
                    <a-row class="row-head">
                        <a-col :span="8" class="col-left">
                            <div>创建人：</div>
                            <div>{{digitalDetail.createBy}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>创建时间：</div>
                            <div>{{digitalDetail.createTime}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>修改时间：</div>
                            <div>{{digitalDetail.updateTime}}</div>
                        </a-col>
                    </a-row>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    <div class="first-content">
                                        {{item.name}}:
                                    </div>
                                    <div v-if="item.type!='CALLING_NUMBER_USER_LIST'&&item.type!='WIRING_GROUP_ADDRESS'&&item.code!='name'&&item.code!='users'&&item.code!='status'" class="second-content">{{item.value}}</div>
                                    <div v-if="!ifEditUserDetail&&item.code=='status'" class="second-content">{{item.value}}</div>
                                    <div v-if="!ifEditUserDetail&&item.type=='CALLING_NUMBER_USER_LIST'" class="second-content">{{item.value}}</div>
                                    <div class="affect-list" v-if="item.type=='CALLING_NUMBER_USER_LIST'&&ifEditUserDetail">
                                        <div class="second-content calling-number">
                                            <span v-for="(item1,index1) in child1Data.personSelected" :key="index1" style="margin-right:6px">{{deptAndUserMap[item1+'']}}</span>
                                        </div>
                                        <a-icon @click="chooseAffected" class="affect-icon" type="zoom-in" />
                                    </div>
                                    <div v-if="item.type=='WIRING_GROUP_ADDRESS'" class="second-content">
                                        <span @click="getSite(item.value)" style="cursor:pointer;color:rgba(42, 130, 228, 1)">获取地址</span>
                                    </div>
                                    <div v-if="!ifEditUserDetail&&item.code=='name'" class="second-content">{{item.value}}</div>
                                    <div v-if="ifEditUserDetail&&item.code=='name'" class="second-content">
                                        <a-input :placeholder="item.value" v-model="dataObj.name" />
                                    </div>
                                    <div v-if="!ifEditUserDetail&&item.code=='users'" class="second-content">{{item.value}}</div>
                                    <div v-if="ifEditUserDetail&&item.code=='users'" class="second-content">
                                        <div class="skill-member">
                                            <span v-for="(item,index) in dataObj.userList" :key='index'>{{passDataMember.deptAndUserMap[item]}}</span>
                                        </div>
                                        <a-icon @click="addSkillMember('edit')" class="zoom-in" type="zoom-in" />
                                    </div>
                                    <div class="second-content" v-if="item.code=='status'&&ifEditUserDetail&&item.isEditable==1" style="display:flex">
                                        <a-select v-model="dataObj.status" style="flex:1">
                                            <a-select-option v-for="(item1,index1) in item.selectList" :key="index1" :value="item1.value">
                                                {{item1.name}}
                                            </a-select-option>
                                        </a-select>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                         <div class="comments">
                            <Comments ref="commentChild"></Comments>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button type="primary" v-if="operationAuthority.DELETE" ghost @click="deleteDrawer">删除</a-button>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div v-if="!ifEditUserDetail" class="btn-group">
                        <a-button type="primary" @click="editDrawer">编辑</a-button>
                    </div>
                    <div v-if="ifEditUserDetail" class="btn-group">
                        <a-button type="primary" ghost @click="cancelInfoDrawer">取消</a-button>
                        <a-button type="primary" @click="sureDrawer">保存</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-drawer
            title='模块名称-新增数据'
            placement="right"
            :closable="true"
            :visible="visible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose1"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '100'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '72px 0 0' }"
        >
            <div class="drawer-set drawer-public2">
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <a-row class="row-list">
                            <a-col :span="12" class="col-left">
                                <div class="title">
                                    <span>技能组名称：</span>
                                </div>
                                <a-input class="content" v-model="dataObj.name" placeholder="请输入名称" />
                            </a-col>
                            <a-col :span="12" class="col-right">
                                <div class="title">
                                    <span>技能组成员：</span>
                                </div>
                                <div class="clean"></div>
                                <div>
                                    <div class="skill-member">
                                        <span v-for="(item,index) in dataObj.userList" :key='index'>{{passDataMember.deptAndUserMap[item]}}</span>
                                    </div>
                                    <a-icon @click="addSkillMember('new')" class="zoom-in" type="zoom-in" />
                                </div>
                            </a-col>
                        </a-row>
                        <!-- <a-row class="row-list">
                            <a-col :span="12" class="col-left">
                                <div class="title">
                                    <span>逃生号码：</span>
                                </div>
                                <a-input class="content" v-model="dataObj.escapeNumber" placeholder="请输入号码" />
                            </a-col>
                        </a-row> -->
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button type="primary" ghost @click="cancelDrawer">取消</a-button>
                        <a-button type="primary" @click="sureDrawer(false)">提交</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="offBindAffected" footer='' title="影响到的人" width='720px'>
            <div class="offbindaffect-modal modal-public">
                <BeAffectedPerson ref="child1"></BeAffectedPerson>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelBindAffected">取消</a-button>
                    <a-button type="primary" @click="sureBindAffected">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="memberTipModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>技能组人数不能为空</span>
                </div>
                <div class="tip">技能组人数必须至少有一人</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelMember">取消</a-button>
                    <a-button type="primary" @click="sureMember">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="skillGroupMemberModal" footer='' title="技能组成员" width='720px'>
            <div class="offbindaffect-modal modal-public">
                <SkillGroupPerson ref="child6"></SkillGroupPerson>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelSkillGroupMember">取消</a-button>
                    <a-button type="primary" @click="sureSkillGroupMember">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="screenModal" title="链接地址" footer='' width='720px'>
            <div class="share-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="copy-link">
                    <a-input id="copyHtml2" readonly="readonly" :placeholder="linkUrl" />
                    <span @click="copyLink(linkUrl)">复制链接</span>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
    import ScreenColumn from '../../components/ScreenColumn'
    import Export from '../../components/Export'
    import MoreOperation from '../../components/MoreOperation'
    import moment from 'moment'
    import BeAffectedPerson from '../../components/BeAffectedPerson';
    import SkillGroupPerson from '../../components/SkillGroupPerson';
    import Comments from "../../components/Comments";
    const columns = []
    const tableData = []
    export default {
        name: 'OutCallTask',
        components: { ScreenColumn, Export, BeAffectedPerson, MoreOperation, SkillGroupPerson, Comments },
        data () {
            return {
                moduleId: '',
                showField: {
                    fieldList: [],
                    valueList: []
                },
                operationAuthority: '',
                conditionList: [],
                currentPage: 1,
                pageSize: 10,
                loading: false,
                tableData,
                columns,
                total: 0,
                selectedRowKeys: [],
                drawerVisible: false,
                digitalDetail: {
                    dataTitle: ''
                },
                ifEditUserDetail: false,
                offBindAffected: false,
                passDataObjOwner: {
                    peopleTree: null,
                    personSelected: null
                },
                child1Data: {
                    personSelected: [],
                },
                deptAndUserMap: '',
                visible: false,
                dataObj: {
                    type: '',
                    groupId: undefined,
                    name: '',
                    // escapeNumber: '',
                    userList: [],
                    status: ''
                },
                memberTipModal: false,
                skillGroupMemberModal: false,
                passDataMember: {
                    peopleTree: null,
                    personSelected: null
                },
                screenModal: false,
                linkUrl: '',
                moduleName: ''
            }
        },
        methods: {
            moment,
            queryColumnList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.moduleId+'/listPage/init', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.operationAuthority = response.data.data.operationAuthority
                        this.showField.fieldList = response.data.data.showField.fieldList
                        this.showField.valueList = response.data.data.showField.valueList
                        this.conditionList = response.data.data.conditionList
                        this.$refs.child3.getData(this.showField,this.moduleId,this.conditionList)
                        this.$refs.child5.getScreenData(this.moduleId,this.conditionList,this.operationAuthority)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryBasicData(type) {
                this.loading = true
                if(type == 'init'){
                    this.currentPage = 1
                }
                this.axios.post('/api/module/data/list', {
                    current: this.currentPage,
                    size: this.pageSize,
                    moduleId: this.moduleId,
                    conditionList: this.$refs.child3.conditionList
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.loading = false
                        this.tableData = response.data.data.tableData.records
                        for(let i = 0; i < this.tableData.length; i++){
                            if(this.tableData[i]['users']) {
                                let arr = this.tableData[i]['users'].split(",")
                                let brr = []
                                for(let j = 0; j < arr.length; j++){
                                    let obj = {}
                                    let str = arr[j].split("-")[1]
                                    if(str == '1'){
                                        obj.type = 'online'
                                    }else if(str == '2'){
                                        obj.type = 'offline'
                                    }
                                    obj.value = arr[j].split("-")[0]
                                    brr.push(obj)
                                }
                                this.tableData[i]['users'] = brr
                            }
                        }
                        this.columns = response.data.data.tableHeader
                        for(let i = 0; i < this.columns.length; i++){
                            if(this.columns[i]['title'] == 'id'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    colSpan:0,  
                                    customRender: () => {
                                        return {
                                            attrs: {
                                                colSpan: 0,
                                            },
                                        };
                                    },
                                }
                            }
                            if(this.columns[i]['dataIndex'] == 'dataTitle'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    scopedSlots: { customRender: 'dataTitle' },
                                }
                            }
                        }
                        this.total = response.data.data.tableData.total
                        setTimeout(()=>{
                            this.$refs.child4.getTotalNum(response.data.data.tableData.total, this.moduleId)
                        },2000)
                        
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            onSelectChange(selectedRowKeys) {
                let obj = {}
                obj.deptId = ''
                obj.conditionList = this.$refs.child3.conditionList
                this.selectedRowKeys = selectedRowKeys;
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj)
                this.$refs.child5.getData(this.moduleId, this.selectedRowKeys)
            },
            passDataToChild() {
                let obj = {}
                obj.deptId = ''
                // obj.dataRange = this.$refs.child2.selectedDataRangeValue
                obj.conditionList = this.$refs.child3.conditionList
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj)
            },
            pageChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            onShowSizeChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            viewRecords(record) {
                this.drawerVisible = true;
                this.currentId = record.id
                this.querySelectData()
            },
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        let arr = response.data.data.detailList
                        this.$refs.commentChild.getParentCommentTree(this.moduleId, this.currentId, true)
                        this.dataObj.type = 'edit'
                        for(let i = 0; i < arr.length; i++){
                            if(arr[i]['type'] == 'CALLING_NUMBER_USER_LIST'){
                                this.passDataObjOwner.peopleTree = arr[i]['userTree']
                                this.passDataObjOwner.personSelected = arr[i]['userValueList']
                                this.child1Data.personSelected = arr[i]['userValueList']
                            }
                            if(arr[i]['code'] == 'name'){
                                this.dataObj.name = arr[i]['value']
                            }
                            // if(arr[i]['code'] == 'escape_number'){
                            //     this.dataObj.escapeNumber = arr[i]['value']
                            // }
                            if(arr[i]['code'] == 'status'){
                                this.dataObj.status = arr[i]['selectedValue']
                            }
                        }

                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onClose() {
                this.drawerVisible = false
            },
            deleteDrawer() {
                this.drawerVisible = false
                this.axios.post('/api/module/data/delete/batch', {
                    moduleId: this.moduleId,
                    idList: [this.currentId],
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            editDrawer() {
                this.ifEditUserDetail = true
                this.querySelectMember('edit')
            },
            cancelInfoDrawer() {
                this.ifEditUserDetail = false
            },
            cancelBindAffected() {
                this.offBindAffected = false
            },
            sureBindAffected() {
                this.child1Data.personSelected = this.$refs.child1.personSelected
                this.offBindAffected = false
            },
            chooseAffected() {
                this.offBindAffected = true
                setTimeout(()=>{
                    this.$refs.child1.getParentData(this.passDataObjOwner)
                })
            },
            initDrawer() {
                this.currentId = ''
                this.child1Data.personSelected = []
                this.passDataObjOwner.personSelected = null
            },
            queryDeptAndUserMap() {
                 this.axios(
                    {
                        method:'get',
                        url:'/api/user/deptAndUserMap', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.deptAndUserMap = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onClose1() {
                this.visible = false;
                this.dataObj = {
                    type: '',
                    groupId: undefined,
                    name: '',
                    // escapeNumber: '',
                    userList: [],
                    status: ''
                }
            },
            addData() {
                this.visible = true
                this.dataObj.type = 'new'
            },
            cancelDrawer() {
                this.visible = false
                this.dataObj = {
                    type: '',
                    groupId: undefined,
                    name: '',
                    // escapeNumber: '',
                    userList: [],
                    status: ''
                }
            },
            sureDrawer() {
                this.visible = false
                this.ifEditUserDetail = false
                this.drawerVisible = false
                this.axios.post('/api/wiringGroup', {
                    groupId: this.dataObj.type=='new'?undefined:this.currentId,
                    name: this.dataObj.name,
                    // escapeNumber: this.dataObj.escapeNumber==''?123:this.dataObj.escapeNumber,
                    userList: this.dataObj.userList,
                    status: this.dataObj.status
                })
                .then(response=> {
                    if(response.data.code == 0){
                        let tip = ''
                        if(this.dataObj.type=='new'){
                            tip = '新增成功'
                        }else{
                            tip = '修改成功'
                        }
                        this.dataObj = {
                            type: '',
                            groupId: undefined,
                            name: '',
                            // escapeNumber: '',
                            userList: [],
                            status: ''
                        }
                        this.queryBasicData('init')
                        this.$message.success(tip)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelMember() {
                this.memberTipModal = false
            },
            sureMember() {
                this.memberTipModal = false
            },
            getUrl(url) {
                let text = url
                let input = document.getElementById("copyHtml1");
                input.value = text; 
                input.select(); 
                document.execCommand("copy"); 
                this.$message.success('获取成功');
            },
            cancelSkillGroupMember() {
                this.skillGroupMemberModal = false
            },
            sureSkillGroupMember() {
                this.dataObj.userList = this.$refs.child6.personSelected
                this.skillGroupMemberModal = false
            },
            addSkillMember(type) {
                this.skillGroupMemberModal = true
                this.querySelectMember(type)
            },
            querySelectMember(type) {
                this.axios(
                    {
                        method:'get',
                        url:'/api/wiringGroup/subordinateUserTree', 
                        params: {
                            groupId: type=='new'?'':this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.passDataMember.peopleTree = response.data.data.userTree
                        this.passDataMember.personSelected = response.data.data.chooseUserList
                        this.passDataMember.deptAndUserMap = response.data.data.deptAndUserMap
                        this.child1Data.personSelected = response.data.data.chooseUserList
                        this.dataObj.userList = response.data.data.chooseUserList
                        this.$refs.child6.getParentData(this.passDataMember)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            getSite(url) {
                this.screenModal = true
                this.linkUrl = url
            },
            copyLink(url) {
                this.screenModal = false
                let text = url
                let input = document.getElementById("copyHtml2");
                input.value = text; 
                input.select(); 
                document.execCommand("copy"); 
                this.$message.success('已复制！')
            },
        },
        mounted: function() {
            this.sessionData('set','personalCenter','wiringGroup');
            this.moduleId = this.$route.query.moduleId
            this.moduleName = this.$route.query.name
            this.queryColumnList()
            this.queryBasicData('init')
            this.queryDeptAndUserMap()
        }
    }
</script>

<style scoped lang="less">
    .outcall-task{
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            overflow-y: auto;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    border-radius: 4px;
                    .modular-in{
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        border-radius: 4px;
                        overflow-y: auto;
                        padding: 24px;
                        .modular-operation{
                            margin-top: 21px;
                            .operation-group{
                                display: inline-block;
                                float: right;
                                .add-data{
                                    margin-right: 8px;
                                }
                                .divider{
                                    margin: 0 16px 0 0;
                                    height: 32px;
                                }
                                .download{
                                    margin: 0 8px;
                                }
                            }
                        }
                        .table-data{
                            margin-top: 64px;
                            .online-type{
                                display: inline-block;
                                color: #fff;
                                font-size: 13px;
                                background: rgba(35, 218, 119, 1);
                                height: 28px;
                                line-height: 28px;
                                padding: 0 12px;
                                margin-right: 8px;
                            }
                            .offline-type{
                                display: inline-block;
                                color: rgba(121, 136, 155, 1);
                                font-size: 13px;
                                background: rgba(121, 136, 155, 0.1);
                                height: 28px;
                                line-height: 28px;
                                padding: 0 12px;
                                margin-right: 8px;
                            }
                        }
                        .pagination-table{
                            float: right;
                            padding-top: 24px;
                        }
                    }
                }
            }  
        }
    }
    .drawer-public{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 144px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;
        }
        .drawer-content{
            padding: 160px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-public2{
        height: 100%;
        // display: flex;
        position: relative;
        .drawer-content{
            padding: 0 16px 68px;
            width: 100%;
            .drawer-body-content{
                width: 100%;
                height: 500px;
                padding: 24px;
                background: #fff;
                border-radius: 4px;
            }
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
        .skill-member{
            display: inline-block;
            width: 304px;
            height: 32px;
            border: 1px solid rgba(121, 136, 155, 0.3);
            border-radius: 4px;
            line-height: 30px;
            padding-left: 11px;
            text-overflow: ellipsis;
            span{
                margin-right: 8px;
            }
        }
        .zoom-in{
            margin-right: 4px;
            float: right;
            cursor: pointer;
            font-size: 16px;
            margin-top: 8px;
            color: rgba(121, 136, 155, 1);
        }
    }
    .drawer-set{
        .row-list{
            margin-bottom: 13px;
            .col-left{
                padding-right: 24px;
            }
            .col-right{
                padding-left: 24px;
            }
            .title{
                color: rgba(188, 188, 188, 1);
                font-size: 12px;
                margin-bottom: 9px;
                float: left;
                span{
                    float: left;
                }
                .icon{
                    color: rgba(227, 60, 100, 1);
                    margin-left: 4px;
                }
            }
            .original-password{
                padding-top: 4px;
            }
            .select-item{
                width: 100%;
            }
            .content{
                color: rgba(51, 51, 51, 1);
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                div:nth-child(1){
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                div:nth-child(2){
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding-right: 16px;
            .basic-info{
                width: 100%;
                // height: 704px;
                background: #fff;
                border-radius: 4px;
                padding: 24px;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        height: 69px;
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            img{
                                width: 21px;
                            }
                            .change-head{
                                color: rgba(24, 144, 255, 1);
                                font-size: 12px;
                                cursor: pointer;
                                margin-left: 24px;
                            }
                        }
                        .affect-list{
                            display: flex;
                            .calling-number{
                                flex: 1;
                                height: 32px;
                                border: 1px solid #d9d9d9;
                                border-radius: 4px;
                                overflow: hidden;
                                line-height: 32px;
                                padding-left: 12px;
                                margin-top: 4px !important;
                            }
                            .affect-icon{
                                color: rgba(121, 136, 155, 1);
                                font-size: 20px;
                                float: right;
                                margin-top: 10px;
                                cursor: pointer;
                                width: 20px;
                                margin-left: 12px;
                                display: inline-block;
                            }
                        }
                        .selection-range{
                            margin-top: 9px;
                            width: 100%;
                        }
                    }
                }
            }  
            .skill-member{
                display: inline-block;
                width: 304px;
                height: 32px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                border-radius: 4px;
                line-height: 30px;
                padding-left: 11px;
                text-overflow: ellipsis;
                span{
                    margin-right: 8px;
                }
            }
            .zoom-in{
                margin-right: 4px;
                float: right;
                cursor: pointer;
                font-size: 16px;
                margin-top: 8px;
                color: rgba(121, 136, 155, 1);
            }
            .comments{
                padding: 18px 24px;
                background: #fff;
                margin-top: 16px;
                border-radius: 4px;
                .title{
                    color: rgba(18, 18, 18, 1);;
                    font-size: 14px;
                }
                .textarea-comment{
                    margin-top: 20px;
                    background: rgba(247, 247, 247, 1);
                    border: none;
                    resize: none;
                }
                .release{
                    text-align: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    margin-top: 4px;
                    span{
                        cursor: pointer;
                    }
                }
            }  
        }
    }
    .offbindaffect-modal{
        border-top: 1px solid #e8e8e8;
        padding-top: 24px;
        padding-bottom: 46px;
        .affect-modal-left{
            width: 100%;
            height: 100%;
            border-right: 1px solid #e8e8e8;
            padding-right: 24px;
            .department-tree,.person-tree{
                height: 300px;
                overflow-x: hidden;
                overflow-y: auto;
                padding-top: 16px;
            }
        }
        .affect-modal-right{
            width: 100%;
            height: 100%;
            padding-left: 24px;
            .title{
                margin-bottom: 24px;
                span:nth-child(1){
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                }
                span:nth-child(2){
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    cursor: pointer;
                    float: right;
                }
            }
            .selected-list{
                height: 346px;
                overflow-y: auto;
                .selected-item{
                    display: inline-block;
                    height: 32px;
                    padding: 0 6px;
                    background: rgba(121, 136, 155, 0.1);
                    border-radius: 4px;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    line-height: 32px;
                    img:nth-child(1){
                        color: rgba(121, 136, 155, 1);
                        width: 18px;
                        float: left;
                        margin-top: 7px;
                    }
                    span:nth-child(2){
                        color: rgba(80, 80, 80, 1);
                        font-size: 14px;
                        margin-left: 4px;
                    }
                    i:nth-child(3){
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .offbindwx-modal{
        padding-top: 24px;
        padding-bottom: 46px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 44px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .share-modal{
        .copy-link{
            height: 62px;
            input{
                width: 582px;
                float: left;
                height: 40px;
                background: rgba(121, 136, 155, 0.08);

            }
            span{
                float: left;
                display: inline-block;
                width: 88px;
                height: 40px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                cursor: pointer;
                border-radius: 0px 4px 4px 0px;
                line-height: 40px;
                text-align: center;
            }
        }
    }
</style>