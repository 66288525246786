<template>
    <div class="call-log">
        <div class="head">
            <span class="title">坐席统计</span>
            <span @click="goBack" style="display: inline-block;width:64px;height: 32px;border:1px solid rgba(121, 136, 155, 1);line-height: 30px;text-align: center;color:rgba(121, 136, 155, 1);font-size:14px;border-radius: 4px;cursor: pointer;float: right;margin:8px 0 0 8px;">返回</span>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="filter-condition">
                <a-row>
                    <a-col :span="6">
                        <span>选择报表</span>
                        <span style="color:rgba(255, 70, 70, 1);margin:0 16px 0 2px;">*</span>
                        <a-select style="width: 180px" v-model="type" placeholder="请选择报表类型">
                            <a-select-option value="1">
                                日报
                            </a-select-option>
                            <a-select-option value="2">
                                周报
                            </a-select-option>
                            <a-select-option value="3">
                                月报
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="7">
                        <span>时间范围</span>
                        <a-range-picker format="YYYY-MM-DD" @change="dateChange" style="margin-left: 16px;width: 260px;" :disabledDate="disabledDate"   v-model="dateRange" />
                    </a-col>
                    <a-col :span="7">
                        <span class="title">数据范围</span>
                        <a-tree-select
                            v-model="selectedDataRangeValue"
                            style="width: 220px;display:inline-block;margin-left: 16px;"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="dataRange"
                            placeholder="请选择数据范围"
                            tree-default-expand-all
                        >
                        </a-tree-select>
                    </a-col>
                    <a-col :span="4" style="text-align: right;">
                        <a-button @click="reSetCondition" type="default" style="color:rgba(24, 144, 255, 1);border:1px solid rgba(24, 144, 255, 1);"> 重置 </a-button>
                        <a-button @click="queryData" type="primary" style="margin-left: 24px;"> 查询 </a-button>
                    </a-col>
                </a-row>
            </div>
            <div style="flex: 1;background:#fff;margin-top:16px;border-radius: 4px;box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);overflow-y: auto;padding: 24px;">
                <a-table rowKey="downloadUrl" :data-source="seatStatisticsData" :pagination='false' >
                    <a-table-column key="statisticTime" title="统计时间" data-index="statisticTime" />
                    <a-table-column key="extensionCode" title="员工工号" data-index="extensionCode" />
                    <a-table-column key="name" title="员工名称" data-index="name" />
                    <a-table-column key="outTotalCount" title="呼出量" data-index="outTotalCount" />
                    <a-table-column key="outSuccessCount" title="呼出接通量" data-index="outSuccessCount" />
                    <a-table-column key="outSuccessPer" title="呼出接通率" data-index="outSuccessPer" />
                    <a-table-column key="outDuration" title="呼出通话总时长" data-index="outDuration" />
                    <a-table-column key="outDurationAvg" title="呼出通话平均时长" data-index="outDurationAvg" />
                    <a-table-column key="inTotalCount" title="呼入量" data-index="inTotalCount" />
                    <a-table-column key="inSuccessCount" title="呼入接通量" data-index="inSuccessCount" />
                    <a-table-column key="inSuccessPer" title="呼入接通率" data-index="inSuccessPer" />
                    <a-table-column key="inDuration" title="呼入通话总时长" data-index="inDuration" />
                    <a-table-column key="inDurationAvg" title="呼入通话平均时长" data-index="inDurationAvg" />
                    <a-table-column key="totalDuration" title="通话总时长" data-index="totalDuration" />
                </a-table>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        name: 'CallLog',
        components: {},
        data() {
            return {
                type: undefined,
                startTime: '',
                endTime: '',
                seatStatisticsData: [],
                dateRange: [],
                dataRange: [],
                selectedDataRangeValue: undefined,
            }   
        },
        methods: {
            moment,
            disabledDate(current) {
                if (this.dateRange.length === 1) {
                    // 计算选择的日期范围
                    const startDate = this.dateRange[0];
                    const endDate = current;
                    
                    // 计算日期范围的天数差
                    const diffInDays = Math.abs((endDate - startDate) / (1000 * 60 * 60 * 24));
                    
                    // 如果日期范围超过31天，则禁用当前日期
                    return diffInDays > 31;
                }
                return false; // 允许选择所有日期
            },
            dateChange(dates, dateString) {
                this.startTime = dateString[0]
                this.endTime = dateString[1]
                if (dates && dates.length === 2) {
                    const startDate = dates[0];
                    const endDate = dates[1];
                    // 检查所选日期范围是否超过31天
                    const diffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
                    if (diffInDays > 31) {
                        this.dateRange = []; // 清空选择
                        this.$message.error('所选日期范围不能超过31天。');
                        return false
                    }
                }
            },
            queryColumnList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/autoCallTask/extensionStatisticList', 
                        params: {
                            type: this.type,
                            timeQ: this.startTime,
                            timeZ: this.endTime,
                            dataRange: this.selectedDataRangeValue
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.seatStatisticsData = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            goBack() {
                this.$router.go(-1); // 返回上一个页面
            },
            reSetCondition() {
                this.type = undefined
                this.dateRange = []
                this.startTime = ''
                this.endTime = ''
                this.selectedDataRangeValue = undefined
            },
            queryData() {
                if(this.startTime == ''||this.endTime == ''){
                    this.$message.warning('请选择时间范围');
                }else{
                    this.queryColumnList()
                }
            },
            queryDataRange() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/user/dataRange', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.dataRange = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
        },
        mounted: function() {
            this.sessionData('set','publicMenu','robotBill');
            this.queryDataRange()
        }
    }
</script>

<style scoped lang="less">
    .call-log{
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            padding: 16px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            display: flex;
            flex-direction: column;
            .filter-condition{
                height: 80px;
                padding: 24px 24px 0 24px;
                color: rgba(18, 18, 18, 1);
                font-size: 14px;
                background: #fff;
                border-radius: 4px;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            }
        }
    }
    
</style>