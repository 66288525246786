<template>
    <div class="public-personal-center">
        <div class="left-menu">
            <a-menu mode="inline" :open-keys="openKeys" style="width: 100%" @openChange="onOpenChange" :selected-keys="[currentKey]">
                <a-menu-item v-for="item in menuList" :key="item.key" :class="{'hidemenu':item.moduleId==0}" :title="item.key">
                    <div @click="chooseItem(item.key)">
                        <router-link :to="{path:'/'+item.component, query:{moduleId:item.moduleId}}">
                            <a-icon :type="item.icon" />
                            <span>{{item.title}}</span>
                        </router-link>
                    </div>
                </a-menu-item>
                <a-sub-menu v-for="item in menuList" :key="item.component" :class="{'hidemenu':item.moduleId!=0}">
                    <span slot="title">
                        <a-icon :type="item.icon" />
                        <span>{{item.title}}</span>
                    </span>
                    <a-menu-item v-for="item1 in item.children" :key='item1.key'>
                        <div @click="chooseItem(item1.key)">
                            <router-link :to="{path:'/'+item1.component, query:{moduleId:item1.moduleId,name:item1.title}}">
                            {{item1.title}}
                            </router-link>
                        </div>
                    </a-menu-item>
                </a-sub-menu>
            </a-menu>
        </div>
        <div class="right-content">
            <router-view @fatherMethod='fatherMethod'></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PublicMenu',
        data() {
            return {
                menuList: [],
                rootSubmenuKeys: [],
                openKeys: [],
                currentKey: "",
            }
        },
        methods: {
            chooseItem(item) {
                this.currentKey = item
                sessionStorage.setItem('menuKey', item)
            },
            queryMenuList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/menu/list', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.menuList = response.data.data
                        for(let i = 0; i < this.menuList.length; i++){
                            if(this.menuList[i]['moduleId'] == 0){
                                this.openKeys.push(this.menuList[i]['component'])
                                this.rootSubmenuKeys.push(this.menuList[i]['component'])
                            }
                            if(this.currentKey == undefined){
                               if(this.menuList[i]['component']=='digitalEmployee'){
                                    this.currentKey = this.menuList[i]['key']
                                }
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            fatherMethod(val) {
                this.$emit('grandMethod',val)
            },
            onOpenChange(openKeys) {
                const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
                if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                    this.openKeys = openKeys;
                } else {
                    this.openKeys = latestOpenKey ? [latestOpenKey] : [];
                }
            },
        },
        mounted: function() {
            this.currentKey = sessionStorage.getItem('menuKey')
            this.queryMenuList()
        },
    }
</script>

<style scoped lang="less">
    .public-personal-center{
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(240, 242, 243, 1);
        .left-menu{
            width: 180px;
            height: 100%;
            background: #fff;
            .back-to{
                height: 48px;
                line-height: 48px;
                padding-left: 18px;
                cursor: pointer;
                i{
                    margin-right: 6px;
                }
            }
            .hidemenu{
                display: none;
            }
            .ant-menu-item{
                a{
                    color: rgba(0, 0, 0, 0.65);
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }
            }
            .ant-menu-item-selected{
                a{
                    color: #1890ff;
                }
            }
        }
        .right-content{
            flex: 1;
            height: 100%;
        }
    }
</style>