<template>
    <div class="call-log">
        <div class="head">
            <span class="title">{{moduleName}}</span>
            <a-button v-if="operationAuthority.NOTICE" type="primary" @click="pushSet">
                推送设置
            </a-button>
            <a-button v-if="operationAuthority.SHARE" type="primary" @click="shareSet">
                共享设置
            </a-button>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="content-in">
                <!-- <div class="screen-condition">
                    <div class="select-modular">
                        <span class="title">场景名称</span>
                        <span style="color:rgba(255, 70, 70, 1);margin-left:4px">*</span>
                        <a-select default-value="" class="selection-modular" @change="changeSence">
                            <a-select-option :value="item.value" v-for="(item,index) in digitalEmployeeList" :key='index'>
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                    </div>
                </div> -->
                <!-- <div class="extra-content"></div> -->
                <div class="modular-content">
                    <div class="modular-in">
                        <ViewGroup ref="child1"></ViewGroup>
                        <div class="modular-operation">
                            <DataRange ref="child2"></DataRange>
                            <div class="operation-group">
                                <ScreenColumn ref="child3"></ScreenColumn>
                                <a-divider class="divider" type="vertical" />
                                <Export v-if="operationAuthority.EXPORT" ref="child4"></Export>
                            </div>
                        </div>
                        <div class="table-data">
                            <a-table
                                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                                :columns="columns"
                                :data-source="tableData"
                                :pagination='false'
                                :scroll="{ x: 'max-content' }"
                                :loading='loading'
                            >
                                <span slot="dataTitle" slot-scope="text, record">
                                    <a-icon style="color:rgba(121, 136, 155, 0.5);margin-right:6px;float:left;margin-top:4px" @click="viewDetail1(record.id)" type="select" />
                                    <a @click="viewRecords(record)">{{ record.dataTitle }}</a>
                                </span>
                                <div slot="callee" slot-scope="text, record">
                                    <span v-html="record.callee"></span>
                                </div>
                            </a-table>
                            <div class="pagination-table">
                                <a-pagination
                                    show-size-changer
                                    :total="total"
                                    :show-total="total => `共 ${total} 条     `"
                                    v-model="currentPage"
                                    @change="pageChange"
                                    @showSizeChange="onShowSizeChange"
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="linkModal" title="外链分享" footer='' width='600px'>
            <div class="share-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="copy-link">
                    <a-input id="copyHtml1"  :placeholder="linkUrl" />
                    <span @click="copyLink(linkUrl)">复制链接</span>
                </div>
            </div>
        </a-modal>
        <a-drawer
            title=''
            placement="right"
            :closable="true"
            :visible="drawerVisible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="viewDetail" type="select" />
                            <a-icon v-if="operationAuthority.EXTERNAL_LINKS" @click="viewSharing" type="share-alt" />
                            <a-icon @click="onClose" type="close" />
                        </div>
                    </div>
                    <a-row class="row-head">
                        <a-col :span="8" class="col-left">
                            <div>创建人：</div>
                            <div>{{digitalDetail.createBy}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>创建时间：</div>
                            <div>{{digitalDetail.createTime}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>修改时间：</div>
                            <div>{{digitalDetail.updateTime}}</div>
                        </a-col>
                    </a-row>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    <div class="first-content">{{item.name}}:</div>

                                    <div v-if="item.type!='USER'&&item.code!='remark'&&item.type!='URL'&&item.attribute!='PHONE'" class="second-content">{{item.value}}</div>

                                    <div v-if="item.attribute=='PHONE'" class="second-content">
                                        <span class="take-phone" @click="playPhone(item.value)">
                                            {{item.value}}
                                            <img title='呼叫' class="take-phone1"  :value='item.value' src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone.svg'>
                                            <img title='呼叫' class="take-phone2" :value='item.value' src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone1.svg'>
                                        </span>
                                    </div>

                                     <div v-if="item.type=='URL'" class="second-content" :title='item.value'>
                                        <a-icon class="try-down" @click="tryListen(item.value)" title="试听" type="play-circle" />
                                        <a-icon class="try-down" @click="jumpToUrl(item.value)" title="下载" style="margin-left:16px" type="download" />
                                    </div>

                                    <div v-if="item.type=='USER'&&ifEditDrawer==false" class="second-content">{{item.value}}</div>

                                    <div v-if="item.code=='remark'&&ifEditDrawer==false" class="second-content">{{item.value}}</div>

                                    <div class="second-content" v-if="item.type=='USER'&&ifEditDrawer==true&&item.isEditable==1">
                                        <a-tree-select
                                            class="selection-range"
                                            @change="changeState"
                                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                            :tree-data="item.userTree"
                                            :placeholder="item.value"
                                            tree-default-expand-all
                                        >
                                        </a-tree-select>
                                    </div>
                                    <div class="second-content" v-if="item.code=='remark'&&ifEditDrawer==true&&item.isEditable==1">
                                        <a-textarea v-model="description" style="height:40px;resize:none" />
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="wiring-group" v-if="moduleId==17">
                            <div class="title">接线群组信息</div>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list col-left" >
                                    <div class="first-content">接入号：</div>
                                    <div class="second-content">{{groupData==null?'-':groupData.accessNumber}}</div>
                                </a-col>
                                <a-col :span="12" class="col-list col-right" >
                                    <div class="first-content">接通时间：</div>
                                    <div class="second-content">{{groupData==null?'-':groupData.answerTime}}</div>
                                </a-col>
                            </a-row>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list col-left" >
                                    <div class="first-content">接通时长(秒)：</div>
                                    <div class="second-content">{{groupData==null?'-':groupData.answerDuration}}</div>
                                </a-col>
                                <a-col :span="12" class="col-list col-right" >
                                    <div class="first-content">挂断原因：</div>
                                    <div class="second-content">{{groupData==null?'-':groupData.hangupCause}}</div>
                                </a-col>
                            </a-row>
                            <!-- <a-row class="row-list">
                                <a-col :span="12" class="col-list col-left" >
                                    <div class="first-content">录音地址：</div>
                                    <div v-if="groupData==null" class="second-content">-</div>
                                    <div v-if="groupData!=null" class="second-content">
                                        <a-icon @click="tryListen(groupData.recordUrl)" style="color:rgba(121, 136, 155, 0.5);font-size:16px;cursor: pointer;" type="play-circle" />
                                        <a-icon @click="jumpToUrl(groupData.recordUrl)" style="color:rgba(121, 136, 155, 0.5);font-size:16px;cursor: pointer;margin-left:12px" type="download" />
                                    </div>
                                </a-col>
                            </a-row> -->
                        </div>
                        <div class="extension-number" v-if="moduleId==17">
                            <div class="title">分机号信息</div>
                            <div style="margin:24px 0 0;">
                                <a-row style="color:rgba(188, 188, 188, 1);font-size:12px;margin-bottom:10px;">
                                    <a-col :span="7">坐席分机</a-col>
                                    <a-col :span="7">接入时间</a-col>
                                    <a-col :span="3">等待时长</a-col>
                                    <a-col :span="3">接通时长</a-col>
                                    <a-col :span="4">挂断原因</a-col>
                                </a-row>
                                <a-row v-for="(item,index) in extensionList" :key="index" style="color:rgba(188, 188, 188, 1);font-size:12px;font-size:14px;margin-bottom:6px;">
                                    <a-col style="color:rgba(61, 134, 253, 1)" :span="7">{{item.extension}}</a-col>
                                    <a-col :span="7">{{item.callTime}}</a-col>
                                    <a-col :span="3">{{item.waitingDuration}}</a-col>
                                    <a-col :span="3">{{item.answerDuration}}</a-col>
                                    <a-col :span="4">{{item.hangupCause}}</a-col>
                                </a-row>
                                <div v-if="extensionList.length==0" style="text-align: center;">
                                    <img style="width:50px;" src="../../assets/img/no-data.png" alt="">
                                </div>
                                <div v-if="extensionList.length==0" style="text-align: center;margin-top:0px;color:rgba(188, 188, 188, 1);">暂无数据</div>
                            </div>
                        </div>
                        <div class="comments">
                            <Comments ref="commentChild"></Comments>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button v-if="!ifEditDrawer&&operationAuthority.EDIT" type="primary" @click="editDrawer">编辑</a-button>
                        <a-button v-if="ifEditDrawer" type="primary" ghost @click="offEditDrawer">取消</a-button>
                        <a-button v-if="ifEditDrawer" type="primary" @click="saveDrawer">保存</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <audio controls style="position:absolute;left:-1000px;top:-1000px;" autoplay :src="musicSrc"></audio>
    </div>
</template>

<script>
    import ViewGroup from '../../components/ViewGroup'
    import ScreenColumn from '../../components/ScreenColumn'
    import Export from '../../components/Export'
    import DataRange from '../../components/DataRange'
    import Comments from "../../components/Comments";
    const columns = []
    const tableData = []
    export default {
        name: 'CallLog',
        components: {ViewGroup, ScreenColumn, Export, DataRange, Comments},
        data() {
            return {
                drawerVisible: false,
                ifEditDrawer: false,
                currentPage: 1,
                pageSize: 10,
                moduleId: '',
                tableData,
                columns,
                // digitalEmployeeList: [],
                // selectedDigitalEmployee: '',
                total: 0,
                showField: {
                    fieldList: [],
                    valueList: []
                },
                conditionList: [],
                selectedRowKeys: [],
                currentId: '',
                digitalDetail: {
                    dataTitle: ''
                },
                currentSelect: '',
                description: '',
                operationAuthority: '',
                linkModal: false,
                linkUrl: '',
                loading: false,
                musicSrc: '',
                moduleName: '',
                groupData: '',
                extensionNumberData: [],
                extensionList: []
            }   
        },
        methods: {
            jumpToUrl(url) {
                window.open(url)
            },
            changeState(val) {
                this.currentSelect = val
            },
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        this.$refs.commentChild.getParentCommentTree(this.moduleId, this.currentId, true)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryBasicData(type) {
                this.loading = true
                if(type == 'init'){
                    this.currentPage = 1
                }
                // if(this.selectedDigitalEmployee != ''){
                    this.axios.post('/api/module/data/list', {
                        current: this.currentPage,
                        size: this.pageSize,
                        moduleId: this.moduleId,
                        dataViewId: this.$refs.child1.dataViewId,
                        dataRange: this.$refs.child2.selectedDataRangeValue,
                        conditionList: this.$refs.child3.conditionList,
                        // digitalEmployeeId: this.selectedDigitalEmployee
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.loading = false
                            this.tableData = response.data.data.tableData.records
                            this.columns = response.data.data.tableHeader
                            for(let i = 0; i < this.columns.length; i++){
                                if(this.columns[i]['title'] == 'id'){
                                    this.columns[i] = {
                                        title: this.columns[i]['title'],
                                        dataIndex: this.columns[i]['dataIndex'],
                                        colSpan:0,  
                                        customRender: () => {
                                            return {
                                                attrs: {
                                                    colSpan: 0,
                                                },
                                            };
                                        },
                                    }
                                }
                                if(this.columns[i]['dataIndex'] == 'dataTitle'){
                                    this.columns[i] = {
                                        title: this.columns[i]['title'],
                                        dataIndex: this.columns[i]['dataIndex'],
                                        scopedSlots: { customRender: 'dataTitle' },
                                    }
                                }
                            }
                            this.total = response.data.data.tableData.total
                            setTimeout(()=>{
                                this.$refs.child4.getTotalNum(response.data.data.tableData.total, this.moduleId)
                            },2000)
                            
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                // }else{
                //     this.loading = false
                // }
            },
            viewRecords(record) {
                this.drawerVisible = true;
                this.currentId = record.id
                this.querySelectData()
                if(this.moduleId == 17){
                    this.queryWiringGroup()
                    this.queryExtensionList()
                }
            },
            // changeSence(value) {
            //     this.selectedDigitalEmployee = value
            //     this.queryBasicData()
            // },
            pageChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            onShowSizeChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            queryColumnList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.moduleId+'/listPage/init', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.operationAuthority = response.data.data.operationAuthority
                        this.showField.fieldList = response.data.data.showField.fieldList
                        this.showField.valueList = response.data.data.showField.valueList
                        this.conditionList = response.data.data.conditionList
                        this.$refs.child3.getData(this.showField,this.moduleId,this.conditionList)
                        // this.digitalEmployeeList = response.data.data.digitalEmployeeList
                        this.$refs.child2.getDataRangeData(response.data.data.dataRange)
                        this.$refs.child1.getParentData(this.moduleId, response.data.data.dataViewList)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onClose() {
                this.drawerVisible = false
            },
            editDrawer() {
                this.ifEditDrawer = true
            },
            offEditDrawer() {
                this.ifEditDrawer = false
            },
            saveDrawer() {
                this.ifEditDrawer = false
                this.axios.post('/api/autoCallRecord/update', {
                    id: this.currentId,
                    owner: this.currentSelect!=''?this.currentSelect:undefined,
                    remark: this.description!=''?this.description:undefined,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('修改成功')
                        this.currentId = ''
                        this.description = ''
                        this.drawerVisible = false
                        // this.querySelectData()
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            shareSet() {
                this.$router.push({
                    path: "/PublicMenu/share",
                    query: {
                        moduleId: this.moduleId
                    }
                })
            },
            pushSet() {
                this.$router.push({
                    path: "/PublicMenu/push",
                    query: {
                        moduleId: this.moduleId
                    }
                })
            },
            viewDetail1(id) {
                let routeUrl = this.$router.resolve({
                    path: "/manualCallDetail",
                    query: {
                        id: id,
                        moduleId: this.moduleId
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            viewDetail() {
                let routeUrl = this.$router.resolve({
                    path: "/manualCallDetail",
                    query: {
                        id: this.currentId,
                        moduleId: this.moduleId
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            viewSharing() {
                event.stopPropagation();
                this.linkModal = true
                let shareId = ''
                this.axios.post('/api/externalLinks',{
                    moduleId: this.moduleId,
                    dataId: this.currentId
                })
                .then(response=> {
                    if(response.data.code == 0){
                        shareId = response.data.data.id
                        let url1 = window.location.origin
                        this.linkUrl = url1+'/#/CallRecordShare?id='+shareId+'&moduleId='+this.moduleId
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            onSelectChange(selectedRowKeys) {
                let obj = {}
                obj.deptId = ''
                // obj.digitalEmployeeId = this.selectedDigitalEmployee
                obj.dataViewId = this.$refs.child1.dataViewId
                obj.dataRange = this.$refs.child2.selectedDataRangeValue
                obj.conditionList = this.$refs.child3.conditionList
                this.selectedRowKeys = selectedRowKeys;
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj,'callLog')
            },
            passDataToChild() {
                let obj = {}
                obj.deptId = ''
                // obj.digitalEmployeeId = this.selectedDigitalEmployee
                obj.dataViewId = this.$refs.child1.dataViewId
                obj.dataRange = this.$refs.child2.selectedDataRangeValue
                obj.conditionList = this.$refs.child3.conditionList
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj, 'callLog')
            },
            copyLink(url) {
                this.linkModal = false
                let text = url
                let input = document.getElementById("copyHtml1");
                input.value = text; 
                input.select(); 
                document.execCommand("copy"); 
                this.$message.success('已复制！')
            },
            playPhone(value) {
                this.$emit('fatherMethod', value);
            },
            tryListen(value) {
                this.musicSrc = value + '?' + this.getTimestamp()
            },
            getTimestamp() {
                return (new Date()).getTime();
            },
            queryWiringGroup() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/autoCallRecord/group', 
                        params: {
                            id: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                       this.groupData = response.data.data 
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryExtensionList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/autoCallRecord/extensionList', 
                        params: {
                            id: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                       this.extensionList = response.data.data 
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            }
        },
        mounted: function() {
            this.sessionData('set','publicMenu','noticeCdr');
            this.moduleId = this.$route.query.moduleId
            this.queryColumnList()
            this.moduleName = this.$route.query.name
            // this.$refs.child1.queryInitData(this.moduleId)
            // document.onclick = (e)=> {
            //     this.orderActive = false
            //     if(e.target.className == 'take-phone'){
            //         this.playPhone(e.target.attributes['value']['nodeValue'])
            //     }
            //     this.$forceUpdate();
            // }
            // document.onmouseover = (e)=>{
            //     if(e.target.className == 'take-phone'){
            //         e.target.src = 'http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone1.svg'
            //     }
            // }
            // document.onmouseout = (e)=>{
            //     if(e.target.className == 'take-phone'){
            //         e.target.src = 'http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone.svg'
            //     }
            // }
        }
    }
</script>

<style scoped lang="less">
    .call-log{
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            overflow-y: auto;
            overflow-y: auto;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .screen-condition{
                    z-index: 100;
                    height: 80px;
                    background: #fff;
                    padding: 24px 0 0 24px;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    border-radius: 4px;
                    overflow: hidden;
                    .select-modular{
                        display: inline-block;
                        .title{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                        }
                        .selection-modular{
                            margin-left: 24px;
                            width: 265px;
                            height: 32px;
                        }
                    }
                }
                .extra-content{
                    height: 16px;
                    width: 100%;
                    background: #f0f2f3;
                    position: absolute;
                    left: 0px;
                    top: 80px;
                    z-index: 100;
                }
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    // padding-top: 96px;
                    height: 100%;
                    width: 100%;
                    border-radius: 4px;
                    .modular-in{
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        border-radius: 4px;
                        overflow-y: auto;
                        padding: 24px;
                        .modular-operation{
                            margin-top: 21px;
                            .operation-group{
                                display: inline-block;
                                float: right;
                                .divider{
                                    margin: 0 16px 0 0;
                                    height: 32px;
                                }
                            }
                        }
                        .table-data{
                            margin-top: 24px;
                        }
                        .pagination-table{
                            float: right;
                            padding-top: 24px;
                        }
                    }
                    
                }
            }
            
        }
    }
    .drawer-public{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 144px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 160px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
            .take-phone{
                cursor: pointer;
                .take-phone1{
                    width: 15px;
                    height: 15px;
                    margin-left: 3px;
                }
                .take-phone2{
                    display: none;
                    width: 15px;
                    height: 15px;
                    margin-left: 3px;
                }
            }
            .take-phone:hover{
                .take-phone1{
                    display: none;
                }
                .take-phone2{
                    display: inline-block;
                }
            }
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                div:nth-child(1){
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                div:nth-child(2){
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding-right: 16px;
            .basic-info{
                width: 100%;
                // height: 704px;
                background: #fff;
                border-radius: 4px;
                padding: 24px;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        height: 69px;
                        overflow: hidden;
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            img{
                                width: 21px;
                            }
                            .change-head{
                                color: rgba(24, 144, 255, 1);
                                font-size: 12px;
                                cursor: pointer;
                                margin-left: 24px;
                            }
                        }
                        .selection-range{
                            margin-top: 9px;
                            width: 100%;
                        }
                    }
                }
            }  
            .wiring-group{
                width: 100%;
                background: #fff;
                border-radius: 4px;
                padding: 24px;
                margin-top:16px;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        height: 69px;
                        overflow: hidden;
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            img{
                                width: 21px;
                            }
                            .change-head{
                                color: rgba(24, 144, 255, 1);
                                font-size: 12px;
                                cursor: pointer;
                                margin-left: 24px;
                            }
                        }
                    }
                }
            }
            .extension-number{
                margin-top:16px;
                width: 100%;
                background: #fff;
                border-radius: 4px;
                padding: 24px;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
            }
            .comments{
                padding: 18px 24px;
                background: #fff;
                margin-top: 16px;
                border-radius: 4px;
                .title{
                    color: rgba(18, 18, 18, 1);;
                    font-size: 14px;
                }
                .textarea-comment{
                    margin-top: 20px;
                    background: rgba(247, 247, 247, 1);
                    border: none;
                    resize: none;
                }
                .release{
                    text-align: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    margin-top: 4px;
                    span{
                        cursor: pointer;
                    }
                }
            }  
        }
    }
    .share-modal{
        .copy-link{
            height: 62px;
            input{
                width: 464px;
                float: left;
                height: 40px;
            }
            span{
                float: left;
                display: inline-block;
                width: 88px;
                height: 40px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                cursor: pointer;
                border-radius: 0px 4px 4px 0px;
                line-height: 40px;
                text-align: center;
            }
        }
    }
    
</style>