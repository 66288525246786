import { render, staticRenderFns } from "./CustomerLeadShare.vue?vue&type=template&id=44b6118a&scoped=true&"
import script from "./CustomerLeadShare.vue?vue&type=script&lang=js&"
export * from "./CustomerLeadShare.vue?vue&type=script&lang=js&"
import style0 from "./CustomerLeadShare.vue?vue&type=style&index=0&id=44b6118a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b6118a",
  null
  
)

export default component.exports