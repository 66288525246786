<template>
    <div class="screen-column">
        <div>
            <div style="display: inline-block" v-if="moduleid!=19">
                <img title="显示列" v-if="!setListWordModal" @click="setListWordOperate" class="operate-img" src="../assets/img/display-column1.png" alt="">
                <img v-if="setListWordModal" class="operate-img" src="../assets/img/display-column2.png" alt="">
            </div>
            <div style="display:inline-block;position:relative">
                <img title="数据筛选" v-if="!screenModal" @click="screenOperate" class="operate-img" src="../assets/img/screen1.png" alt="">
                <img v-if="screenModal" class="operate-img" src="../assets/img/screen2.png" alt="">
                <span class="tip-icon" v-if="conditionList.length!=0">{{conditionList.length}}</span>
            </div>
        </div>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="setListWordModal" title="设置列表显示字段" footer='' width='720px'>
            <div class="setlist-word-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="word-list">
                    <a-checkbox-group @change="selectItemWord" style="width:100%" v-model="checkedWordList">
                        <a-row style="margin-bottom:0px">
                            <a-col :span="6" v-for="(item, index) in allWordList" :key="index">
                                <a-checkbox :value="item.value"  >
                                    {{item.label}}
                                </a-checkbox>
                            </a-col>
                        </a-row>
                    </a-checkbox-group>
                </div>
                <a-divider class="divider3" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelSetListWord">取消</a-button>
                    <a-button type="primary" @click="sureSetListWord">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="screenModal" title="设置筛选条件" footer='' width='720px'>
            <div class="screen-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div style="max-height: 500px;overflow-y: auto;">
                    <a-row v-for="(item,index) in viewScreenData" :key='index' style="margin-bottom:12px">
                        <a-col :span="4">
                            {{item.fieldName}}
                        </a-col>
                        <a-col :span="6">
                            <a-select v-model="item.selectedCondition" style="width:100%;height:32px" @change="selectConChange(item,index)">
                                <a-select-option :value="item1.value" v-for="(item1,index1) in item.methodList" :key='index1'>
                                    {{item1.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="14" style="padding-left:12px;">
                            <Linkage ref="child4"></Linkage>
                        </a-col>
                    </a-row>
                </div>
                <div class="reset-condition">
                    <span @click="newSetCondition">重置筛选条件</span>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelScreen">取消</a-button>
                    <a-button type="primary" @click="sureScreen">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import Linkage from './Linkage'
export default {
    props:['moduleid'],
    components: {Linkage},
    data(){
        return{
            setListWordModal: false,
            screenModal: false,
            checkAll: false,
            checkedWordList: [],
            allWordList: [],
            moduleId: '',
            viewScreenData: [],
            conditionList: [],
            copyData: [],
            selectedDigitalEmployee: '',
            callingLabelList: []
        }
    },
    methods: {
        selectConChange(item, index) {
            if(item.fieldAttribute == 'RECORD_LABEL'){
                if(this.$parent.selectedDigitalEmployee == ''){
                    this.$message.warning('标签数据基于场景名称！')
                }else{
                    this.axios(
                        {
                            method:'get',
                            url:'/api/employee/label/list?digitalEmployeeId='+this.$parent.selectedDigitalEmployee, 
                        }
                    )
                    .then(response=> {
                        if(response.data.code == 0){
                            this.callingLabelList = response.data.data
                            item.selectList = this.callingLabelList
                            this.$refs.child4[index].getPassData(item,index)
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error)
                    })
                }
            }else{
                this.$refs.child4[index].getPassData(item,index)
            }
        },
        getData(data, moduleId, conditionList) {
            this.allWordList = data.fieldList
            this.checkedWordList = data.valueList
            this.moduleId = moduleId
            this.viewScreenData = this.deepClone(conditionList)
            this.copyData = this.deepClone(conditionList)
        },  
        deepClone(obj){
            let _obj = JSON.stringify(obj),
            objClone = JSON.parse(_obj);
            return objClone
        },  
        newSetCondition() {
            if(this.conditionList.length != 0){
                let data = this.deepClone(this.copyData)
                this.viewScreenData = data
                for(let i = 0; i < this.viewScreenData.length; i++){
                    this.$refs.child4[i].getPassData(this.viewScreenData[i],i)
                }
                this.conditionList = []
                this.$forceUpdate()
            }
        },  
        setListWordOperate() {
            this.setListWordModal = true
        },
        cancelSetListWord() {
            this.setListWordModal = false
        },
        sureSetListWord() {
            this.setListWordModal = false
            this.axios.post('/api/module/saveShowFields', {
                moduleId: this.moduleId,
                fieldIdList: this.checkedWordList
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.$message.success('保存成功')
                    this.$parent.queryBasicData()
                }else{
                    this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        screenOperate() {
            this.screenModal = true
        },
        cancelScreen() {
            this.screenModal = false
        },
        sureScreen() {
            this.screenModal = false
            let data = this.$refs.child4
            let arr = []
            for(let i = 0; i < data.length; i++){
                let obj = {}
                if(data[i]['_data']['viewportData']['selectedCondition']!='ALL'&&data[i]['_data']['viewportData']['selectedCondition']!=undefined){
                    obj.fieldId = data[i]['_data']['viewportData']['fieldId']
                    obj.fieldMethod = data[i]['_data']['viewportData']['selectedCondition']
                    obj.valueList = data[i]['_data']['viewportData']['valueList']
                    arr.push(obj)
                }
            }
            this.conditionList = arr
            this.$parent.queryBasicData('init')
        },
        selectItemWord(e) {
            this.checkedWordList = e
            if(this.checkedWordList.length == this.allWordList.length) {
                this.checkAll = true
            }else{
                this.checkAll = false
            } 
        },
    }
}
</script>
<style scoped lang="less">
    .screen-column{
        display: inline-block;
        .operate-img{
            width: 20px;
            margin-right: 20px;
            cursor: pointer;
        }
        .tip-icon{
            color: #fff;
            font-size: 12px;
            position: absolute;
            top: -10px;
            right: 4px;
            display: inline-block;
            width: 18px;
            height: 18px;
            text-align: center;
            line-height: 18px;
            border-radius: 9px;
            background: #f5574e;
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .divider3{
            margin: 0px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .setlist-word-modal{
        padding-bottom: 46px;
        .select-all-word{
            margin-bottom: 12px;
        }
        .word-list{
            div{
                margin-bottom: 13px;
            }
        }
    }
    .screen-modal{
        padding-bottom: 46px;
        .reset-condition{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
</style>