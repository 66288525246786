<template>
    <div class="share-set">
        <div class="head">
            <span class="title">企业标签设置</span>
            <a-button @click="backTo">
                返回
            </a-button>
            <a-button type="primary" @click="addSharing">
                新增
            </a-button>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="content-in">
                <div class="modular-content">
                    <div class="modular-list" v-for="(item,index) in strategyData" :key="index">
                        <a-row>
                            <a-col :span="12">
                                <div class="name">{{item.name}}</div>
                            </a-col>
                            <a-col :span="12">
                                <div class="btn-group3">
                                    <a-button @click="copyList(item.id)">复制</a-button>
                                    <a-button @click="editList(item.id, item.labelList, item.name, item.sort)">编辑</a-button>
                                    <a-button @click="deleteList(item.id)">删除</a-button>
                                </div>
                            </a-col>
                        </a-row>
                        <a-divider class="divider" type="horizontal" />
                        <div class="row-list">
                            <a-row class="row-item">
                                <a-col :span="24">
                                    <div class="jurisdiction" :style="{'background':item1.color}" v-for="(item1,index1) in item.labelList" :key="index1">
                                        {{item1.name}}
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <a-row class="related-info">
                            <a-col :span="9">
          
                            </a-col>
                            <a-col :span="15">
                                <div>
                                    <span>排序：{{item.sort}}</span>
                                    <a-divider type="vertical" />
                                    <span>创建人：</span>
                                    <span>{{item.createBy}}</span>
                                    <a-divider type="vertical" />
                                    <span>创建时间：</span>
                                    <span>{{item.createTime}}</span>
                                    <a-divider type="vertical" />
                                    <span>修改人：</span>
                                    <span>{{item.updateBy}}</span>
                                    <a-divider type="vertical" />
                                    <span>修改时间：</span>
                                    <span>{{item.updateTime}}</span>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
            </div>
        </div>
        <a-drawer
            :title='drawerName'
            placement="right"
            :closable="true"
            :visible="visible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '100'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '72px 0 0' }"
        >
            <div class="drawer-set">
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="item-list">
                            <div class="title">输入标签类别名称：</div>
                            <a-input v-model="shareName" placeholder="请输入权限名称" />
                        </div>
                        <div class="item-list">
                            <div class="title">排序：</div>
                            <a-input-number style="width:100%" v-model.trim="sort" placeholder="请输入排序顺序" type='number' :min='1' />
                        </div>
                        <div class="item-list">
                            <div class="title">数据条件：</div>
                            <div class="new-tag">
                                <a-input v-model.trim="tagName" placeholder="输入标签名称" />
                                <a-icon @click="addTagName" type="plus-circle" />
                            </div>
                            <div class="tagname-box">
                                <span :style="{'background':item.color}" v-for="(item,index) in tagNameList" :key='index'>
                                    {{item.name}}
                                    <a-icon @click="removeTagName(index, item)" type="close" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button type="primary" ghost @click="cancelDrawer">取消</a-button>
                        <a-button type="primary" @click="sureDrawer">保存</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="copyShareSetModal" title="复制标签分类" footer='' width='480px'>
            <div class="edit-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="changed-name">输入复制后新的标签类别名称：</div>
                <a-input v-model="newName" placeholder="请输入内容" />
                <div style="margin:16px 0 8px 0" class="changed-name">排序：</div>
                <a-input-number style="width:100%" v-model.trim="tagSort" placeholder="请输入排序顺序" type='number' :min='1' />
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelCopyShare">取消</a-button>
                    <a-button type="primary" @click="sureCopyShare">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteSettingModal" footer='' width='480px'>
            <div class="delete-setting-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个标签类别吗？</span>
                </div>
                <div class="tip">删除后，数据将无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDelete">取消</a-button>
                    <a-button type="primary" @click="sureDelete">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    export default {
        name: 'Share',
        components: { },
        data() {
            return {
                copyShareSetModal: false,
                deleteSettingModal: false,
                visible: false,
                drawerName: '',
                moduleId: '',
                strategyData: [],
                currentStrategyId: '',
                newName: '',
                shareName: '',
                passDataObjOwner: {
                    departmentTree: null,
                    peopleTree: null,
                    departmentSelected: null,
                    personSelected: null
                },
                passDataObjViewer: {
                    departmentTree: null,
                    peopleTree: null,
                    departmentSelected: null,
                    personSelected: null
                },
                child1Data: {
                    departmentSelected: [],
                    personSelected: [],
                },
                child2Data: {
                    departmentSelected: [],
                    personSelected: [],
                },
                viewScreenData: [],
                conditionList: [],
                type: '',
                copyData: [],
                tagName: '',
                tagNameList: [],
                colorList: ['#256fe7', '#e54444', '#5dc7ff', '#ffaf00', '#23da77', '#a680ff'],
                removedTagIdList: [],
                sort: null,
                tagSort: null
            }
        },
        methods: {
            queryBasicData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/enterpriseLabel/list', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.strategyData = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            deepClone(obj){
                let _obj = JSON.stringify(obj),
                objClone = JSON.parse(_obj);
                return objClone
            },
            onClose() {
                this.visible = false;
                this.initDrawer()
            },
            copyList(id) {
                this.copyShareSetModal = true
                this.currentStrategyId = id
            },
            editList(id, list, name, sort) {
                console.log(sort)
                this.visible = true;
                this.drawerName = '企业标签-编辑标签类别'
                this.shareName = name
                this.currentStrategyId = id
                this.type = 'edit'
                this.sort = sort
                this.tagNameList = JSON.parse(JSON.stringify(list))
                this.removedTagIdList = []
            },
            deleteList(id) {
                this.deleteSettingModal = true
                this.currentStrategyId = id
            },
            cancelCopyShare() {
                this.copyShareSetModal = false
            },
            sureCopyShare() {
                this.copyShareSetModal = false
                this.axios.post('/api/customer/enterpriseLabel/copy', {
                    id: this.currentStrategyId,
                    name: this.newName,
                    sort: this.tagSort
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('复制成功')
                        this.queryBasicData()
                        this.newName = ''
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDelete() {
                this.deleteSettingModal = false
            },
            sureDelete() {
                this.deleteSettingModal = false
                this.axios.delete('/api/customer/enterpriseLabel',{
                    params: {
                        id: this.currentStrategyId
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryBasicData()
                        this.currentStrategyId = ''
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDrawer() {
                this.visible = false
                this.initDrawer()
            },
            sureDrawer() {
                let tip = ''
                if(this.type == 'new'){
                    tip = '新增成功'
                }else{
                    tip = '修改成功'
                }
                let arr = []
                for(let i = 0; i < this.tagNameList.length; i++){
                    if(this.tagNameList[i]['id'] == ''){
                        arr.push(this.tagNameList[i])
                    }
                }
                this.axios.post('/api/customer/enterpriseLabel', {
                    id: this.currentStrategyId,
                    name: this.shareName,
                    newLabelList: arr,
                    sort: this.sort,
                    removeLabelIdList: this.removedTagIdList
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryBasicData()
                        this.shareName = ''
                        this.removedTagIdList = []
                        this.tagNameList = []
                        this.$message.success(tip)
                        this.visible = false
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            initDrawer() {
                this.shareName = ''
                this.currentStrategyId = ''
                this.removedTagIdList = []
                this.tagNameList = []
            },
            addSharing() {
                this.type = 'new'
                this.visible = true;
                this.sort = null
                this.drawerName = '企业标签-新增标签类别'
            },
            backTo() {
                this.$router.go(-1)
            },
            addTagName() {
                if(this.tagName.length == 0){
                    this.$message.error('请输入标签名称')
                }else{
                    let flag = false
                    for(let i = 0; i < this.tagNameList.length; i++){
                        if(this.tagName == this.tagNameList[i]['name']){
                            this.$message.error('标签名称重复')
                            this.tagName = ''
                            flag = true
                        }
                    }
                    if(flag == false){
                        let obj = {}
                        obj.name = this.tagName
                        obj.color = this.randomColor()
                        obj.id = ''
                        this.tagNameList.push(obj)
                        this.tagName = ''
                    }
                    
                }
            },
            randomColor() {
                let num = Math.floor(Math.random()*6)
                let color = this.colorList[num]
                return color
            },
            removeTagName(index, item) {
                this.tagNameList.splice(index,1)
                if(item.id != ''){
                    this.removedTagIdList.push(item.id)
                }
            }
        },
        mounted: function() {
            this.sessionData('set','personalCenter','permissionSet');
            this.moduleId = this.$route.query.moduleId
            this.queryBasicData()
            
        }
    }
</script>

<style scoped lang="less">
    .share-set{
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                width: 64px;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            overflow-y: auto;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    overflow-y: auto;
                    .modular-list{
                        width: 100%;
                        height: 230px;
                        padding: 24px;
                        background: #fff;
                        border-radius: 4px;
                        margin-bottom: 16px;
                        .name{
                            color: rgba(18, 18, 18, 1);
                            font-size: 16px;
                        }
                        .btn-group3{
                            float: right;
                            button{
                                margin-left: 8px;
                            }
                        }
                        .divider{
                            margin: 10px 0 16px;
                        }
                        .jurisdiction{
                            color: #fff;
                            font-size: 13px;
                            display: inline-block;
                            padding: 0 12px;
                            height: 28px;
                            line-height: 28px;
                            margin-right: 8px;
                            margin-bottom: 8px;
                        }
                        .personnel{
                            i{
                                color: rgba(121, 136, 155, 1);
                                font-size: 14px;
                            }
                            span{
                                color: rgba(80, 80, 80, 1);;
                                font-size: 12px;
                                margin-left: 8px;
                            }
                        }
                        .related-info{
                            margin-top: 16px;
                            div{
                                text-align: right;
                                color: rgba(188, 188, 188, 1);
                                font-size: 12px;
                            }
                        }
                        .row-list{
                            height: 100px;
                            overflow-y: auto;
                            .row-item{
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            
        }
        
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .edit-modal{
        padding-bottom: 46px;
        .changed-name{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        input{
            margin-top: 8px;
        }
    }
    .delete-setting-modal{
        padding-top: 24px;
        padding-bottom: 46px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .drawer-set{
        height: 100%;
        display: flex;
        position: relative;
        .drawer-content{
            padding: 0 16px 68px;
            width: 100%;
            overflow-y: auto;
            .drawer-body-content{
                width: 100%;
                height: 100%;
                padding: 24px;
                background: #fff;
                border-radius: 4px;
                .item-list{
                    margin-bottom: 24px;
                    .title{
                        color: rgba(188, 188, 188, 1);
                        font-size: 12px;
                        margin-bottom: 11px;
                    }
                    .new-tag{
                        display: flex;
                        input{
                            flex: 1;
                        }
                        i{
                            font-size: 20px;
                            margin-left: 16px;
                            margin-top: 6px;
                            cursor: pointer;
                            color: rgba(121, 136, 155, 1);
                        }
                    }
                    .tagname-box{
                        margin-top: 12px;
                        span{
                            display: inline-block;
                            margin-right: 8px;
                            height: 28px;
                            line-height: 28px;
                            margin-bottom: 8px;
                            padding: 0 12px;
                            color: #fff;
                            font-size: 13px;
                            i{
                                cursor: pointer;
                                margin-left: 6px;
                                font-size: 12px;
                                font-weight: bold;
                            }
                        }
                    }
                    .reset-condition{
                        text-align: right;
                        color: rgba(24, 144, 255, 1);
                        font-size: 14px;
                        span{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    width: 64px;
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(1){
                    margin-right: 16px;
                }
            }
        }
    }
</style>