<template>
    <div class="linkage">
        <div v-if="viewportData.fieldType=='TEXT'&&viewportData.fieldAttribute=='TEXTAREA'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-textarea v-model="viewportData.valueList[0]" placeholder="请输入" :rows="4" />
        </div>
        <div v-if="viewportData.fieldType=='LONG_TEXT'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-input v-model="viewportData.valueList[0]" placeholder="请输入" />
        </div>
        <div v-if="viewportData.fieldType=='TEXT'&&viewportData.fieldAttribute!='TEXTAREA'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-input v-model="viewportData.valueList[0]" placeholder="请输入" />
        </div>
        <div v-if="viewportData.fieldType=='NUMBER'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-input-number v-model="viewportData.valueList[0]"/>
            <a-input-number v-if="viewportData.selectedCondition=='BETWEEN'" v-model="viewportData.valueList[1]"/>
        </div>
        <div v-if="viewportData.fieldType=='TIME'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'&&viewportData.selectedCondition!='TODAY'&&viewportData.selectedCondition!='THIS_WEEK'&&viewportData.selectedCondition!='THIS_MONTH'&&viewportData.selectedCondition!='BEFORE_TODAY'&&viewportData.selectedCondition!='BEFORE_TWO_MONTH'">
            <a-date-picker v-if="viewportData.selectedCondition!='BETWEEN'"  v-model="viewportData.valueList[0]" style="width:100%" format="YYYY-MM-DD HH:mm:ss" />
            <a-range-picker v-if="viewportData.selectedCondition=='BETWEEN'"  v-model="viewportData.valueList" style="width:100%" format="YYYY-MM-DD HH:mm:ss" />
        </div>
        <div v-if="viewportData.fieldType=='SELECT_SINGLE'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-select v-model="viewportData.valueList" mode="tags" style="width: 100%">
                <a-select-option :value="item.value" v-for="(item, index) in viewportData.selectList" :key='index'>
                    {{item.name}}
                </a-select-option>
            </a-select>
        </div>
        <div v-if="viewportData.fieldType=='SELECT_MULTIPLE'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-select v-model="viewportData.valueList" mode="tags" style="width: 100%">
                <a-select-option :value="item.value" v-for="(item, index) in viewportData.selectList" :key='index'>
                    {{item.name}}
                </a-select-option>
            </a-select>
        </div>
        <div v-if="viewportData.fieldType=='USER'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-tree-select
                v-model="viewportData.valueList"
                style="width: 100%;display:inline-block;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="viewportData.userTree"
                placeholder="请选择"
                tree-default-expand-all
                :multiple="true"
            >
            </a-tree-select>
        </div>
        <div v-if="viewportData.fieldType=='USER_SINGLE'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-tree-select
                v-model="viewportData.valueList[0]"
                style="width: 100%;display:inline-block;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="viewportData.userTree"
                placeholder="请选择"
                tree-default-expand-all
            >
            </a-tree-select>
        </div>
        <div v-if="viewportData.fieldType=='USER_MULTIPLE'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-tree-select
                v-model="viewportData.valueList"
                style="width: 100%;display:inline-block;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="viewportData.deptTree"
                placeholder="请选择"
                tree-default-expand-all
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
            >
            </a-tree-select>
        </div>
        <div v-if="viewportData.fieldType=='DEPARTMENT_SINGLE'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-tree-select
                v-model="viewportData.valueList[0]"
                style="width: 100%;display:inline-block;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="viewportData.deptTree"
                placeholder="请选择"
                tree-default-expand-all
            >
            </a-tree-select>
        </div>
        <div v-if="viewportData.fieldType=='DEPARTMENT_MULTIPLE'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-tree-select
                v-model="viewportData.valueList"
                style="width: 100%;display:inline-block;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="viewportData.deptTree"
                placeholder="请选择"
                tree-default-expand-all
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
            >
            </a-tree-select>
        </div>
        <div v-if="viewportData.fieldType=='URL'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-input v-model="viewportData.valueList[0]" placeholder="请输入" />
        </div>
        <div v-if="viewportData.fieldType=='ASSOCIATED'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-select v-model="viewportData.valueList[0]" style="width: 100%">
                <a-select-option :value="item.value" v-for="(item, index) in viewportData.selectList" :key='index'>
                    {{item.name}}
                </a-select-option>
            </a-select>
        </div>
        <div v-if="viewportData.fieldType=='CUSTOMER_LABEL'&&viewportData.selectedCondition!='ALL'&&viewportData.selectedCondition!='ISNULL'">
            <a-tree-select
                v-model="viewportData.valueList"
                style="width: 100%;display:inline-block;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="viewportData.labelTree"
                placeholder="请选择"
                tree-default-expand-all
                tree-checkable
            >
            </a-tree-select>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
    data(){
        return{
            viewportData: '',
            SHOW_PARENT
        }
    },
    methods: {
        moment,
        getPassData(item) {
            this.viewportData = item
        },
    }
}
</script>
<style scoped lang="less">

</style>