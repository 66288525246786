<template>
    <div class="revenue-detail">
        <div style="text-align:center;padding-top:300px;">
            <div v-if="downloadUrl==''">
                <img style="width:120px;" src="../assets/img/no-calllist.png" alt="">
            </div>
            <div v-if="downloadUrl==''&&downLoadFailUrl==''" style="color:rgba(158, 172, 196, 1);font-size:14px;">话单还未生成，请稍后</div>
            <span v-if="downloadUrl!=''" @click="viewSuccessBillDetail()" style="display: inline-block;width:96px;height:40px;line-height: 40px;text-align: center;background:rgba(24, 144, 255, 1);color:#fff;border-radius: 4px;cursor: pointer;margin-right:12px;">下载成功话单</span>
            <span v-if="downLoadFailUrl!=''" @click="viewFailBillDetail()" style="display: inline-block;width:96px;height:40px;line-height: 40px;text-align: center;background:rgba(24, 144, 255, 1);color:#fff;border-radius: 4px;cursor: pointer;;">下载失败话单</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BillOverview',
        data() {
            return {
                id: '',
                downloadUrl: '',
                downLoadFailUrl: ''
            }   
        },
        methods: {
            queryData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/waitingCall/external/downloadUrl', 
                        params: {
                            id: this.id,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.downloadUrl = response.data.data.callRecordUrl
                        this.downLoadFailUrl = response.data.data.failCallRecordUrl
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            viewSuccessBillDetail() {
                window.open(this.downloadUrl);
            },
            viewFailBillDetail() {
                window.open(this.downLoadFailUrl);
            }
        },
        mounted: function() {
            this.id = this.$route.query.id
            this.queryData()
        },

    }
</script>

<style scoped lang="less">
    
</style>