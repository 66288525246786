<template>
    <div class="call-detail">
        <div class="head">
            <span class="title">{{digitalDetail.moduleName+"-"+digitalDetail.dataTitle}}</span>
            <OutLink v-if="operationAuthority.EXTERNAL_LINKS" :moduleId='moduleId' :dataId='currentId' :original='"CallRecordShare"'></OutLink>
        </div>
        <div class="content">
            <div class="content-in">
                <div class="modular-content">
                    <div class="left-content">
                        <div class="related-module">
                            <span></span>
                            相关模块
                        </div>
                        <ul class="module-list">
                            <li :class="{'active':currentSelectModule==index}" @click="viewSenceDetail(index,item)" v-for="(item,index) in moduleNumList" :key='index'>
                                <a-icon type="profile" />
                                <div>{{item.name}}</div>
                                <span>{{item.num}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="center-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    <div class="first-content">{{item.name}}:</div>

                                    <div v-if="item.type!='USER'&&item.code!='remark'&&item.type!='URL'" class="second-content">{{item.value}}</div>
                                    <div class="second-content" v-if="item.type=='URL'" style="cursor:pointer;color:#1890FF" :title='item.value'>
                                        <span @click="jumpToUrl(item.value)">{{item.value}}</span>
                                    </div>
                                    <div v-if="item.type=='USER'&&ifEditDrawer==false" class="second-content">{{item.value}}</div>

                                    <div v-if="item.code=='remark'&&ifEditDrawer==false" class="second-content">{{item.value}}</div>

                                    <div class="second-content" v-if="item.type=='USER'&&ifEditDrawer==true&&item.isEditable==1">
                                        <a-tree-select
                                            class="selection-range"
                                            v-model="item.valueList"
                                            @change="changeState"
                                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                            :tree-data="item.userTree"
                                           
                                            tree-default-expand-all
                                        >
                                        </a-tree-select>
                                    </div>

                                    <div class="second-content" v-if="item.code=='remark'&&ifEditDrawer==true&&item.isEditable==1">
                                        <a-textarea v-model="description" :rows="2" />
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="comments">
                            <Comments ref="commentChild"></Comments>
                        </div>
                    </div>
                    <div class="right-content">
                        <div class="data-info">
                            <div class="related-info">
                                <span></span>
                                数据信息
                            </div>
                            <ul>
                                <li>
                                    <div>创建时间：</div>
                                    <div>{{digitalDetail.createTime}}</div>
                                </li>
                                <li>
                                    <div>修改时间：</div>
                                    <div>{{digitalDetail.updateTime}}</div>
                                </li>
                                <li>
                                    <div>创建人：</div>
                                    <div>{{digitalDetail.createBy}}</div>
                                </li>
                            </ul>
                        </div>
                        <div class="fast-operate">
                            <div class="fast-do">
                                <span></span>
                                快速操作
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="operationAuthority.EDIT" class="footer-edit">
            <a-button v-if="!ifEditDrawer" type="primary"  @click="editSence">编辑</a-button>
            <a-button v-if="ifEditDrawer" type="primary" ghost @click="cancelEdit">取消</a-button>
            <a-button v-if="ifEditDrawer" type="primary" @click="saveEdit">保存</a-button>
        </div>
    </div>
</template>

<script>
    import Comments from "../../components/Comments";
    import OutLink from "../../components/OutLink";
    export default {
        name: 'CallRecordDetail',
         components: { Comments, OutLink },
        data() {
            return {
                ifEditDrawer: false,
                digitalDetail: '',
                moduleNumList: [],
                currentSelect: '',
                description: '',
                moduleId: '',
                currentId: '',
                operationAuthority: ''
            }
        },
        methods: {
            jumpToUrl(url) {
                window.open(url)
            },
            editSence() {
                this.ifEditDrawer = true
            },
            saveEdit() {
                this.ifEditDrawer = false
                this.axios.post('/api/callRecord/update', {
                    id: this.currentId,
                    owner: this.currentSelect!=''?this.currentSelect:undefined,
                    remark: this.description!=''?this.description:undefined,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('修改成功')
                        this.description = ''
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelEdit() {
                this.ifEditDrawer = false
            },
            querySelectData() {
                this.axios(
                   {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        for(let i = 0; i < this.digitalDetail.detailList.length; i++){
                            if(this.digitalDetail.detailList[i].code=='remark'){
                                this.description = this.digitalDetail.detailList[i]['value']
                            }
                        }
                        this.$refs.commentChild.getParentCommentTree(this.moduleId, this.currentId,true)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            changeState(val) {
                this.currentSelect = val
            },
            queryAuthority() {
                this.axios(
                    {
                        method:'get',
                        url:'api/module/'+this.moduleId+'/operationAuthority', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.operationAuthority = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            }
        },
        mounted: function() {
            this.currentId = this.$route.query.id
            this.moduleId = this.$route.query.moduleId
            this.queryAuthority()
            this.querySelectData()
        }
    }
</script>

<style scoped lang="less">
    .call-detail{
        height: 100%;
        position: relative;
        background: rgba(240, 242, 243, 1);
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                width: 64px;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 64px 16px 68px 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            overflow-y: auto;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    overflow-y: auto;
                    display: flex;
                    .left-content{
                        width: 288px;
                        height: 100%;
                        background: #fff;
                        border-radius: 4px;
                        padding: 21px 24px;
                        .related-module{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                            span{
                                width: 3px;
                                height: 14px;
                                float: left;
                                background: rgba(24, 144, 255, 1);
                                margin: 4px 7px 0 0;
                            }
                        }
                    }
                    .center-content{
                        flex: 1;
                        height: 100%;
                        margin: 0 24px;
                        overflow-y: auto;
                        .basic-info{
                            width: 100%;
                            min-height: 400px;
                            background: #fff;
                            border-radius: 4px;
                            padding: 24px;
                            .title{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            .col-left{
                                padding-right: 24px;
                            }
                            .col-right{
                                padding-left: 24px;
                            }
                            .row-list{
                                .col-list{
                                    margin-top: 19px;
                                    margin-bottom: 5px;
                                    height: 69px;
                                    .first-content{
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                    }
                                    .second-content{
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        margin-top: 9px;
                                        img{
                                            width: 21px;
                                        }
                                        .change-head{
                                            color: rgba(24, 144, 255, 1);
                                            font-size: 12px;
                                            cursor: pointer;
                                            margin-left: 24px;
                                        }
                                    }
                                    .selection-range{
                                        margin-top: 9px;
                                        width: 100%;
                                    }
                                }
                            }
                        }  
                        .comments{
                            padding: 18px 24px;
                            background: #fff;
                            margin-top: 16px;
                            border-radius: 4px;
                            .title{
                                color: rgba(18, 18, 18, 1);;
                                font-size: 14px;
                            }
                            .textarea-comment{
                                margin-top: 20px;
                                background: rgba(247, 247, 247, 1);
                                border: none;
                                resize: none;
                            }
                            .release{
                                text-align: right;
                                color: rgba(24, 144, 255, 1);
                                font-size: 14px;
                                margin-top: 4px;
                                span{
                                    cursor: pointer;
                                }
                            }
                        }  
                    }
                    .right-content{
                        width: 288px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        .data-info{
                            width: 100%;
                            height: 280px;
                            background: #fff;
                            border-radius: 4px;
                            margin-bottom: 16px;
                            padding: 18px 24px;
                            .related-info{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                            ul{
                                li{
                                    padding-top: 24px;
                                    div:nth-child(1){
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                        margin-bottom: 4px;
                                    }
                                    div:nth-child(2){
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        .fast-operate{
                            width: 100%;
                            flex: 1;
                            background: #fff;
                            padding: 18px 24px;
                            .fast-do{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .footer-edit{
            height: 52px;
            line-height: 52px;
            text-align: center;
            position: absolute;
            bottom: 0px;
            left: 0px;
            z-index: 100;
            width: 100%;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0 0 0 0.06);
            button{
                margin-right: 8px;
            }
        }
    }
</style>