import Vue from 'vue';
import VueRouter from 'vue-router';
import { sessionData } from "../storage/storage";
import Login from '../views/login/Login.vue';
import LoginAuto from '../views/login/LoginAuto.vue';
import AutoLogin from '../views/login/AutoLogin.vue';
import Index from '../views/Index.vue';
import Test from '../views/test.vue';
import Test2 from '../views/test2.vue';
import PublicPage from '../views/PublicPage.vue';
import TaskOutLink from '../views/TaskOutLink.vue';
import DownloadFile from '../views/DownloadFile.vue';

  import PublicMenu from '../views/dashboard/PublicMenu.vue';
    import DigitalEmployee from '../views/dashboard/DigitalEmployee.vue';
    import OutCallTask from '../views/dashboard/OutCallTask.vue';
    import WaitingNumber from '../views/dashboard/WaitingNumber.vue';
    import CallLog from '../views/dashboard/CallLog.vue';
    import ManualCall from '../views/dashboard/ManualCall.vue';
    import SeatStatistics from '../views/dashboard/SeatStatistics.vue';
    import CustomerLead from '../views/dashboard/CustomerLead.vue';
    import Share from '../views/sharepush/Share.vue';
    import Push from '../views/sharepush/Push.vue';
    import TagSet from '../views/sharepush/TagSet.vue';
    import NoticeTask from '../views/notice/NoticeTask.vue';
    import NoticeNumber from '../views/notice/NoticeNumber.vue';
    import NoticeCdr from '../views/notice/NoticeCdr.vue';
    import Sfa from '../views/sharepush/Sfa.vue';
    import AutoCallTask from '../views/autocall/AutoCallTask.vue';
    import AutoWaitingCall from '../views/autocall/AutoWaitingCall.vue';
    import AutoCdrCall from '../views/autocall/AutoCdrCall.vue';
    import AutoCallTaskDetail from '../views/autocallTaskDetail/AutoCallTaskDetail.vue';
    import CustomerForm from '../views/formManage/CustomerForm.vue';
    import FormData from '../views/formManage/FormData.vue';

  import DigitalDetail from '../views/digitalDetail/DigitalDetail.vue';
  import DigitalShare from '../views/digitalDetail/DigitalShare.vue';
  import CallRecordDetail from '../views/callRecord/CallRecordDetail.vue';
  import CallRecordShare from '../views/callRecord/CallRecordShare.vue';
  import ManualCallDetail from '../views/manualCallDetail/ManualCallDetail.vue';
  import WaitingCallDetail from '../views/waitingCall/WaitingCallDetail.vue';
  import WaitingCallShare from '../views/waitingCall/WaitingCallShare.vue';
  import OutCallDetail from '../views/outcallDetail/OutcallDetail.vue';
  import OutCallShare from '../views/outcallDetail/OutcallShare.vue';
  import CustomerLeadDetail from '../views/customerLeadDetail/CustomerLeadDetail.vue';
  import CustomerLeadShare from '../views/customerLeadDetail/CustomerLeadShare.vue';
  import CustomerDynamics from '../views/customerLeadDetail/CustomerDynamics.vue';
  import NoticeTaskDetail from '../views/noticeTaskDetail/NoticeTaskDetail.vue';

  import PublicPersonalCenter from '../views/personalCenter/PublicPersonalCenter.vue';
    import PersonalInfo from '../views/personalCenter/PersonalInfo.vue';
    import UserManage from '../views/personalCenter/UserManage.vue';
    import SystemSet from '../views/personalCenter/SystemSet.vue';
    import PermissionSet from '../views/personalCenter/PermissionSet.vue';
    import NumberManage from '../views/personalCenter/NumberManage.vue';
    import WiringGroup from '../views/personalCenter/WiringGroup.vue';

Vue.use(VueRouter)
const routes = [
  { 
    path: '/', 
    component: PublicPage,
    children: [
      { 
        path: '/', 
        redirect: '/login' 
      },
      { 
        path: '/login', 
        component: Login 
      },
      { 
        path: '/loginAuto', 
        component: LoginAuto 
      },
      { 
        path: '/autoLogin', 
        component: AutoLogin 
      },
      { 
        path: '/test', 
        component: Test 
      },
      { 
        path: '/test2', 
        component: Test2 
      },
      { 
        path: '/TaskOutLink', 
        component: TaskOutLink 
      },
      { 
        path: '/DownloadFile', 
        component: DownloadFile 
      },
      { 
        path: '/Index', 
        component: Index,
        children: [
          { path: '/', redirect: '/digitalEmployee' },
          { 
            path: '/PublicMenu', 
            component: PublicMenu,
            children: [
              { path: '/', redirect: '/digitalEmployee' },
              { 
                path: '/digitalEmployee', 
                component: DigitalEmployee,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/outCallTask', 
                component: OutCallTask,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/waitingNumber', 
                component: WaitingNumber,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/callLog', 
                component: CallLog,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/manualCall', 
                component: ManualCall,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/seatStatistics', 
                component: SeatStatistics,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/customerLead', 
                component: CustomerLead,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/customerDynamics', 
                component: CustomerDynamics,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: 'share', 
                component: Share,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: 'push', 
                component: Push,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: 'tagSet', 
                component: TagSet,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/noticeTask', 
                component: NoticeTask,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/noticeNumber', 
                component: NoticeNumber,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/noticeCdr', 
                component: NoticeCdr,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: 'sfa', 
                component: Sfa,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/autoCallTask', 
                component: AutoCallTask,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/autoWaitingCall', 
                component: AutoWaitingCall,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/autoCdrCall', 
                component: AutoCdrCall,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/customerForm', 
                component: CustomerForm,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/wiringGroup', 
                component: WiringGroup,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/formData', 
                component: FormData,
                meta: {
                  requireAuth: true,  
                }  
              },
            ]
          },
         
          

   
          { 
            path: '/PublicPersonalCenter', 
            component: PublicPersonalCenter,
            children: [
              { path: '/', redirect: '/personalInfo' },
              { 
                path: '/personalInfo', 
                component: PersonalInfo,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/userManage', 
                component: UserManage,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/numberManage', 
                component: NumberManage,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/systemSet', 
                component: SystemSet,
                meta: {
                  requireAuth: true,  
                }  
              },
              { 
                path: '/permissionSet', 
                component: PermissionSet,
                meta: {
                  requireAuth: true,  
                }  
              },

            ]
          },
        ]
      },
      { 
        path: '/digitalShare', 
        component: DigitalShare,
        meta: {
          requireAuth: false,  
        }  
      },
      { 
        path: '/callRecordShare', 
        component: CallRecordShare,
        meta: {
          requireAuth: false,  
        }  
      },
      { 
        path: '/waitingCallShare', 
        component: WaitingCallShare,
        meta: {
          requireAuth: false,  
        }  
      },
      { 
        path: '/outCallShare', 
        component: OutCallShare,
        meta: {
          requireAuth: false,  
        }  
      },
      { 
        path: '/customerLeadShare', 
        component: CustomerLeadShare,
        meta: {
          requireAuth: false,  
        }  
      },
      { 
        path: '/customerLeadDetail', 
        component: CustomerLeadDetail,
        meta: {
          requireAuth: true,  
        }  
      },
      { 
        path: '/autoCallTaskDetail', 
        component: AutoCallTaskDetail,
        meta: {
          requireAuth: true,  
        }
      },
      { 
        path: '/noticeTaskDetail', 
        component: NoticeTaskDetail,
        meta: {
          requireAuth: true,  
        }
      },
      { 
        path: '/digitalDetail', 
        component: DigitalDetail,
        meta: {
          requireAuth: true,  
        }  
      },
      { 
        path: '/callRecordDetail', 
        component: CallRecordDetail,
        meta: {
          requireAuth: true,  
        }  
      },
      { 
        path: '/manualCallDetail', 
        component: ManualCallDetail,
        meta: {
          requireAuth: true,  
        }  
      },
      { 
        path: '/waitingCallDetail', 
        component: WaitingCallDetail,
        meta: {
          requireAuth: true,  
        }  
      },
      { 
        path: '/outCallDetail', 
        component: OutCallDetail,
        meta: {
          requireAuth: true,  
        }  
      },
    ]
  },
  
]
const router = new VueRouter({
  mode: 'hash',
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
    if (sessionData('get','token')) {  // 获取当前的token是否存在
      next();
    } else {
      next('/Login')
    }
  }else { // 如果不需要权限校验，直接进入路由界面
    next();
  }
});
export default router;
