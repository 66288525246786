<template>
    <div class="outcall-task">
        <div class="head">
            <span class="title">{{moduleName}}</span>
            <a-button v-if="operationAuthority.NOTICE" type="primary" @click="pushSet">
                推送设置
            </a-button>
            <a-button v-if="operationAuthority.SHARE" type="primary" @click="shareSet">
                共享设置
            </a-button>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="content-in">
                <div class="modular-content">
                    <div class="modular-in">
                        <ViewGroup ref="child1"></ViewGroup>
                        <div class="modular-operation">
                            <DataRange ref="child2"></DataRange>
                            <div class="operation-group">
                                <ScreenColumn ref="child3"></ScreenColumn>
                                <a-divider class="divider" type="vertical" />
                                <a-button v-if="operationAuthority.ADD" style="margin-right:8px" @click="addNewTask" type="primary" icon="plus">
                                    新增
                                </a-button>
                                <Export v-if="operationAuthority.EXPORT" ref="child4"></Export>
                                <MoreOperation ref="child5"></MoreOperation>
                            </div>
                        </div>
                        <div class="table-data">
                            <a-table
                                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                                :columns="columns"
                                :data-source="tableData"
                                :pagination='false'
                                :scroll="{ x: 'max-content' }"
                                :loading='loading'
                            >
                                <span slot="dataTitle" slot-scope="text, record">
                                    <a-icon style="color:rgba(121, 136, 155, 0.5);margin-right:6px;float:left;margin-top:4px" @click="viewDetail1(record.id)" type="select" />
                                    <a @click="viewRecords(record)">{{ record.dataTitle }}</a>
                                </span>
                            </a-table>
                            <div class="pagination-table">
                                <a-pagination
                                    show-size-changer
                                    :total="total"
                                    :show-total="total => `共 ${total} 条     `"
                                    v-model="currentPage"
                                    @change="pageChange"
                                    @showSizeChange="onShowSizeChange"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-drawer
            :title='digitalDetail.moduleName+"-"+digitalDetail.dataTitle'
            placement="right"
            :closable="true"
            :visible="drawerVisible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public2">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="viewDetail" type="select" />
                            <a-icon v-if="operationAuthority.EXTERNAL_LINKS" @click="viewSharing" type="share-alt" />
                            <a-icon @click="onClose" type="close" />
                        </div>
                    </div>
                    <a-row class="row-head">
                        <a-col :span="8" class="col-left">
                            <div>创建人：</div>
                            <div>{{digitalDetail.createBy}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>创建时间：</div>
                            <div>{{digitalDetail.createTime}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>修改时间：</div>
                            <div>{{digitalDetail.updateTime}}</div>
                        </a-col>
                    </a-row>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    <div class="first-content">{{item.name}}:</div>
                                    <div style="height:21px;overflow-y:hidden" :title="item.value" v-if="!ifEditDrawer&&item.code!='workTime'" class="second-content">{{item.value}}</div>
                                    <div v-if="item.code=='workTime'" class="second-content">
                                        <span>{{item.value.timeList.length>0?'已设定':'未设定'}}</span>
                                        <span @click="changeTime(item)" v-if="ifEditDrawer" style="color:rgba(24, 144, 255, 1);font-size:14px;margin-left:24px;cursor:pointer">修改</span>
                                    </div>
                                    <div v-if="ifEditDrawer&&item.isEditable!=1" class="second-content">{{item.value}}</div>
                                    <div v-if="ifEditDrawer&&item.code=='name'&&item.isEditable==1">
                                        <a-input v-model="item.value" @change="changeTaskName" class="second-content" placeholder="任务名称" />
                                    </div>
                                    <div v-if="ifEditDrawer&&item.code=='description'&&item.isEditable==1">
                                        <a-textarea v-model="item.value" @change="changeDes" class="second-content" placeholder="请输入任务描述" :rows="2"/>
                                    </div>
                                    <div class="second-content" v-if="item.type=='USER'&&ifEditDrawer==true&&item.isEditable==1">
                                        <a-tree-select
                                            class="selection-range"
                                            v-model="item.valueList"
                                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                            :tree-data="item.userTree"
                                            :placeholder="item.value"
                                            tree-default-expand-all
                                            @change="changePerson"
                                        >
                                        </a-tree-select>
                                    </div>
                                    <div v-if="ifEditDrawer&&item.code=='callingNumer'&&item.isEditable==1">
                                        <div class="second-content">
                                            <span style="color:rgba(80, 80, 80, 1);">已选择</span>
                                            <span style="color:rgba(24, 144, 255, 1)"> {{selectedRowKeys1.length}} </span>
                                            <span style="color:rgba(80, 80, 80, 1)">个</span>
                                            <a-icon @click="selectNumber" style="margin-left:36px;color:rgba(121, 136, 155, 0.5);cursor:pointer" type="zoom-in" />
                                        </div>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="comments">
                            <Comments ref="commentChild"></Comments>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button v-if="!ifEditDrawer&&operationAuthority.DELETE" type="primary" ghost @click="deleteDrawer">删除</a-button>
                        <a-button v-if="!ifEditDrawer&&operationAuthority.EDIT" type="primary" @click="editDrawer">编辑</a-button>
                        <a-button v-if="ifEditDrawer" type="primary" ghost @click="cancelDrawer">取消</a-button>
                        <a-button v-if="ifEditDrawer" type="primary" @click="saveDrawer">保存</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-drawer
            :title='drawerAddName'
            placement="right"
            :closable="true"
            :visible="drawerAddVisiable"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose2"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '100'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '56px 0 0' }"
        >
            <div class="drawer-newtask drawer-public1">
                <div class="newtask-body">
                    <div class="newtask-content">
                        <a-row class="row-list">
                            <a-col v-if="authorityFieldMap.name" :span="12" class="col-list col-left">
                                <div class="first-content">任务名称：</div>
                                <div class="second-content">
                                    <a-input v-model="newTaskName" placeholder="请输入任务名称" />
                                </div>
                            </a-col>
                            <a-col v-if="authorityFieldMap.digital_employee_id" :span="12" class="col-list col-right">
                                <div class="first-content">数据员工：</div>
                                <div class="second-content">
                                    <a-select default-value="" class="digital-staff"  v-model="selectedEmployee">
                                        <a-select-option v-for="(item,index) in DiditalEmployeeList" :key='index' :value='item.value'>
                                            {{item.name}}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-list">
                            <a-col v-if="authorityFieldMap.description" :span="12" class="col-list col-left">
                                <div class="first-content">外显号码：</div>
                                <div class="second-content">
                                    <span style="color:rgba(80, 80, 80, 1);">已选择</span>
                                    <span style="color:rgba(24, 144, 255, 1)"> {{selectedRowKeys1.length}} </span>
                                    <span style="color:rgba(80, 80, 80, 1)">个</span>
                                    <a-icon @click="selectNumber" style="margin-left:36px;color:rgba(121, 136, 155, 0.5);cursor:pointer" type="zoom-in" />
                                </div>
                            </a-col>
                            <a-col v-if="authorityFieldMap.description" :span="12" class="col-list col-right">
                                <div class="first-content">任务描述：</div>
                                <div class="second-content">
                                    <a-textarea v-model="newTaskDes" class="textarea-des" placeholder="请输入任务描述" :rows="3"/>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button type="primary" ghost @click="offEditDrawer">取消</a-button>
                        <a-button type="primary" @click="submitAndNext">提交并新增下一条</a-button>
                        <a-button type="primary" @click="submitDrawer">提交</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="createViewModal" title="创建视图" footer='' width='720px'>
            <div class="create-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
               
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelCreateView">取消</a-button>
                    <a-button type="primary" @click="sureCreateView">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="uoloadModal" title="logo图片" footer='' width='600px'>
            <div class="upload-modal">
                <a-divider class="divider1" type="horizontal" />
                <CropperImage ref="child"></CropperImage>
                <a-divider class="divider2" type="horizontal" />
                <div class="upload-btn">
                    <a-button type="primary" style="float:right" @click="uploadImg()">确定 </a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="setTimeModal" title="时间设定" footer='' width='1168px'>
            <div class="time-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="check-list">
                    <a-checkbox-group v-model="weekList">
                        <div class="check-item">
                            <a-checkbox value="MONDAY">
                                周一
                            </a-checkbox>
                        </div>
                        <div class="check-item">
                            <a-checkbox value="TUESDAY">
                                周二
                            </a-checkbox>
                        </div> 
                        <div class="check-item">
                            <a-checkbox value="WEDNESDAY">
                                周三
                            </a-checkbox>
                        </div>
                        <div class="check-item">
                            <a-checkbox value="THURSDAY">
                                周四
                            </a-checkbox>
                        </div> 
                        <div class="check-item">
                            <a-checkbox value="FRIDAY">
                                周五
                            </a-checkbox>
                        </div>
                        <div class="check-item">
                            <a-checkbox value="SATURDAY">
                                周六
                            </a-checkbox>
                        </div> 
                        <div class="check-item">
                            <a-checkbox value="SUNDAY">
                                周日
                            </a-checkbox>
                        </div>
                    </a-checkbox-group>
                </div>
                <div style="margin-bottom:12px;" v-for="(item,index) in timeList" :key='index'>
                    <div style="display:inline-block;width:160px" v-for="(item1,index1) in item" :key='index1'>
                        <a-time-picker style="width:72px;" v-model="item1.beginTime"  format="HH:mm" />
                        <span>-</span>
                        <a-time-picker style="width:72px;" v-model="item1.endTime"  format="HH:mm" />
                    </div>
                </div>
                <div style="margin-top:12px;">
                    <span @click="addTime" style="display:inline-block;width:144px;height:32px;color:rgba(24, 144, 255, 1);border:1px dotted rgba(24, 144, 255, 1);text-align:center;line-height:30px;cursor:pointer;border-radius:4px">+ 添加时间</span>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelCopyShare">取消</a-button>
                    <a-button type="primary" @click="sureCopyShare">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="linkModal" title="外链分享" footer='' width='600px'>
            <div class="share-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="copy-link">
                    <a-input id="copyHtml1"  :placeholder="linkUrl" />
                    <span @click="copyLink(linkUrl)">复制链接</span>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个任务吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDelete">取消</a-button>
                    <a-button type="primary" @click="sureDelete">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="chooseAccountModal" :closable='false' footer='' :zIndex='10000000' width='1020px'>
            <div class="choose-account-modal">
                <div class="add-title">
                    <span>选外显号码</span>
                    <a-icon @click="cancelChooseAccount" type="close" />
                </div>
                <div class="choose-account-data">
                    <a-row>
                        <a-col :span="13">
                            <div class="account-left-content">
                                <div class="sort-condition">
                                    <a-input v-model="searchNum" @input='inputChange()' placeholder="搜索号码" />
                                    <span @click="selectSortCondition(1)" :class="{'active':sortField=='remainingMinutes'}" class="sort-list">剩余分钟数</span>
                                    <!-- <span @click="selectSortCondition(2)" :class="{'active':sortField=='callCount'}" class="sort-list">近期外呼数量</span> -->
                                    <span @click="selectSortMode(1)"  :class="{'active':sortMode=='asc'}" class="sort-list">升序</span>
                                    <span @click="selectSortMode(2)" :class="{'active':sortMode=='desc'}" class="sort-list">降序</span>
                                </div>
                                <div class="account-table">
                                    <a-table
                                        :row-selection="{ selectedRowKeys: selectedRowKeys1, onChange: onSelectChange1 }"
                                        :columns="columns1"
                                        :data-source="numberData1"
                                        :pagination='false'
                                        scroll="{ x: '100%', y: '240'}"
                                        :rowKey="(record,index)=>{return record.id}"
                                    >
                                    </a-table>
                                    <div class="pagination-table">
                                        <a-pagination
                                            show-size-changer
                                            :total="totalNumber"
                                            :show-total="totalNumber => `共 ${totalNumber} 条     `"
                                            v-model="currentPage2"
                                            @change="pageChange2"
                                            @showSizeChange="onShowSizeChange2"
                                            :pageSizeOptions="['10', '50', '100', '200']"
                                        />
                                    </div>
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="11">
                            <div class="account-right-content">
                                <div class="choosed-title">
                                    <span>已选择：{{ selectedRowKeys1.length }}条</span>
                                    <span @click="cleanSelected">清空</span>
                                </div>
                                <div class="choosed-content">
                                    <div class="choosed-item" v-for="(item,index) in selectedRowKeys1" :key='index'>
                                        <a-icon class="choosed-phone" type="phone" />
                                        <span class="choosed-number">{{dataObj[item]}}</span>
                                        <a-icon class="choosed-close" @click="deleteSelectedNum(index)" type="close" />
                                    </div>
                                </div>
                            </div>
                        </a-col>
                    </a-row>
                </div>
                <div class="btn-group">
                    <div>
                        <a-button type="default" @click="cancelChooseAccount">取消</a-button>
                        <a-button type="primary" @click="sureChooseAccount">确认</a-button>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
    import ViewGroup from '../../components/ViewGroup'
    import ScreenColumn from '../../components/ScreenColumn'
    import Export from '../../components/Export'
    import DataRange from '../../components/DataRange'
    import MoreOperation from '../../components/MoreOperation'
    import moment from 'moment'
    import Comments from "../../components/Comments";
    const columns = []
    const tableData = []
    export default {
        name: 'OutCallTask',
        components: {ViewGroup, ScreenColumn, Export, DataRange, MoreOperation, Comments},
        data () {
            return {
                drawerVisible: false,
                drawerAddVisiable: false,
                ifEditTaskDrawer: false,
                createViewModal: false,
                currentPage: 1,
                pageSize: 10,
                moduleId: '',
                tableData,
                columns,
                DiditalEmployeeList: [],
                selectedEmployee: '',
                newTaskName: '',
                newTaskDes: '',
                total: 0,
                showField: {
                    fieldList: [],
                    valueList: []
                },
                conditionList: [],
                selectedRowKeys: [],
                dataId: '',
                uoloadModal: false,
                imgId: '',
                currentSelect: '',
                digitalDetail: {
                    dataTitle: ''
                },
                ifEditDrawer: false,
                setTimeModal: false,
                timeList: [],
                weekList: [],
                taskName: undefined,
                taskDes: undefined,
                chargePerson: undefined,
                operationAuthority: '',
                authorityFieldMap: {
                    name: '',
                    digital_employee_id: '',
                    description: ''
                },
                linkModal: false,
                linkUrl: '',
                deleteModal: false,
                loading: false,
                chooseAccountModal: false,
                selectedRowKeys1: [],
                columns1: [
                     {
                        dataIndex: 'id',
                        key: 'id',
                        title: 'id',
                        colSpan: 0,
                        customRender: () => {
                            return {
                                attrs: {
                                    colSpan: 0,
                                },
                            };
                        },
                    },
                    {
                        dataIndex: 'number',
                        scopedSlots: {
                            customRender: 'number'
                        },
                        key: 'number',
                        title: '号码'
                    },
                    // {
                    //     dataIndex: 'callCount',
                    //     scopedSlots: {
                    //         customRender: 'callCount'
                    //     },
                    //     key: 'callCount',
                    //     title: '近90天外呼次数',
                    // },
                    {
                        dataIndex: 'expirationDate',
                        scopedSlots: {
                            customRender: 'expirationDate'
                        },
                        key: 'expirationDate',
                        title: '有效期'
                    },
                    {
                        dataIndex: 'remainingMinutes',
                        scopedSlots: {
                            customRender: 'remainingMinutes'
                        },
                        key: 'remainingMinutes',
                        title: '剩余分钟数'
                    },
                    {
                        dataIndex: 'remark',
                        scopedSlots: {
                            customRender: 'remark'
                        },
                        key: 'remark',
                        title: '备注'
                    },
                ],
                numberData1: [],
                timer: null,
                searchId: '',
                dataObj: {},
                searchNum: '',
                changeNumberType: '',//'new新增，edit修改'
                totalNumber: 0,
                currentPage2: 1,
                pageSize2: 10,
                sortField: '',
                sortMode: '',
                flag: 0,
                moduleName: '',
                drawerAddName: ''
            }
        },
        methods: {
            moment,
            queryBasicData(type) {
                this.loading = true
                if(type == 'init'){
                    this.currentPage = 1
                }
                this.axios.post('/api/module/data/list', {
                    current: this.currentPage,
                    size: this.pageSize,
                    moduleId: this.moduleId,
                    dataViewId: this.$refs.child1.dataViewId,
                    dataRange: this.$refs.child2.selectedDataRangeValue,
                    conditionList: this.$refs.child3.conditionList
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.loading = false
                        this.tableData = response.data.data.tableData.records
                        this.columns = response.data.data.tableHeader
                        for(let i = 0; i < this.columns.length; i++){
                            if(this.columns[i]['title'] == 'id'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    colSpan:0,  
                                    customRender: () => {
                                        return {
                                            attrs: {
                                                colSpan: 0,
                                            },
                                        };
                                    },
                                }
                            }
                            if(this.columns[i]['dataIndex'] == 'dataTitle'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    scopedSlots: { customRender: 'dataTitle' },
                                }
                            }
                        }
                        this.total = response.data.data.tableData.total
                        setTimeout(()=>{
                            this.$refs.child4.getTotalNum(response.data.data.tableData.total, this.moduleId)
                        },2000)
                        
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            viewRecords(record) {
                this.drawerVisible = true;
                this.dataId = record.id
                this.querySelectData()
            },
            pageChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            onShowSizeChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            queryColumnList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.moduleId+'/listPage/init', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.operationAuthority = response.data.data.operationAuthority
                        this.authorityFieldMap = response.data.data.operationAuthority.authorityFieldMap==undefined?{}:response.data.data.operationAuthority.authorityFieldMap
                        this.showField.fieldList = response.data.data.showField.fieldList
                        this.showField.valueList = response.data.data.showField.valueList
                        this.conditionList = response.data.data.conditionList
                        this.$refs.child3.getData(this.showField,this.moduleId,this.conditionList)
                        this.$refs.child5.getScreenData(this.moduleId,this.conditionList,this.operationAuthority)
                        this.$refs.child2.getDataRangeData(response.data.data.dataRange)
                        this.$refs.child1.getParentData(this.moduleId, response.data.data.dataViewList)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onClose() {
                this.drawerVisible = false
                this.imgId = ''
                this.currentSelect = ''
                this.ifEditDrawer = false
            },
            deleteDrawer() {
                this.drawerVisible = false
                this.deleteModal = true
            },
            sureDelete() {
                this.deleteModal = false
                this.axios.post('/api/module/data/delete/batch', {
                    moduleId: this.moduleId,
                    idList: [this.dataId],
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDelete() {
                this.deleteModal = false
            },
            addNewTask() {
                this.drawerAddVisiable = true
                this.changeNumberType = 'new'
                this.selectedRowKeys1 = []
            },
            editDrawer() {
                this.ifEditDrawer = true
                this.changeNumberType = 'edit'
                this.queryAccountData()
            },
            cancelDrawer() {
                this.ifEditDrawer = false
                this.imgId = ''
                this.currentSelect = ''
                this.ifEditDrawer = false
            },
            saveDrawer() {
                this.ifEditDrawer = false
                this.axios.post('/api/callTask/update', {
                    id: this.dataId,
                    name: this.taskName,
                    description: this.taskDes,
                    owner: this.chargePerson,
                    workTime: {
                        weekList: this.weekList,
                        timeList: this.timeList,
                    },
                    numberList: this.selectedRowKeys1
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('保存成功')
                        this.taskName = undefined,
                        this.taskDes = undefined,
                        this.chargePerson = undefined,
                        this.selectedRowKeys1 = []
                        this.queryBasicData()
                        this.querySelectData()()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            onClose2() {
                this.drawerAddVisiable = false
                this.selectedEmployee = ''
                this.newTaskName = ''
                this.newTaskDes = ''
            },
            offEditDrawer() {
                this.drawerAddVisiable = false
                this.selectedEmployee = ''
                this.newTaskName = ''
                this.newTaskDes = ''
            },
            submitAndNext() {
                this.addTask()
            },
            addTask(){
                this.axios.post('/api/callTask', {
                    digitalEmployeeId: this.selectedEmployee,
                    name: this.newTaskName,
                    description: this.newTaskDes,
                    numberList: this.selectedRowKeys1
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('任务创建成功')
                        this.selectedEmployee = ''
                        this.newTaskName = ''
                        this.newTaskDes = ''
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            submitDrawer() {
                this.addTask()
                this.drawerAddVisiable = false
            },
            handleChange(value) {
                console.log(`selected ${value}`);
            },
            cancelCreateView() {
                this.createViewModal = false
            },
            sureCreateView() {
                this.createViewModal = false
            },
            shareSet() {
                this.$router.push({
                    path: "/PublicMenu/share",
                    query: {
                        moduleId: this.moduleId,
                    }
                })
            },
            pushSet() {
                this.$router.push({
                    path: "/PublicMenu/push",
                    query: {
                        moduleId: 5
                    }
                })
            },
            viewDetail1(id) {
                let routeUrl = this.$router.resolve({
                    path: "/outCallDetail",
                    query: {
                        id: id,
                        moduleId: this.moduleId
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            viewDetail() {
                let routeUrl = this.$router.resolve({
                    path: "/outCallDetail",
                    query: {
                        id: this.dataId,
                        moduleId: this.moduleId,
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            viewSharing() {
                event.stopPropagation();
                this.linkModal = true
                let shareId = ''
                this.axios.post('/api/externalLinks',{
                    moduleId: this.moduleId,
                    dataId: this.dataId
                })
                .then(response=> {
                    if(response.data.code == 0){
                        shareId = response.data.data.id
                        let url1 = window.location.origin
                        this.linkUrl = url1+'/#/OutCallShare?id='+shareId+'&moduleId='+this.moduleId
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            queryDiditalEmployee() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/employee/select/list?hasInactive=0', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.DiditalEmployeeList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onSelectChange(selectedRowKeys) {
                let obj = {}
                obj.deptId = ''
                obj.digitalEmployeeId = ''
                obj.dataViewId = this.$refs.child1.dataViewId
                obj.dataRange = this.$refs.child2.selectedDataRangeValue
                obj.conditionList = this.$refs.child3.conditionList
                this.selectedRowKeys = selectedRowKeys;
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj)
                this.$refs.child5.getData(this.moduleId, this.selectedRowKeys)
            },
            passDataToChild() {
                let obj = {}
                obj.deptId = ''
                obj.digitalEmployeeId = ''
                obj.dataViewId = this.$refs.child1.dataViewId
                obj.dataRange = this.$refs.child2.selectedDataRangeValue
                obj.conditionList = this.$refs.child3.conditionList
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj)
            },
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.dataId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        this.$refs.commentChild.getParentCommentTree(this.moduleId, this.dataId, true)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            cancelCopyShare() {
                this.setTimeModal = false
            },
            sureCopyShare() {
                this.setTimeModal = false
                for(let i = 0; i < this.timeList.length; i++){
                    for(let j = 0; j < this.timeList[i].length; j++){
                        if(this.timeList[i][j]['beginTime']!=null){
                            this.timeList[i][j]['beginTime'] = moment(this.timeList[i][j]['beginTime'], 'HH:mm').format('HH:mm')
                        }
                        if(this.timeList[i][j]['endTime']!=null){
                            this.timeList[i][j]['endTime'] = moment(this.timeList[i][j]['endTime'], 'HH:mm').format('HH:mm')
                        }
                    }
                }
            },
            changeTime(item) {
                this.timeList = item.value.timeList
                for(let i = 0; i < this.timeList.length; i++){
                    for(let j = 0; j < this.timeList[i].length; j++){
                        if(this.timeList[i][j]['beginTime']!=null){
                            this.timeList[i][j]['beginTime'] = moment(this.timeList[i][j]['beginTime'], 'HH:mm')
                            this.timeList[i][j]['endTime'] = moment(this.timeList[i][j]['endTime'], 'HH:mm')
                        }
                    }
                }
                this.weekList = item.value.weekList
                this.setTimeModal = true
            },
            addTime() {
                let arr = []
                arr = [
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                ]
                this.timeList.push(arr)
            },
            changeTaskName(e) {
                this.taskName = e.target.value
            },
            changeDes(e) {
                this.taskDes = e.target.value
            },
            changePerson(e) {
                this.chargePerson = e
            },
            copyLink(url) {
                this.linkModal = false
                let text = url
                let input = document.getElementById("copyHtml1");
                input.value = text; 
                input.select(); 
                document.execCommand("copy"); 
                this.$message.success('已复制！')
            },
            cancelChooseAccount() {
                this.chooseAccountModal = false
            },
            sureChooseAccount() {
                this.chooseAccountModal = false
            },
            onSelectChange1(selectedRowKeys) {
                this.selectedRowKeys1 = selectedRowKeys;
            },
            selectNumber() {
                this.chooseAccountModal = true
                this.flag = 0
                this.queryAccountData()
            },
            queryAccountData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/callTask/callingNumberList', 
                        params: {
                            number: this.searchNum,
                            callTaskId: this.changeNumberType=='new'?'':this.dataId,
                            current: this.currentPage2,
                            size: this.pageSize2,
                            sortField: this.sortField,
                            sortMode: this.sortMode
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.numberData1 = response.data.data.allCallingNumberList.records
                        this.totalNumber = response.data.data.allCallingNumberList.total
                        let selectedData = response.data.data.callTaskNumberList
                        for(let i = 0; i < this.numberData1.length; i++){
                            this.dataObj[this.numberData1[i]['id']] = this.numberData1[i]['number']
                        }
                        if(this.flag < 1){
                            this.selectedRowKeys1 = []
                            for(let i = 0; i < selectedData.length; i++){
                                this.dataObj[selectedData[i]['id']] = selectedData[i]['number']
                            }
                            for(let i = 0; i < selectedData.length; i++){
                                this.selectedRowKeys1.push(selectedData[i]['id'])
                            }
                            this.flag ++
                        }
                        
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            inputChange() {
                clearTimeout(this.timer)
                this.timer = setTimeout(()=>{
                    this.queryAccountData()
                },1500)
            },
            deleteSelectedNum(index) {
                this.selectedRowKeys1.splice(index, 1);
            },
            cleanSelected() {
                this.selectedRowKeys1 = []
            },
            pageChange2(current, pageSize) {
                this.currentPage2 = current
                this.pageSize2 = pageSize
                this.queryAccountData()
            },
            onShowSizeChange2(current, pageSize) {
                this.currentPage2 = current
                this.pageSize2 = pageSize
                this.queryAccountData()
            },
            selectSortCondition(data) {
                if(data==1&&this.sortField=='remainingMinutes'||(data==2&&this.sortField=='callCount')){
                    this.sortField = ''
                }else if(data == 1&&this.sortField!='remainingMinutes'){
                    this.sortField = 'remainingMinutes'
                }else if(data == 2&&this.sortField!='callCount'){
                    this.sortField = 'callCount'
                }
                this.queryAccountData()
            },
            selectSortMode(data) {
                if(data==1&&this.sortMode=='asc'||(data==2&&this.sortMode=='desc')){
                    this.sortMode = ''
                }else if(data == 1&&this.sortMode!='asc'){
                    this.sortMode = 'asc'
                }else if(data == 2&&this.sortMode!='desc'){
                    this.sortMode = 'desc'
                }
                this.queryAccountData()
            }
        },
        mounted: function() {
            this.sessionData('set','publicMenu','outCallTask');
            this.moduleId = this.$route.query.moduleId
            this.queryDiditalEmployee()
            this.queryColumnList()
            this.moduleName = this.$route.query.name
            this.drawerAddName = this.$route.query.name + '-新增任务'
        }
    }
</script>

<style scoped lang="less">
    .outcall-task{
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            overflow-y: auto;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    border-radius: 4px;
                    .modular-in{
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        border-radius: 4px;
                        overflow-y: auto;
                        padding: 24px;
                        .modular-operation{
                            margin-top: 21px;
                            .operation-group{
                                display: inline-block;
                                float: right;
                                .divider{
                                    margin: 0 16px 0 0;
                                    height: 32px;
                                }
                                .download{
                                    margin: 0 8px;
                                }
                            }
                        }
                        .table-data{
                            margin-top: 24px;
                        }
                        .pagination-table{
                            float: right;
                            padding-top: 24px;
                        }
                    }
                    
                }
            }
            
        }
        
    }
    .drawer-public1{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-head{
            height: 88px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 104px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                div:nth-child(1){
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                div:nth-child(2){
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding-right: 16px;
            .basic-info{
                width: 100%;
                // height: 704px;
                background: #fff;
                border-radius: 4px;
                padding: 24px;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        height: 69px;
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            img{
                                width: 21px;
                            }
                            .change-head{
                                color: rgba(24, 144, 255, 1);
                                font-size: 12px;
                                cursor: pointer;
                                margin-left: 24px;
                            }
                        }
                        .selection-range{
                            margin-top: 9px;
                            width: 100%;
                        }
                    }
                }
            }  
            .comments{
                padding: 18px 24px;
                background: #fff;
                margin-top: 16px;
                border-radius: 4px;
                .title{
                    color: rgba(18, 18, 18, 1);;
                    font-size: 14px;
                }
                .textarea-comment{
                    margin-top: 20px;
                    background: rgba(247, 247, 247, 1);
                    border: none;
                    resize: none;
                }
                .release{
                    text-align: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    margin-top: 4px;
                    span{
                        cursor: pointer;
                    }
                }
            }  
        }
    }
    .drawer-newtask{
        .newtask-body{
            height: 496px;
            padding: 16px;
            .newtask-content{
                width: 100%;
                height: 100%;
                overflow: hidden;
                background: #fff;
                padding: 24px;
                border-radius: 4px;
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        .select-admin{
                            width: 336px;
                            height: 32px;
                            margin-top: 9px;
                        }
                        .textarea-remarks{
                            margin-top: 9px;
                        }
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            .digital-staff{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .create-modal{
        padding-bottom: 46px;
    }
    .drawer-public2{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 144px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 160px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .upload-modal{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        padding-bottom: 50px;
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
    }
    .time-modal{
        padding-bottom: 46px;
        .check-list{
            .check-item{
                display: inline-block;
                width: 160px;
                height: 32px;
            }
        }
    }
    .share-modal{
        .copy-link{
            height: 62px;
            input{
                width: 464px;
                float: left;
                height: 40px;
            }
            span{
                float: left;
                display: inline-block;
                width: 88px;
                height: 40px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                cursor: pointer;
                border-radius: 0px 4px 4px 0px;
                line-height: 40px;
                text-align: center;
            }
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .choose-account-modal{
        .add-title{
            padding: 20px 20px 14px 0px;
            border-bottom: 1px solid rgba(229, 229, 229, 1);
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 14px;
                font-weight: bold;
            }
            i{
                float: right;
                color: rgba(158, 172, 196, 1);
                font-size: 16px;
                cursor: pointer;
            }
        }
        .choose-account-data{
            padding: 24px 0;
            .account-left-content{
                padding-right: 24px;
                border-right: 1px solid rgba(229, 229, 229, 1);
                .account-table{
                    margin-top: 24px;
                }
                .pagination-table{
                    float: right;
                    padding-top: 24px;
                }
                .sort-condition{
                    input{
                        width: 150px;
                    }
                    .sort-list{
                        display: inline-block;
                        padding: 0 12px;
                        height: 32px;
                        line-height: 32px;
                        background: #fafafa;
                        margin-left: 10px;
                        font-size: 14px;
                        cursor: pointer;
                        border-radius: 4px;
                    }
                    span.active{
                        background: #1890FF;
                        color: #fff;
                    }
                }
            }
            .account-right-content{
                padding-left: 24px;
                .choosed-title{
                    span:nth-child(1){
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                    }
                    span:nth-child(2){
                        color: rgba(24, 144, 255, 1);
                        font-size: 14px;
                        float: right;
                        cursor: pointer;
                    }
                }
                .choosed-content{
                    margin-top: 24px;
                    max-height: 305px;
                    overflow-y: auto;
                    .choosed-item{
                        display: inline-block;
                        min-width: 132px;
                        height: 32px;
                        margin: 0 8px 8px 0;
                        background: rgba(121, 136, 155, 0.1);
                        text-align: center;
                        line-height: 32px;
                        padding: 0 8px;
                        .choosed-phone{
                            color: rgba(121, 136, 155, 1);
                            font-size: 16px;
                            float: left;
                            margin-top: 7px;
                        }
                        .choosed-number{
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            display: inline-block;
                            min-width: 84px;
                            overflow: hidden;
                        }
                        .choosed-close{
                            color: rgba(153, 153, 153, 1);
                            font-size: 14px;
                            float: right;
                            margin-top: 8px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .btn-group{
            border-top: 1px solid rgba(229, 229, 229, 1);
            padding: 16px 20px 20px 0;
            height: 58px;
            div{
                float: right;
            }
            button{
                display: inline-block;
                width: 64px;
                height: 28px;
                margin-left: 8px;
                font-size: 13px;
            }
            button:nth-child(1){
                color: rgba(158, 172, 196, 1);
            }
        }
    }
</style>