<template>
    <div class="waiting-number">
        <div class="head">
            <span class="title">{{moduleName}}</span>
        </div>
        <div class="content">
            <div class="content-in">
                <div class="modular-content">
                    <div style="height: 80px;background: #fff;margin-bottom:16px;border-radius: 4px;padding:24px 0 0 24px;">
                        <div style="display: inline-block;margin-top:4px;float: left;">
                            <span style="color:rgba(18, 18, 18, 1);font-size:14px;float: left;">选择表单</span>
                            <span style="color:rgba(255, 70, 70, 1);font-size:16px;margin: 1px 15px 0 2px;float: left;">*</span>
                        </div>
                        <a-select
                            show-search
                            placeholder="请选择"
                            option-filter-prop="children"
                            style="width: 265px"
                            :filter-option="filterOption"
                            @change="chooseForm"
                        >
                            <a-select-option :value="item.value" v-for="(item,index) in customFormList" :key="index">
                            {{item.name}}
                            </a-select-option>
                        </a-select>
                        <span v-if="formDataValue!=''&&formType!=1" @click="importData" style="display: inline-block;width:64px;height: 32px;line-height: 32px;text-align: center;color:#fff;background:rgba(24, 144, 255, 1);border-radius: 4px;cursor: pointer;float: right;margin-top: 8px;margin-right:24px;">导入</span>
                    </div>
                    <div class="modular-in">
                        <ViewGroup ref="child1" :formDataValue="formDataValue"></ViewGroup>
                        <div class="modular-operation">
                            <DataRange ref="child2"></DataRange>
                            <div class="operation-group">
                                <ScreenColumn ref="child3" :moduleid="moduleId" v-if="formDataValue!=''"></ScreenColumn>
                                <a-divider v-if="formDataValue!=''" class="divider" type="vertical" />
                                <span @click="allocateCurrentConditionData('current')" v-if="selectedRowKeys.length==0&&total!=0&&formType!=1" style="display:inline-block;width:136px;height:32px;background:rgba(24, 144, 255, 1);color:#fff;font-size:14px;cursor:pointer;border-radius: 4px;text-align: center;line-height: 32px;margin-right:8px;">分配当前条件数据</span>
                                <span @click="allocateCurrentConditionData('select')" v-if="selectedRowKeys.length!=0&&formType!=1" style="display:inline-block;width:136px;height:32px;background:rgba(24, 144, 255, 1);color:#fff;font-size:14px;cursor:pointer;border-radius: 4px;text-align: center;line-height: 32px;margin-right:8px;">分配选中数据</span>
                                <Export style="float: right;" ref="child4" :formDataValue="formDataValue"></Export>
                            </div>
                        </div>
                        <div class="table-data">
                            <a-table
                                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                                :columns="columns"
                                :data-source="tableData"
                                :pagination='false'
                                :scroll="{ x: 'max-content' }"
                                :loading='loading'
                            >
                                <span slot="dataTitle" slot-scope="text, record">
                                    <!-- <a-icon style="color:rgba(121, 136, 155, 0.5);margin-right:6px;float:left;margin-top:4px" @click="viewDetail1(record.id)" type="select" /> -->
                                    <a @click="viewRecords(record)">{{ record.dataTitle }}</a>
                                </span>
                                <div slot="phone" slot-scope="text, record">
                                    <span v-html="record.phone"></span>
                                </div>
                            </a-table>
                            <div class="pagination-table">
                                <a-pagination
                                    show-size-changer
                                    :total="total"
                                    :show-total="total => `共 ${total} 条     `"
                                    v-model="currentPage"
                                    @change="pageChange"
                                    @showSizeChange="onShowSizeChange"
                                    :page-size-options="pageSizeOptions"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-drawer
            title='待呼号码-手机号码'
            placement="right"
            :closable="true"
            :visible="drawerVisible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span :title="digitalDetail.moduleName+'-'+digitalDetail.dataTitle" class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <!-- <a-icon @click="viewDetail2" type="select" /> -->
                            <a-icon @click="onClose" type="close" />
                        </div>
                    </div>
                    <a-row class="row-head">
                        <a-col :span="8" class="col-left">
                            <div>创建人：</div>
                            <div>{{digitalDetail.createBy}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>创建时间：</div>
                            <div>{{digitalDetail.createTime}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>修改时间：</div>
                            <div>{{digitalDetail.updateTime}}</div>
                        </a-col>
                    </a-row>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    <div class="first-content">
                                        {{item.name}}:
                                        <span v-if="item.attribute=='PHONE'" class="take-phone" @click="playPhone(item.value)">
                                            {{item.value}}
                                            <img title='呼叫' class="take-phone1"  :value='item.value' src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone.svg'>
                                            <img title='呼叫' class="take-phone2"  :value='item.value' src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone1.svg'>
                                        </span>
                                        <a-icon v-if="ifEditDrawer&&item.isEditable==1" @click="changeSelect1('', item.fieldId)" style="color:rgba(121, 136, 155, 0.5);font-size:12px;cursor:pointer;float:right;" type="reload" />
                                    </div>
                                    <div v-if="!ifEditDrawer||(ifEditDrawer&&item.isEditable==0)" class="second-content">
                                        {{item.value}}
                                        <span v-if="item.attribute=='PHONE'">{{phoneDetails.city}}|{{phoneDetails.sp.length==0?'未知运营商':phoneDetails.sp}}</span>
                                    </div>

                                    <div v-if="ifEditDrawer&&item.type=='TEXT'&&item.isEditable==1">
                                        <a-input @blur="fieldInputBlur(item.fieldId,item.value)" v-model="item.value" class="second-content" placeholder="" />
                                    </div>
                                    <div v-if="ifEditDrawer&&item.type=='LONG_TEXT'&&item.isEditable==1">
                                        <a-textarea @blur="fieldInputBlur(item.fieldId,item.value)" v-model="item.value" class="second-content" placeholder="可以在这里输入通知内容，不能超过140个字符" :maxLength="140" :rows="2"/>
                                    </div>
                                    <div class="second-content" v-if="item.type=='SELECT_SINGLE'&&ifEditDrawer==true&&item.isEditable==1" style="display:flex">
                                        <a-select v-model="item.selectedValue" style="flex:1" @change="(value)=>changeSelect1(value,item.fieldId)">
                                            <a-select-option v-for="(item1,index1) in item.selectList" :key="index1" :value="item1.value">
                                                {{item1.name}}
                                            </a-select-option>
                                        </a-select>
                                    </div>
                                    <div class="second-content" v-if="item.type=='SELECT_MULTIPLE'&&ifEditDrawer==true&&item.isEditable==1" style="display:flex">
                                        <a-select v-model="item.selectedValue" mode="multiple" style="width: 100%" @change="(value)=>changeSelect2(value,item.fieldId)">
                                            <a-select-option :value="item1.value" v-for="(item1, index1) in item.selectList" :key='index1'>
                                                {{item1.name}}
                                            </a-select-option>
                                        </a-select>
                                    </div>
                                    <div class="second-content" v-if="item.type=='TIME'&&ifEditDrawer==true&&item.isEditable==1" style="display:flex">
                                        <a-date-picker @change="(date)=>changeDate(date,item.fieldId)" v-model="item.value" style="width:100%" format="YYYY-MM-DD HH:mm:ss" />
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="basic-info" style="margin-top:16px;" v-if="detailList1.length!=0">
                            <div class="title">外呼信息</div>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList1" :key='index'>
                                    <div class="first-content">{{item.name}}:</div>
                                    <div class="second-content">
                                        <span v-if="item.name!='录音'">{{item.value}}</span>
                                        <a-icon v-if="item.name=='录音'" @click="tryListen(item.value)" style="font-size: 14px;cursor: pointer;;" type="play-circle" />
                                        <a-icon v-if="item.name=='录音'" class="try-down" @click="jumpToUrl(item.value)" title="下载" style="margin-left:16px" type="download" />
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="basic-info" style="margin-top:16px;" v-if="detailList1.length==0">
                            <div class="title">90天内人工呼出记录</div>
                            <a-row style="margin:25px 0 8px;color:rgba(188, 188, 188, 1);font-size:12px;">
                                <a-col :span="6" >
                                    <div>时间</div>
                                </a-col>
                                <a-col :span="6" >
                                    <div>号码</div>
                                </a-col>
                                <a-col :span="4" >
                                    <div>通话时长</div>
                                </a-col>
                                <a-col :span="4" >
                                    <div>负责人</div>
                                </a-col>
                                <a-col :span="4" >
                                    <div>录音</div>
                                </a-col>
                            </a-row>
                            <a-row v-for="(item,index) in detailList2" :key='index' style="color:rgba(51, 51, 51, 1);font-size:14px;height: 29px;line-height: 29px;">
                                <a-col :span="6" >
                                    <div>{{item.createTime}}</div>
                                </a-col>
                                <a-col :span="6" >
                                    <div>{{item.phone}}</div>
                                </a-col>
                                <a-col :span="4" >
                                    <div>{{item.duration}}</div>
                                </a-col>
                                <a-col :span="4" >
                                    <div>{{item.owner}}</div>
                                </a-col>
                                <a-col :span="4" >
                                    <a-icon @click="tryListen(item.voiceUrl)" style="font-size: 14px;cursor: pointer;color:rgba(24, 144, 255, 1);" type="play-circle" />
                                </a-col>
                            </a-row>
                            <div v-if="detailList2.length==0" style="padding:55px 0 70px">
                                <div style="text-align:center">
                                    <img style="width:48px;" src="../../assets/img/no-seat.png" alt="">
                                </div>
                                <div style="color:rgba(179, 184, 193, 1);font-size:12px;text-align:center;">暂无数据</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button v-if="!ifEditDrawer" type="primary" ghost @click="deleteDrawer">删除</a-button>
                        <a-button v-if="!ifEditDrawer" type="primary" @click="editDrawer">编辑</a-button>
                        <a-button v-if="ifEditDrawer" type="primary" ghost @click="cancelDrawer">取消</a-button>
                        <a-button v-if="ifEditDrawer" type="primary" @click="saveDrawer">保存</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteSelectedModal" title="删除选中" footer='' width='720px'>
            <div class="delete-selected-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="des">共筛选出数据（数据最大操作上限为10000条，超过将只会执行前10000条）：</div>
                <div class="data-num">6条</div>

                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelDeleteSelected">取消</a-button>
                    <a-button type="primary" @click="sureDeleteSelected">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="setConditionModal" title="高级批量-设置筛选条件" footer='' width='720px'>
            <div class="set-condition-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <ScreenCondition></ScreenCondition>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelSetCondition">取消</a-button>
                    <a-button type="primary" @click="sureSetCondition">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="batchDeleteModal" title="高级批量-批量修改" footer='' width='720px'>
            <div class="batch-delete-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="des">共筛选出数据（数据最大操作上限为10000条，超过将只会执行前10000条）：</div>
                <div class="data-num">6条</div>

                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelBatchDelete">取消</a-button>
                    <a-button type="primary" @click="sureBatchDelete">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="linkModal" title="外链分享" footer='' width='600px'>
            <div class="share-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="copy-link">
                    <a-input id="copyHtml1"  :placeholder="linkUrl" />
                    <span @click="copyLink(linkUrl)">复制链接</span>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="delModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个号码吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDelData">取消</a-button>
                    <a-button type="primary" @click="sureDelData">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="showAudioModal" footer='' @cancel="closeAudio">
            <div style="padding:24px;text-align: center">
                <audio style="display: inline-block;z-index:10000" controls autoplay preload="auto" :src="musicSrc"></audio>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="normalModal" title="导入" footer='' width='600px'>
            <div class="normal-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="tab-normal">
                    <div>
                        <span style="color:rgba(80, 80, 80, 1);font-size:14px">*为保证数据导入顺利，请先</span>
                        <a-button @click="downloadTemplate" icon="download" style="margin-left:8px">
                        下载模板
                        </a-button>
                    </div>
                    <div style="min-height:166px;margin-top:24px">
                        <a-upload-dragger
                            key={Math.random()}
                            name="file"
                            :multiple="false"
                            :customRequest="customRequest"
                            @change="onUpload"
                            ref="pathClear"
                        >
                            <p class="ant-upload-drag-icon">
                            <a-icon type="upload" />
                            </p>
                            <p class="ant-upload-text">
                            点击或将文件拖拽到这里上传
                            </p>
                            <p class="ant-upload-hint">
                            支持扩展名：.xls .xlsx
                            </p>
                        </a-upload-dragger>
                    </div>
                    <div style="margin-top:16px;color:rgba(153, 153, 153, 1);font-size:14px">上传的 Excel 表符合以下规范::</div>
                    <div style="color:rgba(80, 80, 80, 1);margin-top:8px;font-size:12px">文件大小不超过20MB<br>仅支持 (*.xls 和 *.xlsx)文件<br>请确保您需要导入的sheet表头中不包含空的单元格，否则该sheet页数据系统将不做导入</div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelNormal">取消</a-button>
                    <a-button type="primary" @click="sureNormal">确认</a-button>
                </div>
            </div>
        </a-modal>  
        <a-modal :centered='true' :maskClosable='false' v-model.trim="currentConditionDataModal" title="批量分配" footer='' width='600px'>
            <div class="batch-delete-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="des">本次即将分配的数据量：</div>
                <div class="data-num">{{conditionDataNum}}条</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelCurrentConditionData">取消</a-button>
                    <a-button type="primary" @click="nextCurrentConditionData">下一步</a-button>
                </div>
            </div>
        </a-modal>  
        <a-modal :centered='true' :maskClosable='false' v-model.trim="batchAllocationModal" title="批量分配" footer='' width='600px'>
            <div class="batch-delete-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div>选择要分配的人（单选）：</div>
                <div>
                    <a-input placeholder="搜索人" style="margin-top:8px;" @change="onChange2" />
                    <div class="person-tree" style="max-height: 500px;overflow-y: auto;">
                        <a-tree
                            v-model="checkedKeys2"
                            checkable
                            :expanded-keys="expandedKeys2"
                            :auto-expand-parent="autoExpandParent2"
                            :tree-data="peopleTree"
                            :defaultExpandAll="true"
                            @expand="onExpand2"
                        />
                    </div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelBatchAllocation">取消</a-button>
                    <a-button type="primary" @click="sureBatchAllocation">确定</a-button>
                </div>
            </div>
        </a-modal>  
    </div>
</template>


<script>
    import ViewGroup from '../../components/ViewGroup'
    import ScreenColumn from '../../components/ScreenColumn'
    import Export from '../../components/Export'
    import ScreenCondition from '../../components/ScreenCondition'
    import DataRange from '../../components/DataRange'
    const columns = []
    const tableData = []
    export default {
        name: 'WaitingNumber',
        components: {ViewGroup, ScreenColumn, Export, ScreenCondition, DataRange},
        data () {
            return {
                drawerVisible: false,
                deleteSelectedModal: false,
                setConditionModal: false,
                batchDeleteModal: false,
                currentPage: 1,
                pageSize: 10,
                moduleId: '',
                tableData,
                columns,
                total: 0,
                showField: {
                    fieldList: [],
                    valueList: []
                },
                conditionList: [],
                selectedRowKeys: [],
                currentId: '',
                digitalDetail: {
                    dataTitle: ''
                },
                operationAuthority: '',
                linkModal: false,
                linkUrl: '',
                loading: false,
                pageSizeOptions: ["50", "100", "200"],
                delModal: false,
                moduleName: '',
                customFormList: [],
                formDataValue: '',
                ifEditDrawer: false,
                timeList: [],
                weekList: [],
                musicSrc: '',
                showAudioModal: false,
                normalModal: false,
                newFormData: '',
                detailList1: [],
                formType: '',
                phoneDetails: {
                    city: '',
                    sp: ''
                },
                detailList2: [],
                currentConditionDataModal: false,
                conditionDataNum: 0,
                batchAllocationModal: false,
                expandedKeys2: [],
                checkedKeys2: [],
                peopleTree: [],
                autoExpandParent2: true,
                deptAndUserMap: '',
                dataList1: [],
                searchValue1: '',
                dataList2: [],
                searchValue2: '',
                selectedDataType: '',
                countNum: 0
            }
        },
        methods: {
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        this.detailList1 = response.data.data.detailList1
                        this.detailList2 = response.data.data.detailList2
                        let arr = response.data.data.detailList
                        for(let i = 0;i < arr.length; i++){
                            if(arr[i]['attribute'] == 'PHONE'){
                                this.queryPhoneDetail(arr[i]['value'])
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryBasicData(type) {
                this.loading = true
                if(type == 'init'){
                    this.currentPage = 1
                }
                this.axios.post('/api/module/data/list', {
                    current: this.currentPage,
                    size: this.pageSize,
                    moduleId: this.moduleId,
                    dataViewId: this.$refs.child1.dataViewId,
                    dataRange: this.$refs.child2.selectedDataRangeValue,
                    conditionList: this.$refs.child3.conditionList,
                    customFormId: this.formDataValue
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.loading = false
                        this.tableData = response.data.data.tableData.records
                        this.columns = response.data.data.tableHeader
                        for(let i = 0; i < this.columns.length; i++){
                            if(this.columns[i]['title'] == 'id'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    colSpan:0,  
                                    customRender: () => {
                                        return {
                                            attrs: {
                                                colSpan: 0,
                                            },
                                        };
                                    },
                                }
                            }
                            if(this.columns[i]['dataIndex'] == 'dataTitle'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    scopedSlots: { customRender: 'dataTitle' },
                                }
                            }
                        }
                        this.total = response.data.data.tableData.total
                        setTimeout(()=>{
                            this.$refs.child4.getTotalNum(response.data.data.tableData.total, this.moduleId)
                        },2000)
                        
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            viewRecords(record) {
                this.drawerVisible = true;
                this.currentId = record.id
                this.querySelectData()
            },
            pageChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            onShowSizeChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            queryColumnList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.moduleId+'/listPage/init', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.customFormList = response.data.data.customFormList
                        this.operationAuthority = response.data.data.operationAuthority
                        this.$refs.child2.getDataRangeData(response.data.data.dataRange)
                        
                        if(this.moduleId!=19){
                            this.conditionList = response.data.data.conditionList
                            this.showField.fieldList = response.data.data.showField.fieldList
                            this.showField.valueList = response.data.data.showField.valueList
                            this.$refs.child3.getData(this.showField,this.moduleId,this.conditionList)
                            this.$refs.child1.getParentData(this.moduleId, response.data.data.dataViewList)
                        }else{
                            this.$refs.child1.passModuleId(19)
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onClose() {
                this.drawerVisible = false
            },
            deleteDrawer() {
                this.drawerVisible = false
                this.delModal = true
            },
            sureDelData() {
                this.delModal = false
                this.axios.post('/api/module/data/delete/batch', {
                    moduleId: this.moduleId,
                    idList: [this.currentId],
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDelData() {
                this.delModal = false
            },
            moreOperate(e) {
                if(e.key == 1){
                    this.deleteSelectedModal = true
                }else if(e.key == 2){
                    this.setConditionModal = true
                }
            },
            cancelDeleteSelected() {
                this.deleteSelectedModal = false
            },
            sureDeleteSelected() {
                this.deleteSelectedModal = false
            },
            cancelSetCondition() {
                this.setConditionModal = false
            },
            sureSetCondition() {
                this.setConditionModal = false
                this.batchDeleteModal = true
            },
            cancelBatchDelete() {
                this.batchDeleteModal = false
            },
            sureBatchDelete() {
                this.batchDeleteModal = false
            },
            viewDetail1(id) {
                let routeUrl = this.$router.resolve({
                    path: "/waitingCallDetail",
                    query: {
                        id: id,
                        moduleId: this.moduleId
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            viewDetail2() {
                let routeUrl = this.$router.resolve({
                    path: "/waitingCallDetail",
                    query: {
                        id: this.currentId,
                        moduleId: this.moduleId
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            viewSharing() {
                event.stopPropagation();
                this.linkModal = true
                let shareId = ''
                this.axios.post('/api/externalLinks',{
                    moduleId: this.moduleId,
                    dataId: this.currentId
                })
                .then(response=> {
                    if(response.data.code == 0){
                        shareId = response.data.data.id
                        let url1 = window.location.origin
                        this.linkUrl = url1+'/#/WaitingCallShare?id='+shareId+'&moduleId='+this.moduleId
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            onSelectChange(selectedRowKeys) {
                let obj = {}
                obj.deptId = ''
                obj.digitalEmployeeId = ''
                obj.dataViewId = this.$refs.child1.dataViewId
                obj.dataRange = this.$refs.child2.selectedDataRangeValue
                obj.conditionList = this.$refs.child3.conditionList
                this.selectedRowKeys = selectedRowKeys;
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj)
            },
            passDataToChild() {
                let obj = {}
                obj.deptId = ''
                obj.digitalEmployeeId = ''
                obj.dataViewId = this.$refs.child1.dataViewId
                obj.dataRange = this.$refs.child2.selectedDataRangeValue
                obj.conditionList = this.$refs.child3.conditionList
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj)
            },
            copyLink(url) {
                this.linkModal = false
                let text = url
                let input = document.getElementById("copyHtml1");
                input.value = text; 
                input.select(); 
                document.execCommand("copy"); 
                this.$message.success('已复制！')
            },
            playPhone(value) {
                this.$emit('fatherMethod', value);
            },
            chooseForm(value) {
                if(this.countNum == 0){
                    this.countNum++
                }else{
                    this.$refs.child3.newSetCondition()
                    this.$refs.child1.newSetDataViewId()
                }
                for(let i = 0; i < this.customFormList.length; i++){
                    if(this.customFormList[i]['value'] == value){
                        this.formType = this.customFormList[i]['type']
                    }
                }
                this.formDataValue = value
                this.queryConditionList('query')
               
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            queryConditionList(data) {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customForm/screeningConditionList', 
                        params: {
                            customFormId: data=='query'?this.formDataValue:'',
                            dataViewId: data=='edit'?this.$refs.child1.dataViewId:''
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.conditionList = response.data.data
                        this.$refs.child3.getData(this.showField,this.moduleId,this.conditionList)
                        this.$refs.child1.queryInitData('init')
                        this.$forceUpdate();
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            editDrawer() {
                this.ifEditDrawer = true
                this.changeNumberType = 'edit'
            },
            cancelDrawer() {
                this.ifEditDrawer = false
                this.imgId = ''
                this.currentSelect = ''
                this.ifEditDrawer = false
            },
            saveDrawer() {
                this.drawerVisible = false
                this.ifEditDrawer = false
                this.axios.post('/api/autoCallTask/update', {
                    id: this.dataId,
                    name: this.taskName,
                    description: this.taskDes,
                    owner: this.chargePerson,
                    workTime: {
                        weekList: this.weekList,
                        timeList: this.timeList,
                    },
                    numberList: this.selectedRowKeys1,
                    // content: this.noticeDes,
                    // ttsVoice: this.ttsValue,
                    speed: this.speedValue
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('保存成功')
                        this.taskName = undefined,
                        this.taskDes = undefined,
                        this.chargePerson = undefined,
                        this.queryBasicData()
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            fieldInputBlur(id, value) {
                this.changeFormData(id,value)
            },
            changeDate(date, id) {
                this.changeFormData(id,date.format('YYYY-MM-DD HH:mm:ss'))
            },
            changeSelect1(value, id) {
                this.changeFormData(id,value)
            },
            changeSelect2(value, id) {
                let str = ''
                for(let i = 0; i < value.length; i++){
                    str+=value[i]+','
                }
                const newStr = str.substring(0, str.length - 1);
                this.changeFormData(id,newStr)
            },
            changeFormData(fieldId, value) {
                this.axios.post('/api/customForm/updateFormData', {
                    id: this.digitalDetail.id,
                    type: 2,
                    fieldId: fieldId,
                    value: value
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            tryListen(value) {
                this.showAudioModal = true
                this.musicSrc = value + '?' + this.getTimestamp()
            },
            hideAudio() {
                this.showAudioModal = false
            },
            jumpToUrl(url) {
                window.open(url)
            },
            getTimestamp() {
                return (new Date()).getTime();
            },
            closeAudio() {
                this.musicSrc = ''
            },
            importData() {
                this.normalModal = true
            },
            downloadTemplate() {
                window.open('/api/customForm/download/template/'+this.formDataValue, '_blank');
            },
            customRequest(data) { // 上传提交
                this.newFormData = ''
                const formData = new FormData() 
                formData.append('file', data.file)
                formData.append('customFormId', this.formDataValue)
                this.newFormData = formData
            },
            cancelNormal() {
                this.normalModal = false
            },
            sureNormal() {
                this.normalModal = false
                this.axios({
                    method: 'post',
                    url: '/api/customForm/import/excel',
                    headers:{
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    data: this.newFormData,
                
                }).then((response) => {
                    if(response.data.code == 0){
                        this.newFormData = ''
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            onUpload() {
                this.$refs.pathClear.value = ''
            },
            queryPhoneDetail(value) {
                this.axios(
                    {
                        method:'get',
                        url:"/api/customer/qCellCore", 
                        params: {
                            phone: value,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        if(response.data.data.data.city==''&&response.data.data.data.province==''){
                            this.phoneDetails.city = '未知号码归属地'
                        }else if(response.data.data.data.city==''&&response.data.data.data.province!=''){
                            this.phoneDetails.city = response.data.data.data.province
                        }else{
                            this.phoneDetails.city = response.data.data.data.city
                        }
                        this.phoneDetails.sp = response.data.data.data.sp
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            allocateCurrentConditionData(type) {
                if(type == 'current'){
                    this.conditionDataNum = this.total
                    this.selectedDataType = 2
                }else{
                    this.conditionDataNum = this.selectedRowKeys.length
                    this.selectedDataType = 1
                }
                this.currentConditionDataModal = true
            },
            cancelCurrentConditionData() {
                this.currentConditionDataModal = false
            },
            nextCurrentConditionData() {
                this.currentConditionDataModal = false
                this.batchAllocationModal = true
            },
            cancelBatchAllocation() {
                this.batchAllocationModal = false
            },
            sureBatchAllocation() {
                let owner = ''
                if(this.checkedKeys2.length == 1){
                    owner = this.checkedKeys2[0]
                }else{
                    this.$message.error('请选择需要分配的人')
                    return false
                }
                this.axios.post('/api/customForm/updateOwner', {
                    type: this.selectedDataType,
                    customFormId: this.formDataValue,
                    idList: this.selectedRowKeys,
                    dataViewId: this.$refs.child1.dataViewId,
                    dataRange: this.$refs.child2.selectedDataRangeValue,
                    conditionList: this.$refs.child3.conditionList,
                    owner: owner,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.batchAllocationModal = false
                        this.selectedRowKeys = []
                        this.checkedKeys2 = []
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            onChange2(e) {
                const value = e.target.value;
                const expandedKeys2 = this.dataList2
                    .map(item => {
                        if (item.title.indexOf(value) > -1) {
                            return this.getParentKey2(item.key, this.peopleTree);
                        }
                        return null;
                    })
                    .filter((item, i, self) => item && self.indexOf(item) === i);
                Object.assign(this, {
                    expandedKeys2,
                    searchValue2: value,
                    autoExpandParent2: true,
                });
            },
            onExpand2(expandedKeys) {
                this.expandedKeys2 = expandedKeys;
                this.autoExpandParent2 = false;
            },
            generateList1(data) {
                for (let i = 0; i < data.length; i++) {
                    const node = data[i];
                    const key = node.key;
                    const title = node.title
                    this.dataList1.push({ key, title: title });
                    if (node.children) {
                        this.generateList1(node.children);
                    }
                }
            },
            getParentKey1(key, tree) {
                let parentKey;
                for (let i = 0; i < tree.length; i++) {
                    const node = tree[i];
                    if (node.children) {
                        if (node.children.some(item => item.key === key)) {
                            parentKey = node.key;
                        } else if (this.getParentKey1(key, node.children)) {
                            parentKey = this.getParentKey1(key, node.children);
                        }
                    }
                }
                return parentKey;
            },
            generateList2(data) {
                for (let i = 0; i < data.length; i++) {
                    const node = data[i];
                    const key = node.key;
                    const title = node.title
                    this.dataList2.push({ key, title: title });
                    if (node.children) {
                        this.generateList2(node.children);
                    }
                }
            },
            getParentKey2(key, tree) {
                let parentKey;
                for (let i = 0; i < tree.length; i++) {
                    const node = tree[i];
                    if (node.children) {
                        if (node.children.some(item => item.key === key)) {
                            parentKey = node.key;
                        } else if (this.getParentKey2(key, node.children)) {
                            parentKey = this.getParentKey2(key, node.children);
                        }
                    }
                }
                return parentKey;
            },
            queryDeptAndUserMap() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/user/tree', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.peopleTree = response.data.data
                        this.generateList2(this.peopleTree)
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            }
        },
        watch: {
            checkedKeys2(val) {
                if (val.length > 1) {
                    this.checkedKeys2.shift();
                }
            },
        },
        mounted: function() {
            this.sessionData('set','publicMenu','noticeNumber');
            this.moduleId = this.$route.query.moduleId
            this.queryColumnList()
            this.moduleName = this.$route.query.name
            this.queryDeptAndUserMap()
        }
    }
</script>

<style scoped lang="less">
    .waiting-number{
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            overflow-y: auto;
            overflow-y: auto;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    border-radius: 4px;
                    .modular-in{
                        width: 100%;
                        // height: 100%;
                        background: #fff;
                        border-radius: 4px;
                        overflow-y: auto;
                        padding: 24px;
                        .modular-operation{
                            margin-top: 21px;
                            .operation-group{
                                display: inline-block;
                                float: right;
                                .divider{
                                    margin: 0 16px 0 0;
                                    height: 32px;
                                }
                                .download{
                                    margin-right: 8px;
                                }
                            }
                        }
                        .table-data{
                            margin-top: 24px;
                        }
                        .pagination-table{
                            float: right;
                            padding-top: 24px;
                        }
                    }
                    
                }
            }
            
        }
        
    }
    .drawer-public{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 144px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 160px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
            .take-phone{
                cursor: pointer;
                .take-phone1{
                    width: 14px;
                    height: 14px;
                    margin-left: 3px;
                }
                .take-phone2{
                    display: none;
                    width: 14px;
                    height: 14px;
                    margin-left: 3px;
                }
            }
            .take-phone:hover{
                .take-phone1{
                    display: none;
                }
                .take-phone2{
                    display: inline-block;
                }
            }
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                div:nth-child(1){
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                div:nth-child(2){
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding-right: 16px;
            .basic-info{
                width: 100%;
                // height: 704px;
                background: #fff;
                border-radius: 4px;
                padding: 24px;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        height: 69px;
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            img{
                                width: 21px;
                            }
                            .change-head{
                                color: rgba(24, 144, 255, 1);
                                font-size: 12px;
                                cursor: pointer;
                                margin-left: 24px;
                            }
                        }
                        .selection-range{
                            margin-top: 9px;
                            width: 100%;
                        }
                    }
                }
            }  
            .comments{
                padding: 18px 24px;
                background: #fff;
                margin-top: 16px;
                border-radius: 4px;
                .title{
                    color: rgba(18, 18, 18, 1);;
                    font-size: 14px;
                }
                .textarea-comment{
                    margin-top: 20px;
                    background: rgba(247, 247, 247, 1);
                    border: none;
                    resize: none;
                }
                .release{
                    text-align: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    margin-top: 4px;
                    span{
                        cursor: pointer;
                    }
                }
            }  
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .delete-selected-modal{
        padding-bottom: 46px;
        .des{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        .data-num{
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            margin-top: 8px;
        }
        .reset-changed{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .set-condition-modal{
        padding-bottom: 46px;
        .reset-changed{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .batch-delete-modal{
        padding-bottom: 46px;
        .des{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        .data-num{
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            margin-top: 8px;
        }
        .reset-changed{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .share-modal{
        .copy-link{
            height: 62px;
            input{
                width: 464px;
                float: left;
                height: 40px;
            }
            span{
                float: left;
                display: inline-block;
                width: 88px;
                height: 40px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                cursor: pointer;
                border-radius: 0px 4px 4px 0px;
                line-height: 40px;
                text-align: center;
            }
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .normal-modal{
        padding-bottom: 46px;
        .tab-normal{
            height: 340px;
        }
    }
</style>