import { render, staticRenderFns } from "./WaitingNumber.vue?vue&type=template&id=6f62f3ca&scoped=true&"
import script from "./WaitingNumber.vue?vue&type=script&lang=js&"
export * from "./WaitingNumber.vue?vue&type=script&lang=js&"
import style0 from "./WaitingNumber.vue?vue&type=style&index=0&id=6f62f3ca&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f62f3ca",
  null
  
)

export default component.exports