<template>
    <div class="permission-set">
        <div class="head">
            <span class="title">{{moduleName}}</span>
            <a-button type="primary" @click="editInfo">
                新增
            </a-button>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="content-in">
                <div class="screen-condition">
                    <div class="select-modular">
                        <span class="title">选择模块</span>
                        <a-select default-value="" class="selection-modular"  @change="changeModule">
                            <a-select-option :value="item.value" v-for="(item,index) in moduleList" :key='index'>
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="select-user">
                        <span class="title">选择用户</span>
                        <a-tree-select
                            class="selection-user"
                            v-model="selectedTreeValue"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="userTree"
                            placeholder="Please select"
                            tree-default-expand-all
                        >
                        </a-tree-select>
                    </div>
                </div>
                <div class="extra-content"></div>
                <div class="modular-content">
                    <div class="modular-list" v-for="(item,index) in basicData" :key="index">
                        <a-row>
                            <a-col :span="12">
                                <div class="name">{{item.moduleName}}-{{item.name}}</div>
                            </a-col>
                            <a-col :span="12">
                                <div class="btn-group3">
                                    <a-button @click="copyList(item.id, item.name)">复制</a-button>
                                    <a-button @click="editList(item.id)">编辑</a-button>
                                    <a-button @click="deleteList(item.id)">删除</a-button>
                                </div>
                            </a-col>
                        </a-row>
                        <a-divider class="divider" type="horizontal" />
                        <a-row>
                            <a-col :span="12">
                                <div class="jurisdiction">
                                    <span>权限：</span>
                                    <span>{{item.operations}}</span>
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div class="personnel">
                                    <a-icon type="user" />
                                    <span>{{item.targets}}</span>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="related-info">
                            <a-col :span="9">
          
                            </a-col>
                            <a-col :span="15">
                                <div>
                                    <span>创建人：</span>
                                    <span>{{item.createBy}}</span>
                                    <a-divider type="vertical" />
                                    <span>创建时间：</span>
                                    <span>{{item.createTime}}</span>
                                    <a-divider type="vertical" />
                                    <span>修改人：</span>
                                    <span>{{item.updateBy}}</span>
                                    <a-divider type="vertical" />
                                    <span>修改时间：</span>
                                    <span>{{item.updateTime}}</span>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
            </div>
        </div>
        <a-drawer
            :title='drawerName'
            placement="right"
            :closable="true"
            :visible="visible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '100'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '72px 0 0' }"
        >
            <div class="drawer-set">
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="item-list">
                            <div class="title">输入权限名称：</div>
                            <a-input v-model="permissionName" placeholder="请输入权限名称" />
                        </div>
                        <div class="item-list">
                            <div class="title">影响到的人：</div>
                            <div class="affect-list">
                                <div style="display:inline-block;width:680px;height:32px;border:1px solid #d9d9d9;border-radius:4px;overflow:hidden;line-height:32px;padding-left:12px">
                                    <span v-for="(item,index) in child1Data.departmentSelected" :key="index" style="margin-right:6px">{{deptAndUserMap[item+'']}}</span>
                                    <span v-for="(item,index) in child1Data.personSelected" :key="index" style="margin-right:6px">{{deptAndUserMap[item+'']}}</span>
                                </div>
                                <a-icon @click="chooseAffected" class="affect-icon" type="zoom-in" />
                            </div>
                        </div>
                        <div class="item-list">
                            <div class="title">操作权限：</div>
                            <div class="affect-checkbox">
                                <a-checkbox-group :options="operationList" v-model="valueList" />
                            </div>
                        </div>
                        <div class="item-list">
                            <div class="title">字段权限：</div>
                            <div>
                                <div>
                                    <a-row class="word-head">
                                        <a-col :span="8">
                                            字段权限
                                        </a-col>
                                        <a-col :span="8">
                                            查
                                        </a-col>
                                        <a-col :span="8">
                                            改
                                        </a-col>
                                    </a-row>
                                    <div class="word-body">
                                        <a-checkbox-group style="width:100%" @change="onChangeField" v-model="checkedFieldList">
                                            <a-row v-for="(item,index) in wordPermissionList" :key='index'>
                                                <a-col :span="8"  class="field1">
                                                    <div>{{item.fieldName}}</div>
                                                </a-col>
                                                <a-col :span="8"  class="field2">
                                                    <a-checkbox class="checkbox-list" :disabled='item.options[0].disabled' :value='item.options[0].value+"@@"+item.fieldId'>
                                                        开启
                                                    </a-checkbox>
                                                </a-col>
                                                <a-col :span="8"  class="field2">
                                                    <a-checkbox class="checkbox-list" :disabled='item.options[1].disabled' :value='item.options[1].value+"@@"+item.fieldId'>
                                                        开启
                                                    </a-checkbox>
                                                </a-col>
                                            </a-row>
                                        </a-checkbox-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button type="primary" ghost @click="cancelDrawer">取消</a-button>
                        <a-button type="primary" @click="sureDrawer">提交</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="newJurisdictionModal" title="新建权限-选择模块" footer='' width='480px'>
            <div class="jurisdiction-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="jurisdiction-select">选择要配置权限的模块：</div>
                <a-select default-value="" style="width:265px;height:32px"  @change="newPerModule">
                    <a-select-option :value="item.value" v-for="(item,index) in moduleList" :key='index'>
                        {{item.name}}
                    </a-select-option>
                </a-select>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelNewJurisdiction">取消</a-button>
                    <a-button type="primary" @click="nextJurisdiction">下一步</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="changeNameModal" title="复制权限" footer='' width='480px'>
            <div class="edit-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="changed-name">输入复制后新的权限名称：</div>
                <a-input v-model="currentName" />
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelNewName">取消</a-button>
                    <a-button type="primary" @click="sureNewName">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="offBindWxModal" footer='' width='480px'>
            <div class="offbindwx-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个权限吗？</span>
                </div>
                <div class="tip">删除后，数据将无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDelete">取消</a-button>
                    <a-button type="primary" @click="sureDelete">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="offBindAffected" footer='' title="影响到的人" width='720px'>
            <div class="offbindaffect-modal modal-public">
                <Department ref="child1"></Department>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelBindAffected">取消</a-button>
                    <a-button type="primary" @click="sureBindAffected">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import Department from '../../components/Department';
    export default {
        name: 'PermissionSet',
        components: { Department },
        data() {
            return {
                newJurisdictionModal: false,
                changeNameModal: false,
                offBindWxModal: false,
                visible: false,
                drawerName: '',
                offBindAffected: false,
                expandedKeys: [],
                autoExpandParent1: true,
                autoExpandParent2: true,
                expandedKeys2: [],
                basicData: [],
                currentName: '',
                currentId: '',
                moduleList: [],
                userTree: [],
                selectedModuleValue: '',
                selectedTreeValue: '',
                needChangedModuleValue: '',
                operationList: [],
                valueList: [],
                wordPermissionList: [],
                fieleList:[],
                currentOperation: '',
                permissionName: '',
                checkedFieldList: [],
                moduleId: '',
                deptAndUserMap: '',
                passDataObjOwner: {
                    departmentTree: null,
                    peopleTree: null,
                    departmentSelected: null,
                    personSelected: null
                },
                child1Data: {
                    departmentSelected: [],
                    personSelected: [],
                },
                moduleName: ''
            }
        },
        watch: {
            selectedTreeValue(value) {
                this.selectedTreeValue = value
                this.queryBasicData()
            },
        },
        methods: {
            onChangeField(checkedValues) {
                this.checkedFieldList = checkedValues
                this.fieleList = []
                for(let i = 0; i < checkedValues.length; i++){
                    let obj = {}
                    let id = checkedValues[i].split("@@")[1]
                    let value = checkedValues[i].split("@@")[0]
                    let flag = 0
                    for(let j = 0; j < this.fieleList.length; j++){
                        if(this.fieleList[j].fieldId == id){
                            this.fieleList[j].valueList.push(value)
                            flag = 1
                        }
                    }
                    if(flag == 0){
                        obj.fieldId = id
                        obj.valueList = [value]
                        this.fieleList.push(obj)
                    }
                }
            },
            queryBasicData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/authority/list', 
                        params: {
                            moduleId: this.selectedModuleValue,
                            influenceUser: this.selectedTreeValue
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.basicData = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryCondition() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/authority/listPage/init', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.moduleList = response.data.data.moduleList
                        this.userTree = response.data.data.userTree
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            changeModule(value) {
                this.selectedModuleValue = value
                this.queryBasicData()
            },
            newPerModule(value) {
                this.needChangedModuleValue = value
            },
            editInfo() {
                this.initDrawer()
                this.newJurisdictionModal = true
                this.currentOperation = 'new'
            },
            cancelNewJurisdiction() {
                this.newJurisdictionModal = false
            },
            nextJurisdiction() {
                if(this.needChangedModuleValue == ''){
                    this.$message.error('新增权限时，不能选择所有模块');
                }else{
                    this.newJurisdictionModal = false
                    this.visible = true;
                    this.axios(
                        {
                            method:'get',
                            url:'/api/authority/init', 
                            params: {
                                moduleId: this.needChangedModuleValue,
                            }
                        }
                    )
                    .then(response=> {
                        if(response.data.code == 0){
                            this.drawerName = response.data.data.moduleName+'-新增'
                            this.passDataObjOwner.departmentTree = response.data.data.target.deptTree
                            this.passDataObjOwner.peopleTree = response.data.data.target.userTree
                            this.operationList = response.data.data.operation.operationList
                            this.valueList = response.data.data.operation.valueList
                            this.wordPermissionList = response.data.data.field
                            let field = response.data.data.field
                            this.checkedFieldList = []
                            this.fieleList = []
                            for(let i = 0; i < field.length; i++){
                                if(field[i].valueList.length > 0){
                                    let obj = {}, arr = []
                                    for(let j = 0; j < field[i]['valueList'].length; j++){
                                        this.checkedFieldList.push(field[i]['valueList'][j]+"@@"+field[i]['fieldId'])
                                        arr.push(field[i].valueList[j])
                                    }
                                    obj.fieldId = field[i]['fieldId']
                                    obj.valueList = arr
                                    this.fieleList.push(obj)
                                }
                            }
                        }else{
                            this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                        }
                    })
                    .catch(error=> {
                        console.log(error)
                    })
                }
                
            },
            onClose() {
                this.visible = false;
            },
            copyList(id, name) {
                this.changeNameModal = true
                this.currentName = name
                this.currentId = id
            },
            editList(id) {
                this.visible = true;
                this.drawerName = '模块名称-编辑权限设置'
                this.currentOperation = 'edit'
                this.currentId = id
                this.axios(
                    {
                        method:'get',
                        url:'/api/authority/init', 
                        params: {
                            id: id,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.drawerName = response.data.data.moduleName+'-编辑权限设置'
                        this.operationList = response.data.data.operation.operationList
                        this.valueList = response.data.data.operation.valueList
                        this.wordPermissionList = response.data.data.field
                        this.permissionName = response.data.data.name
                        this.passDataObjOwner.departmentTree = response.data.data.target.deptTree
                        this.passDataObjOwner.peopleTree = response.data.data.target.userTree
                        this.passDataObjOwner.departmentSelected = response.data.data.target.deptValueList
                        this.passDataObjOwner.personSelected = response.data.data.target.userValueList
                        this.child1Data.departmentSelected = response.data.data.target.deptValueList
                        this.child1Data.personSelected = response.data.data.target.userValueList
                        this.checkedFieldList = []
                        let field = response.data.data.field
                        this.fieleList = []
                        for(let i = 0; i < field.length; i++){
                            if(field[i].valueList.length > 0){
                                let obj = {}, arr = []
                                for(let j = 0; j < field[i]['valueList'].length; j++){
                                    this.checkedFieldList.push(field[i]['valueList'][j]+"@@"+field[i]['fieldId'])
                                    arr.push(field[i].valueList[j])
                                }
                                obj.fieldId = field[i]['fieldId']
                                obj.valueList = arr
                                this.fieleList.push(obj)
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            deleteList(id) {
                this.offBindWxModal = true
                this.currentId = id
            },
            cancelNewName() {
                this.changeNameModal = false
            },
            sureNewName() {
                this.changeNameModal = false
                this.axios.post('/api/authority/copy', {
                    id: this.currentId,
                    name: this.currentName
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('复制成功')
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDelete() {
                this.offBindWxModal = false
            },
            sureDelete() {
                this.offBindWxModal = false
                this.axios.delete('/api/authority/'+this.currentId)
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDrawer() {
                this.visible = false
            },
            initDrawer() {
                this.currentId = ''
                this.needChangedModuleValue = ''
                this.permissionName = ''
                this.child1Data.departmentSelected = []
                this.child1Data.personSelected = []
                this.valueList = []
                this.fieleList = []
                this.passDataObjOwner.departmentSelected = null
                this.passDataObjOwner.personSelected = null
            },
            sureDrawer() {
                let tip = ''
                if(this.currentOperation == 'new'){
                    this.currentId = ''
                    tip = '新增成功！'
                }else{
                    this.needChangedModuleValue = ''
                    tip = '修改成功！'
                }
                this.visible = false
                this.axios.post('/api/authority', {
                    id: this.currentId,
                    moduleId: this.needChangedModuleValue,
                    name: this.permissionName,
                    target: {
                        deptValueList: this.child1Data.departmentSelected,
                        userValueList: this.child1Data.personSelected
                    },
                    operationList: this.valueList,
                    fieldList: this.fieleList
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success(tip)
                        this.queryBasicData()
                        this.initDrawer()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            checkboxChange() {

            },
            onChangeAll1() {

            },
            onChangeAll2() {

            },
            onChangeList1() {

            },
            onChangeList2() {

            },
            chooseAffected() {
                this.offBindAffected = true
                setTimeout(()=>{
                    this.$refs.child1.getParentData(this.passDataObjOwner)
                })
            },
            cancelBindAffected() {
                this.offBindAffected = false
            },
            sureBindAffected() {
                this.child1Data.departmentSelected = this.$refs.child1.departmentSelected
                this.child1Data.personSelected = this.$refs.child1.personSelected
                this.offBindAffected = false
            },
            onExpand(expandedKeys) {
                this.expandedKeys = expandedKeys;
                this.autoExpandParent1 = false;
            },
            onExpand2(expandedKeys) {
                this.expandedKeys2 = expandedKeys;
                this.autoExpandParent2 = false;
            },
            queryDeptAndUserMap() {
                 this.axios(
                    {
                        method:'get',
                        url:'/api/user/deptAndUserMap', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.deptAndUserMap = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            }
        },
        mounted: function() {
            this.sessionData('set','personalCenter','permissionSet');
            this.moduleId = this.$route.query.moduleId
            this.moduleName = this.$route.query.name
            this.queryBasicData()
            this.queryCondition()
            this.queryDeptAndUserMap()
        }
    }
</script>

<style scoped lang="less">
    .permission-set{
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                width: 64px;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            overflow-y: auto;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .screen-condition{
                    z-index: 100;
                    height: 80px;
                    background: #fff;
                    padding: 24px 0 0 24px;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    border-radius: 4px;
                    overflow: hidden;
                    .select-modular{
                        display: inline-block;
                        .title{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                        }
                        .selection-modular{
                            margin-left: 24px;
                            width: 265px;
                            height: 32px;
                        }
                    }
                    .select-user{
                        display: inline-block;
                        margin-left: 48px;
                        .title{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                        }
                        .selection-user{
                            margin-left: 24px;
                            width: 265px;
                            height: 32px;
                        }
                    }
                }
                .extra-content{
                    height: 16px;
                    width: 100%;
                    background: #f0f2f3;
                    position: absolute;
                    left: 0px;
                    top: 80px;
                    z-index: 100;
                }
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    padding-top: 96px;
                    height: 100%;
                    width: 100%;
                    overflow-y: auto;
                    .modular-list{
                        width: 100%;
                        height: 160px;
                        padding: 24px;
                        background: #fff;
                        border-radius: 4px;
                        margin-bottom: 16px;
                        .name{
                            color: rgba(18, 18, 18, 1);
                            font-size: 16px;
                        }
                        .btn-group3{
                            float: right;
                            button{
                                margin-left: 8px;
                            }
                        }
                        .divider{
                            margin: 10px 0 16px;
                        }
                        .jurisdiction{
                            color: rgba(80, 80, 80, 1);;
                            font-size: 14px;
                        }
                        .personnel{
                            i{
                                color: rgba(121, 136, 155, 1);
                                font-size: 14px;
                            }
                            span{
                                color: rgba(80, 80, 80, 1);;
                                font-size: 12px;
                                margin-left: 8px;
                            }
                        }
                        .related-info{
                            margin-top: 26px;
                            div{
                                text-align: right;
                                color: rgba(188, 188, 188, 1);
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            
        }
        
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .jurisdiction-modal{
        padding-bottom: 46px;
        .jurisdiction-select{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            margin-bottom: 8px;
        }
        .selection-jurisdiction{
            width: 430px;
            height: 32px;
        }
    }
    .edit-modal{
        padding-bottom: 46px;
        .changed-name{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        input{
            margin-top: 8px;
        }
    }
    .offbindwx-modal{
        padding-top: 24px;
        padding-bottom: 46px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .offbindaffect-modal{
        border-top: 1px solid #e8e8e8;
        padding-top: 24px;
        padding-bottom: 46px;
        .affect-modal-left{
            width: 100%;
            height: 100%;
            border-right: 1px solid #e8e8e8;
            padding-right: 24px;
            .department-tree,.person-tree{
                height: 300px;
                overflow-x: hidden;
                overflow-y: auto;
                padding-top: 16px;
            }
        }
        .affect-modal-right{
            width: 100%;
            height: 100%;
            padding-left: 24px;
            .title{
                margin-bottom: 24px;
                span:nth-child(1){
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                }
                span:nth-child(2){
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    cursor: pointer;
                    float: right;
                }
            }
            .selected-list{
                height: 346px;
                overflow-y: auto;
                .selected-item{
                    display: inline-block;
                    height: 32px;
                    padding: 0 6px;
                    background: rgba(121, 136, 155, 0.1);
                    border-radius: 4px;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    line-height: 32px;
                    img:nth-child(1){
                        color: rgba(121, 136, 155, 1);
                        width: 18px;
                        float: left;
                        margin-top: 7px;
                    }
                    span:nth-child(2){
                        color: rgba(80, 80, 80, 1);
                        font-size: 14px;
                        margin-left: 4px;
                    }
                    i:nth-child(3){
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
    .drawer-set{
        height: 100%;
        display: flex;
        position: relative;
        .drawer-content{
            padding: 0 16px 68px;
            width: 100%;
            .drawer-body-content{
                width: 100%;
                height: 100%;
                padding: 24px;
                background: #fff;
                border-radius: 4px;
                .item-list{
                    margin-bottom: 24px;
                    .title{
                        color: rgba(188, 188, 188, 1);
                        font-size: 12px;
                        margin-bottom: 11px;
                    }
                    .affect-list{
                        .affect-input{
                            width: 680px;
                        }
                        .affect-icon{
                            color: rgba(121, 136, 155, 1);
                            font-size: 20px;
                            float: right;
                            margin-top: 6px;
                            cursor: pointer;
                        }
                    }
                    .affect-checkbox{

                    }
                    .word-head{
                        height: 50px;
                        background: rgba(232, 235, 238, 1);
                        line-height: 50px;
                        text-align: center;
                    }
                    .word-body{
                        height: 430px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        .field1{
                            div{
                                height: 40px;
                                width: 100%;
                                text-align: center;
                                line-height: 40px;
                                border-left: 1px solid rgba(232, 235, 238, 1);
                                border-bottom: 1px solid rgba(232, 235, 238, 1);
                            }
                        }
                        .field2{
                            .checkbox-list{
                                display: block;
                                height: 40px;
                                width: 100%;
                                text-align: center;
                                line-height: 40px;
                                border-left: 1px solid rgba(232, 235, 238, 1);
                                border-bottom: 1px solid rgba(232, 235, 238, 1);
                                margin-left: 0px !important;
                            }
                        }
                    }
                }
            }
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
</style>