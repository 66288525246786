<template>
    <div class="screen-column">
        <div style="display:flex;margin-top:24px;cursor: pointer;" @click="showModal">
            <img style="width:20px;" src="./../assets/img/fast-operate.svg" alt="">
            <span style="color:rgba(18, 18, 18, 1);font-size:14px;flex:1;margin-left:12px;">分享任务</span>
            <a-icon style="color:rgba(51, 51, 51, 1);font-size:14px;margin-top:4px;" type="right" />
        </div>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="screenModal" title="外链分享" footer='' width='720px'>
            <div class="share-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="copy-link">
                    <a-input id="copyHtml" readonly="readonly" :placeholder="linkUrl" />
                    <span @click="copyLink(linkUrl)">复制链接</span>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {
        moduleId: {
            type: String,
            required: true
        },
        dataId: {
            type: String,
            required: true
        },
        original: {
            type: String,
            required: true
        }
    },
    data(){
        return{
            screenModal: false,
            linkUrl: ''
        }
    },
    methods: {
        showModal() {
            this.screenModal = true
            this.getUrl()
        },
        copyLink(url) {
            this.screenModal = false
            let text = url
            let input = document.getElementById("copyHtml");
            input.value = text; 
            input.select(); 
            document.execCommand("copy"); 
            this.$message.success('已复制！')
        },
        getUrl() {
            if(this.original == ''){
                this.linkUrl = window.location.href
            }else{
                let shareId = ''
                this.axios.post('/api/externalLinks',{
                    moduleId: this.moduleId,
                    dataId: this.dataId
                })
                .then(response=> {
                    if(response.data.code == 0){
                        shareId = response.data.data.id
                        let url1 = window.location.origin
                        let url = url1+'/#/'+this.original+'?id='+shareId+'&moduleId='+this.moduleId
                        this.linkUrl = url
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            }
        },
    }
}
</script>
<style scoped lang="less">
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .divider3{
            margin: 0px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .share-modal{
        .copy-link{
            height: 62px;
            input{
                width: 464px;
                float: left;
                height: 40px;
            }
            span{
                float: left;
                display: inline-block;
                width: 88px;
                height: 40px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                cursor: pointer;
                border-radius: 0px 4px 4px 0px;
                line-height: 40px;
                text-align: center;
            }
        }
    }
</style>