<template>
    <div class="call-detail">
        <div class="head">
            <span class="title">{{digitalDetail.moduleName+"-"+digitalDetail.dataTitle}}</span>
            <div style="float:right;">
                <!-- <div v-if="operationAuthority.START_OR_PAUSE" style="display:inline-block;float:left;margin-top:5px">
                    <a-icon v-if="callType==2" @click="callOperate('1')" type="play-circle" style="margin:0 8px" />
                    <a-icon v-if="callType==1" @click="callOperate('2')" style="color:rgba(24, 144, 255, 1);margin:0 8px" type="pause-circle" />
                    <span v-if="callType==1">呼叫中</span>
                    <span v-if="callType==2">已暂停</span>
                </div>
                <a-icon style="margin:0 0px 0 24px;float:left;margin-top:20px" type="exclamation-circle" /> -->
                <!-- <a-dropdown v-if="operationAuthority.ADD_PHONE&&isCustomForm!=1" :trigger="['click']">
                    <a-menu slot="overlay" @click="downloadOperate">
                        <a-menu-item key="1" class="more-operate-item more-operate-item1">
                            <div>普通导入</div>
                        </a-menu-item>
                        <a-menu-item key="2" class="more-operate-item">
                            <div>从别的模块导入</div>
                        </a-menu-item>
                    </a-menu>
                    <a-button type="primary"> 加入号码 </a-button>
                </a-dropdown>
                <a-button @click="addNumber" type="primary" v-if="isCustomForm==1"> 加入号码 </a-button> -->
                <!-- <OutLink v-if="operationAuthority.EXTERNAL_LINKS" :moduleId='moduleId' :dataId='dataId' :original='"OutcallShare"'></OutLink> -->
            </div>
        </div>
        <div class="content">
            <div class="content-in">
                <div class="modular-content">
                    <div class="left-content">
                        <div style="height: 144px;background:#fff;border-radius: 4px;padding:18px 24px;overflow-y: auto;">
                            <div class="related-module">
                                <span></span>
                                相关模块
                            </div>
                            <ul class="module-list">
                                <li :class="{'active':currentSelectModule==index}" @click="viewSenceDetail(index,item)" v-for="(item,index) in moduleNumList" :key='index'>
                                    <a-icon type="profile" />
                                    <div>{{item.name}}</div>
                                    <span :title="item.num">{{item.num}}</span>
                                </li>
                            </ul>
                        </div>
                        <div style="flex: 1;margin-top:16px;background:#fff;border-radius: 4px;padding:18px 0 18px 24px;display: flex;flex-direction: column;overflow-y: auto;">
                            <div class="related-module" style="height: 45px;">
                                <span></span>
                                今日任务统计
                            </div>
                            <div style="flex: 1;overflow-y: auto">
                                <div class="statistics-item">
                                    <div class="statistics-title">外呼量：</div>
                                    <div class="statistics-value">{{ statisticData.totalCallCount }}</div>
                                </div>
                                <div class="statistics-item">
                                    <div class="statistics-title">客户接通量：</div>
                                    <div class="statistics-value">{{ statisticData.successCallCount }}</div>
                                </div>
                                <div class="statistics-item">
                                    <div class="statistics-title">任务接通率：</div>
                                    <div class="statistics-value">{{ statisticData.successCallPer }}</div>
                                </div>
                                <div class="statistics-item">
                                    <div class="statistics-title">坐席总呼损率：</div>
                                    <div class="statistics-value">{{ statisticData.fumblePer }}</div>
                                </div>
                                <div class="statistics-item">
                                    <div class="statistics-title">坐席不足呼损率：</div>
                                    <div class="statistics-value">{{ statisticData.lackPer }}</div>
                                </div>
                                <div class="statistics-item">
                                    <div class="statistics-title">签入坐席：</div>
                                    <div class="statistics-value">{{ statisticData.signCount }}</div>
                                </div>
                                <div class="statistics-item">
                                    <div class="statistics-title">签出坐席：</div>
                                    <div class="statistics-value">{{ statisticData.signOutCount }}</div>
                                </div>
                                <div class="statistics-item">
                                    <div class="statistics-title">忙碌坐席：</div>
                                    <div class="statistics-value">{{ statisticData.busyCount }}</div>
                                </div>
                                <div class="statistics-item">
                                    <div class="statistics-title">空闲中坐席：</div>
                                    <div class="statistics-value">{{ statisticData.waitingCount }}</div>
                                </div>
                                <div class="statistics-item">
                                    <div class="statistics-title">振铃中坐席：</div>
                                    <div class="statistics-value">{{ statisticData.receivingCount }}</div>
                                </div>
                                <div class="statistics-item">
                                    <div class="statistics-title">通话中坐席：</div>
                                    <div class="statistics-value">{{ statisticData.callingCount }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="center-content">
                        <div class="basic-data">
                            <a-row>
                                <a-col :span="12" style="padding-right:8px">
                                    <a-row>
                                        <a-col style="padding-right:8px" :span="12">
                                            <div class="data-content">
                                                <div>待呼号码</div>
                                                <div>{{taskNumData.waitingCall}}</div>
                                            </div>
                                        </a-col>
                                        <a-col style="padding-left:8px" :span="12">
                                            <div class="data-content">
                                                <div>呼叫中号码</div>
                                                <div>{{taskNumData.calling}}</div>
                                            </div>
                                        </a-col>
                                    </a-row>
                                </a-col>
                                <a-col :span="12" style="padding-left:8px">
                                    <a-row>
                                        <a-col style="padding-right:8px" :span="12">
                                            <div class="data-content">
                                                <div>已呼号码</div>
                                                <div>{{taskNumData.called}}</div>
                                            </div>
                                        </a-col>
                                        <a-col style="padding-left:8px" :span="12">
                                            <div class="data-content">
                                                <div>呼通号码</div>
                                                <div>{{taskNumData.callSuccess}}</div>
                                            </div>
                                        </a-col>
                                    </a-row>
                                </a-col>
                            </a-row>
                        </div>
                        <div style="flex: 1;overflow-y: auto;">
                            <div class="basic-info">
                                <div class="title">基本信息</div>
                                <a-row class="row-list">
                                    <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                        <div class="first-content">{{item.name}}:</div>
                                        <div v-if="!ifEditDrawer&&item.code!='workTime'&&item.code!='recording_file_id'" class="second-content">{{item.value}}</div>
                                        <div v-if="item.code=='workTime'" class="second-content">
                                            <span>{{item.value.timeList.length>0?'已设定':'未设定'}}</span>
                                            <span @click="changeTime(item, 1)" v-if="ifEditDrawer" style="color:rgba(24, 144, 255, 1);font-size:14px;margin-left:24px;cursor:pointer">修改</span>
                                        </div>
                                        <div v-if="ifEditDrawer&&item.isEditable!=1&&item.code!='recording_file_id'" class="second-content">{{item.value}}</div>
                                        <div v-if="ifEditDrawer&&item.code=='name'&&item.isEditable==1">
                                            <a-input v-model="item.value" @change="changeTaskName" class="second-content" placeholder="任务名称" />
                                        </div>
                                        <div v-if="ifEditDrawer&&item.code=='description'&&item.isEditable==1">
                                            <a-textarea v-model="item.value" @change="changeDes" class="second-content" style="height:40px;resize:none" placeholder="请输入任务描述" :rows="2"/>
                                        </div>
                                        <div v-if="ifEditDrawer&&item.code=='content'&&item.isEditable==1">
                                            <a-textarea v-model="item.value" @change="changeNotice" class="second-content" placeholder="请输入通知内容" style="height:40px;resize:none"/>
                                        </div>
                                        <div class="second-content" v-if="item.type=='USER'&&ifEditDrawer==true&&item.isEditable==1">
                                            <a-tree-select
                                                class="selection-range"
                                                v-model="item.valueList"
                                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                                :tree-data="item.userTree"
                                                :placeholder="item.value"
                                                tree-default-expand-all
                                                @change="changePerson"
                                            >
                                            </a-tree-select>
                                        </div>
                                        <div class="second-content" v-if="item.code=='tts_voice'&&ifEditDrawer==true&&item.isEditable==1">
                                            <a-select v-model="item.selectedValue" @change="ttsChange" class="selection-range">
                                                <a-select-option v-for="(item1,index1) in item.selectList" :key="index1" :value="item1.value">
                                                    {{item1.name}}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                        <!-- <div v-if="ifEditDrawer&&item.isEditable!=1&&item.code!='recording_file_id'&&item.code!='speed'" class="second-content">
                                            {{item.value}}
                                        </div> -->
                                        <div v-if="ifEditDrawer&&item.code=='speed'&&item.isEditable==1" class="second-content">
                                            <span>在线坐席 * </span>
                                            <a-input-number v-model="speedValue" :min="0" :step="1"/>
                                        </div>
                                        <div v-if="ifEditDrawer&&item.code=='callingNumer'&&item.isEditable==1">
                                            <div class="second-content">
                                                <span style="color:rgba(80, 80, 80, 1);">已选择</span>
                                                <span style="color:rgba(24, 144, 255, 1)"> {{selectedRowKeys1.length}} </span>
                                                <span style="color:rgba(80, 80, 80, 1)">个</span>
                                                <a-icon @click="selectNumberFun" style="margin-left:36px;color:rgba(121, 136, 155, 0.5);cursor:pointer" type="zoom-in" />
                                            </div>
                                        </div>
                                        <div style="height:21px;overflow-y:hidden" :title="item.value" v-if="item.code=='recording_file_id'" class="second-content">
                                            <div style="display: inline-block;width:200px;overflow: hidden;color:rgba(24, 144, 255, 1)">
                                                <div style="overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">{{item.value}}</div>
                                            </div>
                                            <div style="display: inline-block;">
                                                <a-icon @click="tryListen3(item.recordingFileUrl)" style="font-size: 14px;color:rgba(121, 136, 155, 0.5);margin:0px 0 0 8px;cursor: pointer;float: left;" type="play-circle" />
                                            </div>
                                        </div>
                                        <div class="second-content" v-if="item.code=='wiring_group_id'&&ifEditDrawer==true&&item.isEditable==1">
                                        <a-select v-model="item.selectedValue" @change="changeLineGroup" style="width:100%">
                                            <a-select-option v-for="(item1,index1) in item.selectList" :key="index1" :value="item1.value">
                                                {{item1.name}}
                                            </a-select-option>
                                        </a-select>
                                    </div>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="monitor-content">
                                <div style="margin-bottom:24px;">
                                    <span style="color:rgba(18, 18, 18, 1);font-size:14px;">坐席状态监控</span>
                                    <div style="float: right">
                                        <a-switch style="margin-right:16px;width: 44px;;" @change="changeMonitorState" />
                                        <span style="color:rgba(121, 136, 155, 1);font-size:14px;float: right;margin-top:2px;">全部坐席</span>
                                    </div>
                                </div>
                                <div style="display: inline-block" v-for="(item,index) in wiringGroupList" :key="index">
                                    <div class="monitor-box" v-if="item.signStatus!=ifSign" :class="getClassByType(item.status, item.signStatus)">
                                        <div>
                                            <span style="color:#000;font-weight: bold;font-size: 16px;">{{item.name}}</span>
                                            <span style="color:#000;font-weight: bold;font-size: 16px;margin-left: 24px;">{{item.extensionCode}}</span>
                                            <span v-if="item.signStatus==1" style="color:rgba(24, 144, 255, 1);border:1px solid rgba(24, 144, 255, 0.5);background:rgba(24, 144, 255, 0.05);float: right;font-size:14px;border-radius: 4px;display: inline-block;width:48px;height:24px;text-align: center;line-height: 22px;">签入</span>
                                            <span v-if="item.signStatus==2" style="color:rgba(121, 136, 155, 1);border:1px solid rgba(121, 136, 155, 0.5);background:rgba(121, 136, 155, 0.1);float: right;font-size:14px;border-radius: 4px;display: inline-block;width:48px;height:24px;text-align: center;line-height: 22px;">签出</span>
                                            <span v-if="item.signStatus==3" style="color:rgba(212, 48, 48, 1);border:1px solid rgba(212, 48, 48, 0.5);background:rgba(212, 48, 48, 0.05);float: right;font-size:14px;border-radius: 4px;display: inline-block;width:48px;height:24px;text-align: center;line-height: 22px;">忙碌</span>
                                        </div>
                                        <div style="margin-top:8px;">
                                            <span v-if="item.status==1" style="color:rgba(24, 144, 255, 1);font-size:14px;">空闲</span>
                                            <span v-if="item.status==2" style="color:rgba(255, 141, 26, 1);font-size:14px;">振铃中</span>
                                            <span v-if="item.status==3" style="color:rgba(10, 191, 91, 1);font-size:14px;">通话中</span>
   
                                            <a-dropdown style="float:right;margin:-10px 4px 0 0;">
                                                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                                    <span style="font-weight:bold;font-size:20px;color:rgba(153, 153, 153, 1)">...</span>
                                                </a>
                                                <a-menu style="width:100px;text-align:center" slot="overlay" @click="value => changeStatus(item.userId, value)">
                                                    <a-menu-item key="1" :value="1">
                                                        签入
                                                    </a-menu-item>
                                                    <a-menu-item key="2" :value="2">
                                                        签出
                                                    </a-menu-item>
                                                    <a-menu-item key="3" :value="3" disabled>
                                                        忙碌
                                                    </a-menu-item>
                                                </a-menu>
                                            </a-dropdown>
                                            <span v-if="item.signStatus==1&&item.status==1" style="color:rgba(121, 136, 155, 0.7);font-size:13px;float: right;margin-right:40px;">{{item.waitingTime}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="ifShowNoData" style="padding:55px 0 70px">
                                    <div style="text-align:center">
                                        <img style="width:64px;" src="../../assets/img/no-seat.png" alt="">
                                    </div>
                                    <div style="color:rgba(179, 184, 193, 1);font-size:12px;text-align:center;">暂无数据</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right-content">
                        <div class="data-info">
                            <div class="related-info">
                                <span></span>
                                数据信息
                            </div>
                            <ul>
                                <li>
                                    <div>创建时间：</div>
                                    <div>{{digitalDetail.createTime}}</div>
                                </li>
                                <li>
                                    <div>修改时间：</div>
                                    <div>{{digitalDetail.updateTime}}</div>
                                </li>
                                <li>
                                    <div>创建人：</div>
                                    <div>{{digitalDetail.createBy}}</div>
                                </li>
                            </ul>
                        </div>
                        <div class="fast-operate">
                            <div class="fast-do">
                                <span></span>
                                快速操作
                            </div>
                            <div v-if="operationAuthority.START_OR_PAUSE" style="display:inline-block;margin-top:24px">
                                <a-icon v-if="callType==2" @click="callOperate('1')" type="play-circle" style="margin-right: 8px" />
                                <a-icon v-if="callType==1" @click="callOperate('2')" style="color:rgba(24, 144, 255, 1);margin-right: 8px" type="pause-circle" />
                                <span v-if="callType==1">呼叫中</span>
                                <span v-if="callType==2">已暂停</span>
                            </div>
                            <div style="height:1px;background:rgba(229, 229, 229, 1);margin-top:24px;"></div>
                            <div style="display:flex;margin-top:24px;cursor: pointer;" @click="fastAddNumber" v-if="operationAuthority.ADD_PHONE&&isCustomForm!=1">
                                <img style="width:20px;" src="../../assets/img/fast-operate.svg" alt="">
                                <span style="color:rgba(18, 18, 18, 1);font-size:14px;flex:1;margin-left:12px;">快速添加号码</span>
                                <a-icon style="color:rgba(51, 51, 51, 1);font-size:14px;margin-top:4px;" type="right" />
                            </div>
                            <div v-if="operationAuthority.ADD_PHONE" style="display:flex;margin-top:24px;cursor: pointer;" @click="fixedTemplateImportNum" >
                                <img style="width:20px;" src="../../assets/img/fast-operate.svg" alt="">
                                <span style="color:rgba(18, 18, 18, 1);font-size:14px;flex:1;margin-left:12px;">固定模板导入号码</span>
                                <a-icon style="color:rgba(51, 51, 51, 1);font-size:14px;margin-top:4px;" type="right" />
                            </div>
                            <div style="display:flex;margin-top:24px;cursor: pointer;" @click="otherModulesImport" v-if="operationAuthority.ADD_PHONE&&isCustomForm!=1">
                                <img style="width:20px;" src="../../assets/img/fast-operate.svg" alt="">
                                <span style="color:rgba(18, 18, 18, 1);font-size:14px;flex:1;margin-left:12px;">表单模板导入号码</span>
                                <a-icon style="color:rgba(51, 51, 51, 1);font-size:14px;margin-top:4px;" type="right" />
                            </div>
                            <div style="display:flex;margin-top:24px;cursor: pointer;" @click="setCallTime">
                                <img style="width:20px;" src="../../assets/img/fast-operate.svg" alt="">
                                <span style="color:rgba(18, 18, 18, 1);font-size:14px;flex:1;margin-left:12px;">设定呼叫时间</span>
                                <a-icon style="color:rgba(51, 51, 51, 1);font-size:14px;margin-top:4px;" type="right" />
                            </div>
                            <OutLinkNew v-if="operationAuthority.EXTERNAL_LINKS" :moduleId='moduleId' :dataId='dataId' :original='"OutcallShare"'></OutLinkNew>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-edit" v-if="editPermissionValue==1">
            <!-- <a-button v-if="!ifEditDrawer" type="primary" ghost @click="deleteSence">删除</a-button> -->
            <a-button v-if="!ifEditDrawer" type="primary"  @click="editSence">编辑</a-button>
            <a-button v-if="ifEditDrawer" type="primary" ghost @click="cancelEdit">取消</a-button>
            <a-button v-if="ifEditDrawer" type="primary" @click="saveEdit">保存</a-button>
        </div>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="setTimeModal" title="时间设定" footer='' width='1168px'>
            <div class="time-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="check-list">
                    <a-checkbox-group v-model="weekList">
                        <div class="check-item">
                            <a-checkbox value="MONDAY">
                                周一
                            </a-checkbox>
                        </div>
                        <div class="check-item">
                            <a-checkbox value="TUESDAY">
                                周二
                            </a-checkbox>
                        </div> 
                        <div class="check-item">
                            <a-checkbox value="WEDNESDAY">
                                周三
                            </a-checkbox>
                        </div>
                        <div class="check-item">
                            <a-checkbox value="THURSDAY">
                                周四
                            </a-checkbox>
                        </div> 
                        <div class="check-item">
                            <a-checkbox value="FRIDAY">
                                周五
                            </a-checkbox>
                        </div>
                        <div class="check-item">
                            <a-checkbox value="SATURDAY">
                                周六
                            </a-checkbox>
                        </div> 
                        <div class="check-item">
                            <a-checkbox value="SUNDAY">
                                周日
                            </a-checkbox>
                        </div>
                    </a-checkbox-group>
                </div>
                <div style="margin-bottom:12px;" v-for="(item,index) in timeList" :key='index'>
                    <div style="display:inline-block;width:160px" v-for="(item1,index1) in item" :key='index1'>
                        <a-time-picker style="width:72px;" v-model="item1.beginTime"  format="HH:mm" />
                        <span>-</span>
                        <a-time-picker style="width:72px;" v-model="item1.endTime"  format="HH:mm" />
                    </div>
                </div>
                <div style="margin-top:12px;">
                    <span @click="addTime" style="display:inline-block;width:144px;height:32px;color:rgba(24, 144, 255, 1);border:1px dotted rgba(24, 144, 255, 1);text-align:center;line-height:30px;cursor:pointer;border-radius:4px">+ 添加时间</span>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelCopyShare">取消</a-button>
                    <a-button type="primary" @click="sureCopyShare">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-drawer
            title=''
            placement="right"
            :closable="true"
            :visible="drawerVisibleSence"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onCloseDrawerSence"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="onCloseDrawerSence" type="close" />
                        </div>
                    </div>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <a-table
                            :columns="columns"
                            :data-source="data"
                            :pagination='false'
                            :scroll="{ x: 'max-content' }"
                        />
                        <a-pagination
                            class="pagination-table"
                            show-size-changer
                            :total="totalData"
                            :show-total="totalData => `共 ${totalData} 条     `"
                            v-model="currentPage"
                            @change="pageChange"
                            @showSizeChange="onShowSizeChange"
                        />
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="join1Modal" title="加入号码-从别的模块导入" footer='' width='720px'>
            <div class="join1-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <a-row class="condition">
                    <a-col :span="12">
                        <div class="title title1">选择模块：</div>
                        <div class="content1">
                            <a-select placeholder='请选择模块' @change="changeSelectedModule" class="select-module">
                                <a-select-option :value="item.value" v-for="(item,index) in moduleList" :key='index'>
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                    <a-col :span="12">
                        <div class="title title3">选择字段：</div>
                        <div class="content3">
                            <a-select placeholder='请选择字段' v-model="currentSelectedWord" class="select-module">
                                <a-select-option :value="item.value" v-for="(item,index) in wordList" :key='index'>
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                    <a-col v-if="currentSelectedModule==7" :span="12" style="margin-top:12px;">
                        <div class="title title1">场景名称：</div>
                        <div class="content1">
                            <a-select placeholder='请选择场景' @change="changeSenceName" class="select-module">
                               <a-select-option :value="item.value" v-for="(item,index) in digitalEmployeeList" :key='index'>
                                {{item.name}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                </a-row>
                <a-row v-for="(item,index) in viewScreenData" :key='index' style="margin-bottom:12px">
                    <a-col :span="4">
                        {{item.fieldName}}
                    </a-col>
                    <a-col :span="6">
                        <a-select v-model="item.selectedCondition" style="width:100%;height:32px" @change="selectConChange(item,index)">
                            <a-select-option :value="item1.value" v-for="(item1,index1) in item.methodList" :key='index1'>
                                {{item1.name}}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="14" style="padding-left:12px;">
                        <Linkage ref="child4"></Linkage>
                    </a-col>
                </a-row>
                <div style="text-align:right;color:rgba(24, 144, 255, 1);font-size:14px;margin-top:16px;">
                    <span style="cursor:pointer" @click="newSetCondition">重置筛选条件</span>
                </div>
                <div>
                    <a-checkbox class="filter-data" v-model="ifNeedOtherFilter">
                        是否需要待呼号码去重
                    </a-checkbox>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelJoin1">取消</a-button>
                    <a-button type="primary" @click="sureJoin1">下一步</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="join2Modal" title="加入号码-从别的模块导入" footer='' width='720px'>
            <div class="join2-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div style="color:rgba(153, 153, 153, 1);font-size:14px;">共筛选出数据：</div>
                <div style="color:rgba(24, 144, 255, 1);font-size:14px;margin-top:8px">{{changedSelectedTotal}}条</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelJoin2">取消</a-button>
                    <a-button type="primary" @click="sureJoin2">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="normalModal1" title="加入号码-普通导入" footer='' width='600px'>
            <div class="normal-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="tab-normal">
                    <a-textarea v-model.trim="phoneInput" placeholder="输入号码" style="height:222px" />
                    <div style="margin-top:24px;color:rgba(153, 153, 153, 1);font-size:14px">输入的文本符合以下规范:</div>
                    <div style="color:rgba(80, 80, 80, 1);margin-top:8px;font-size:12px">一行一个电话号码<br>每行十一个数字</div>
                    <a-checkbox class="filter-data" v-model="needOriginRepeat">
                        是否需要原始数据去重
                    </a-checkbox>
                    <a-checkbox class="filter-data" v-model="ifNeedReduceNumber">
                        是否需要待呼号码去重
                    </a-checkbox>
                    <a-checkbox class="filter-data" v-model="ifNeedFilter">
                        是否需要话单去重
                    </a-checkbox>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelNormal">取消</a-button>
                    <a-button type="primary" @click="sureNormal">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="normalModal2" title="加入号码-普通导入" footer='' width='600px'>
            <div class="normal-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="tab-normal">
                    <div>
                        <span style="color:rgba(80, 80, 80, 1);font-size:14px">*为保证数据导入顺利，请先</span>
                        <a-button @click="downloadTemplate" icon="download" style="margin-left:8px">
                        下载模板
                        </a-button>
                    </div>
                    <div style="min-height:166px;margin-top:24px">
                        <a-upload-dragger
                            key={Math.random()}
                            name="file"
                            :multiple="false"
                            :customRequest="customRequest"
                            @change="onUpload"
                            ref="pathClear"
                        >
                            <p class="ant-upload-drag-icon">
                                <a-icon type="upload" />
                            </p>
                            <p class="ant-upload-text">
                                点击或将文件拖拽到这里上传
                            </p>
                            <p class="ant-upload-hint">
                                支持扩展名：.xls .xlsx
                            </p>
                        </a-upload-dragger>
                    </div>
                    <div style="margin-top:16px;color:rgba(153, 153, 153, 1);font-size:14px">上传的 Excel 表符合以下规范::</div>
                    <div style="color:rgba(80, 80, 80, 1);margin-top:8px;font-size:12px">文件大小不超过20MB<br>仅支持 (*.xls 和 *.xlsx)文件<br>请确保您需要导入的sheet表头中不包含空的单元格，否则该sheet页数据系统将不做导入</div>
                    <div v-if="isCustomForm!=1">
                        <a-checkbox class="filter-data" v-model="needOriginRepeat">
                            是否需要原始数据去重
                        </a-checkbox>
                        <a-checkbox class="filter-data" v-model="ifNeedReduceNumber">
                            是否需要待呼号码去重
                        </a-checkbox>
                        <a-checkbox class="filter-data" v-model="ifNeedFilter">
                            是否需要话单去重
                        </a-checkbox>
                    </div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelNormal">取消</a-button>
                    <a-button type="primary" @click="sureNormal">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="selectNumberModal" title="选择号码-根据条件确定过滤包" footer='' width='600px'>
            <div class="select-number-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <!-- <div class="select-list">
                    <span class="select-title">场景名称</span>
                    <a-input disabled class="select-content" v-model="senceName" placeholder="固定的值" />
                </div> -->
                <div class="select-list">
                    <span class="select-title">最后外呼日期</span>
                    <div class="select-content">
                        <a-input-number v-model="selectNumberObj.lastCallDate" class="select-input" :min="0" :max="90"/>
                        <span>天以内（最大90天）</span>
                    </div>
                </div>
                <div class="select-list">
                    <span class="select-title">最后接听时长</span>
                    <div class="select-content">
                        <a-input-number v-model="selectNumberObj.lastAnswerDuration1" class="select-input" :min="0"/>
                        <span>秒</span>
                        <span style="margin:0 12px">-</span>
                        <a-input-number v-model="selectNumberObj.lastAnswerDuration2" class="select-input" :min="0"/>
                        <span>秒</span>
                    </div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelSelect">取消</a-button>
                    <a-button type="primary" @click="nextSelect">下一步</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="progressModal" title="加入号码-进度条" footer='' width='600px'>
            <div class="progress-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="progress-content">
                    <div class="progress-step">
                        <div :class="{'active':importInfoData.step1Msg!=''}">1</div>
                        <span :class="{'active':importInfoData.step2Msg!=''}"></span>
                        <div :class="{'active':importInfoData.step2Msg!=''}">2</div>
                        <span :class="{'active':importInfoData.step3Msg!=''}" v-if="ifNeedReduceNumber"></span>
                        <div :class="{'active':importInfoData.step3Msg!=''}" v-if="ifNeedReduceNumber">3</div>
                        <span :class="{'active':importInfoData.step4Msg!=''}"></span>
                        <div :class="{'active':importInfoData.step4Msg!=''}">4</div>
                        <span :class="{'active':importInfoData.step5Msg!=''}" v-if="ifNeedFilter"></span>
                        <div :class="{'active':importInfoData.step5Msg!=''}" v-if="ifNeedFilter">5</div>
                    </div>
                    <div class="progress-body">
                        <div class="progress-list">
                            <div class="progress-title">初始化</div>
                            <div>
                                <span v-html="importInfoData.step1Msg" style="font-size:14px;color:rgba(166, 166, 166, 1)"></span>
                            </div>
                        </div>
                        <div class="progress-list">
                            <div class="progress-title">去除异常数据及文件内去重</div>
                            <div>
                                <span v-html="importInfoData.step2Msg" style="font-size:14px;color:rgba(166, 166, 166, 1)"></span>
                            </div>
                        </div>
                        <div class="progress-list"  v-if="ifNeedReduceNumber">
                            <div class="progress-title">待呼号码去重（当前任务内）</div>
                            <div>
                                <span v-html="importInfoData.step3Msg" style="font-size:14px;color:rgba(166, 166, 166, 1)"></span>
                            </div>
                        </div>
                        <div class="progress-list" v-if="ifNeedFilter">
                            <div class="progress-title">冷却去重（当前场景内）</div>
                            <div>
                                <span v-html="importInfoData.step4Msg" style="font-size:14px;color:rgba(166, 166, 166, 1)"></span>
                            </div>
                        </div>
                        <div class="progress-list">
                            <div class="progress-title">导入</div>
                            <div>
                                <span v-html="importInfoData.step5Msg" style="font-size:14px;color:rgba(166, 166, 166, 1)"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelProgress">取消</a-button>
                    <a-button type="primary" :disabled='importInfoData.step5Msg==""' @click="completeProgress">完成</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="progressOtherModal" title="加入号码-进度条" footer='' width='600px'>
            <div class="progress-modal modal-public" style="height:298px">
                <a-divider class="divider1" type="horizontal" />
                <div class="progress-content" style="height:190px">
                    <div class="progress-step">
                        <div :class="{'active':importOtherInfoData.step1Msg!=''}">1</div>
                        <span :class="{'active':importOtherInfoData.step2Msg!=''}"></span>
                        <div :class="{'active':importOtherInfoData.step2Msg!=''}">2</div>
                    </div>
                    <div class="progress-body">
                        <div class="progress-list">
                            <div class="progress-title">筛选数据</div>
                            <div>
                                <span v-html="importOtherInfoData.step1Msg" style="font-size:14px;color:rgba(166, 166, 166, 1)"></span>
                            </div>
                        </div>
                        <div class="progress-list">
                            <div class="progress-title">导入</div>
                            <div>
                                <span v-html="importOtherInfoData.step2Msg" style="font-size:14px;color:rgba(166, 166, 166, 1)"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" @click="completeOtherProgress">完成</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="chooseAccountModal" :closable='false' footer='' :zIndex='10000000' width='1020px'>
            <div class="choose-account-modal">
                <div class="add-title">
                    <span>选外显号码</span>
                    <a-icon @click="cancelChooseAccount" type="close" />
                </div>
                <div class="choose-account-data">
                    <a-row>
                        <a-col :span="13">
                            <div class="account-left-content">
                                <div class="sort-condition">
                                    <a-input v-model="searchNum" @input='inputChange()' placeholder="搜索号码" />
                                    <span @click="selectSortCondition(1)" :class="{'active':sortField=='remainingMinutes'}" class="sort-list">剩余分钟数</span>
                                    <span @click="selectSortCondition(2)" :class="{'active':sortField=='callCount'}" class="sort-list">近期外呼数量</span>
                                    <span @click="selectSortMode(1)"  :class="{'active':sortMode=='asc'}" class="sort-list">升序</span>
                                    <span @click="selectSortMode(2)" :class="{'active':sortMode=='desc'}" class="sort-list">降序</span>
                                </div>
                                <div class="account-table">
                                    <a-table
                                        :row-selection="{ selectedRowKeys: selectedRowKeys1, onChange: onSelectChange1 }"
                                        :columns="columns1"
                                        :data-source="numberData1"
                                        :pagination='false'
                                        scroll="{ x: '100%', y: '240'}"
                                        :rowKey="(record,index)=>{return record.id}"
                                    >
                                    </a-table>
                                    <div class="pagination-table">
                                        <a-pagination
                                            show-size-changer
                                            :total="totalNumber"
                                            :show-total="totalNumber => `共 ${totalNumber} 条     `"
                                            v-model="currentPage2"
                                            @change="pageChange2"
                                            @showSizeChange="onShowSizeChange2"
                                            :pageSizeOptions="['10', '50', '100', '200']"
                                        />
                                    </div>
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="11">
                            <div class="account-right-content">
                                <div class="choosed-title">
                                    <span>已选择：{{ selectedRowKeys1.length }}条</span>
                                    <span @click="cleanSelected">清空</span>
                                </div>
                                <div class="choosed-content">
                                    <div class="choosed-item" v-for="(item,index) in selectedRowKeys1" :key='index'>
                                        <a-icon class="choosed-phone" type="phone" />
                                        <span class="choosed-number">{{dataObj[item]}}</span>
                                        <a-icon class="choosed-close" @click="deleteSelectedNum(index)" type="close" />
                                    </div>
                                </div>
                            </div>
                        </a-col>
                    </a-row>
                </div>
                <div class="btn-group">
                    <div>
                        <a-button type="default" @click="cancelChooseAccount">取消</a-button>
                        <a-button type="primary" @click="sureChooseAccount">确认</a-button>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
    import moment from 'moment';
    import Linkage from '../../components/Linkage'
    // import OutLink from "../../components/OutLink";
    import OutLinkNew from "../../components/OutLinkNew";
    export default {
        name: 'OutCallDetail',
        components: {Linkage, OutLinkNew},
        data() {
            return {
                ifEditDrawer: false,
                setTimeModal: false,
                drawerVisibleSence: false,
                drawerVisibleRecord: false,
                data: null,
                columns: null,
                join1Modal: false,
                join2Modal: false,
                normalModal: false,
                dataId: '',
                moduleId: '',
                digitalDetail: {
                    dataTitle: ''
                },
                taskNumData: '',
                moduleNumList: [],
                total: 0,
                currentSelectModule: null,
                timeList: [],
                weekList: [],
                taskName: undefined,
                taskDes: undefined,
                chargePerson: undefined,
                callType: null,
                phoneInput: '',
                currentTab: '1',
                newFormData: '',
                moduleList: [],
                currentSelectedModule: '',
                wordList: [],
                currentSelectedWord: undefined,
                currentSelectedSence: undefined,
                digitalEmployeeList: [],
                viewScreenData: [],
                conditionList: [],
                changedSelectedTotal: null,
                operationAuthority: '',
                copyData: [],
                totalData: 0,
                timer: null,
                currentPage: 0,
                ifNeedFilter: false,
                selectNumberModal: false,
                progressModal: false,
                currentStep: 1,
                selectNumberObj: {
                    lastCallDate: null,
                    lastAnswerDuration1: null,
                    lastAnswerDuration2: null,
                },
                timer2: null,
                importInfoData: {
                    canImport: false,
                    step1Msg: '正在初始化数据...请稍后',
                    step2Msg: '',
                    step3Msg: '',
                    step4Msg: '',
                    step5Msg: '',
                },
                ifComplete: false,
                ifNeedReduceNumber: false,
                ifNeedOtherFilter: false,
                progressOtherModal: false,
                importOtherInfoData: {
                    canImport: false,
                    step1Msg: '正在初始化数据...请稍后',
                    step2Msg: '',
                },
                timer3: null,
                senceName: '',
                originFormData: '',
                selectedRowKeys1: [],
                chooseAccountModal: false,
                numberData1: [],
                timer4: null,
                searchId: '',
                dataObj: {},
                searchNum: '',
                columns1: [
                     {
                        dataIndex: 'id',
                        key: 'id',
                        title: 'id',
                        colSpan: 0,
                        customRender: () => {
                            return {
                                attrs: {
                                    colSpan: 0,
                                },
                            };
                        },
                    },
                    {
                        dataIndex: 'number',
                        scopedSlots: {
                            customRender: 'number'
                        },
                        key: 'number',
                        title: '号码'
                    },
                    {
                        dataIndex: 'callCount',
                        scopedSlots: {
                            customRender: 'callCount'
                        },
                        key: 'callCount',
                        title: '近90天外呼次数'
                    },
                    {
                        dataIndex: 'expirationDate',
                        scopedSlots: {
                            customRender: 'expirationDate'
                        },
                        key: 'expirationDate',
                        title: '有效期'
                    },
                    {
                        dataIndex: 'remainingMinutes',
                        scopedSlots: {
                            customRender: 'remainingMinutes'
                        },
                        key: 'remainingMinutes',
                        title: '剩余分钟数'
                    },
                ],
                totalNumber: 0,
                currentPage2: 1,
                pageSize2: 10,
                sortField: '',
                sortMode: '',
                flag: 0,
                needOriginRepeat: true,
                voiceUrl: '',
                noticeSelect: undefined,
                noticeContent: undefined,
                noticeDes: '',
                ttsValue: '',
                speedValue: '',
                audio: null, // Audio 对象
                isCustomForm: '',
                wiringGroupList: [],
                statisticData: '',
                ifSign: 2,
                ifShowNoData: false,
                lineGroupId: '',
                editPermissionValue: 0,
                normalModal1: false,
                normalModal2: false,
                currentSelectedModal: '',
                timeItem: null,
                changeOrigin: ''
            }
        },
        methods: {
            moment,
            callOperate(type) {
                this.axios.post('/api/autoCallTask/updateStatus', {
                    id: this.dataId,
                    status: type,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.callType = type
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            queryCurrentState() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/autoCallTask/status?id='+this.dataId, 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.callType = response.data.data.status
                        this.isCustomForm = response.data.data.isCustomForm
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            ttsChange(value) {
                this.ttsValue = value
            },
            speedChange(value) {
                this.speedValue = value
            },
            editSence() {
                this.ifEditDrawer = true
                this.queryAccountData()
            },
            deleteSence() {
                this.axios.post('/api/module/data/delete/batch', {
                    moduleId: this.moduleId,
                    idList: [this.dataId],
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.$router.go(-1)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            saveEdit() {
                this.ifEditDrawer = false
                this.axios.post('/api/autoCallTask/update', {
                    id: this.dataId,
                    name: this.taskName,
                    description: this.taskDes,
                    owner: this.chargePerson,
                    workTime: {
                        weekList: this.weekList,
                        timeList: this.timeList,
                    },
                    numberList: this.selectedRowKeys1,
                    speed: this.speedValue,
                    wiringGroupId: this.lineGroupId
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('保存成功')
                        this.taskName = undefined,
                        this.taskDes = undefined,
                        this.chargePerson = undefined,
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            saveEdit2() {
                this.axios.post('/api/autoCallTask/update', {
                    id: this.dataId,
                    name: undefined,
                    description: undefined,
                    owner: undefined,
                    workTime: {
                        weekList: this.weekList,
                        timeList: this.timeList,
                    },
                    numberList: undefined,
                    speed: undefined,
                    wiringGroupId: undefined
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('保存成功')
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelEdit() {
                this.ifEditDrawer = false
            },
            setTimeOperate() {
                this.setTimeModal = true
            },
            onCloseDrawerSence() {
                this.drawerVisibleSence = false
                this.currentSelectModule = null
            },
            viewSenceDetail(index,item) {
                this.drawerVisibleSence = true
                this.currentSelectModule = index
                this.associatedModuleId = item.id
                this.queryTableData()
            },
            queryTableData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/associatedModule/data/List', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.dataId,
                            associatedModuleId: this.associatedModuleId,
                            current: this.currentPage,
                            size: this.currentSize,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.columns = response.data.data.tableHeader
                        this.data = response.data.data.tableData.records
                        this.totalData = response.data.data.tableData.total
                        for(let i = 0; i < this.columns.length; i++){
                            if(this.columns[i]['title'] == 'id'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    colSpan:0,  
                                    customRender: () => {
                                        return {
                                            attrs: {
                                                colSpan: 0,
                                            },
                                        };
                                    },
                                }
                            }
                        }
                        this.total = response.data.data.tableData.total
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onShowSizeChange(current, pageSize) {
                this.currentPage = current
                this.currentSize = pageSize
                this.queryTableData()
            },
            pageChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryTableData()
            },
            downloadOperate(e) {
                this.originFormData = ''
                this.newFormData = ''
                if(e.key == '1'){
                    this.selectNumberObj = {
                        lastCallDate: null,
                        lastAnswerDuration1: null,
                        lastAnswerDuration2: null,
                    }
                    this.queryImportInfo('init')
                }else if(e.key == '2'){
                    this.queryIfCanImport('init')
                }
            },
            addNumber() {
                this.selectNumberObj = {
                    lastCallDate: null,
                    lastAnswerDuration1: null,
                    lastAnswerDuration2: null,
                }
                this.currentTab = 2
                this.queryImportInfo('init')
            },
            cancelJoin1() {
                this.join1Modal = false
            },
            sureJoin1() {
                this.join1Modal = false
                this.join2Modal = true
                let data = this.$refs.child4
                let arr = []
                for(let i = 0; i < data.length; i++){
                    let obj = {}
                    if(data[i]['_data']['viewportData']['selectedCondition']!='ALL'&&data[i]['_data']['viewportData']['selectedCondition']!=undefined){
                        obj.fieldId = data[i]['_data']['viewportData']['fieldId']
                        obj.fieldMethod = data[i]['_data']['viewportData']['selectedCondition']
                        obj.valueList = data[i]['_data']['viewportData']['valueList']
                        arr.push(obj)
                    }
                }
                this.conditionList = arr
                this.axios.post('/api/autoWaitingCall/import/otherModule/dataCount', {
                    moduleId: this.currentSelectedModule,
                    callTaskId: this.dataId,
                    digitalEmployeeId: this.currentSelectedSence,
                    phoneFieldId: this.currentSelectedWord,
                    conditionList: this.conditionList,
                    needRemoveWaitingCallRepeat: this.ifNeedOtherFilter?true:false,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.changedSelectedTotal = response.data.data.count
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelJoin2() {
                this.join2Modal = false
            },
            sureJoin2() {
                this.join2Modal = false
                if(this.changedSelectedTotal != 0){
                    this.timer2 = setInterval(()=>{
                        this.queryIfCanImport()
                    },2000)
                    this.axios.post('/api/autoWaitingCall/import/otherModule', {
                        moduleId: this.currentSelectedModule,
                        callTaskId: this.dataId,
                        digitalEmployeeId: this.currentSelectedSence,
                        phoneFieldId: this.currentSelectedWord,
                        conditionList: this.conditionList,
                        needRemoveWaitingCallRepeat: this.ifNeedOtherFilter?true:false,
                    })
                    .then(response=> {
                        setTimeout(()=>{
                            clearInterval(this.timer2)
                        },2000)
                        if(response.data.code == 0){
                            this.progressOtherModal = true
                            this.queryIfCanImport()
                            this.queryTaskNum()
                            this.queryDataNum()
                        }else{
                            this.$message.error(response.data.userTip);
                        }
                    })
                    .catch(error=> {
                        setTimeout(()=>{
                            clearInterval(this.timer2)
                        },2000)
                        console.log(error);
                    });
                }
            },
            cancelNormal() {
                this.normalModal1 = false
                this.normalModal2 = false
            },
            sureNormal() {
                if(this.currentSelectedModal==2&&this.originFormData == ''){
                    this.$message.error('请重新上传文件')
                    return false
                }
                this.normalModal1 = false
                this.normalModal2 = false
                if(this.ifNeedFilter){
                    this.senceName = ''
                    this.selectNumberModal = true
                    // this.querySence()
                }else{
                    this.upLoadData()
                    this.importInfoData = {
                        canImport: false,
                        step1Msg: '正在初始化数据...请稍后',
                        step2Msg: '',
                        step3Msg: '',
                        step4Msg: '',
                        step5Msg: '',
                    }
                    this.progressModal = true
                    this.ifComplete = false
                }
            },
            querySence() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/employee/name', 
                        params: {
                            callTaskId: this.dataId,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.senceName = response.data.data.digitalEmployeeName
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            upLoadData() {
                if(this.currentSelectedModal == 1){
                    this.timer2 = setInterval(()=>{
                        this.queryImportInfo()
                    },2000)
                    let arr = this.phoneInput.split('\n')
                    let newArr = []
                    for(let i = 0; i < arr.length; i++){
                        if(arr[i].replace(/\s/g,"").length != 0){
                            newArr.push(arr[i])
                        }
                    }
                    this.axios.post('/api/autoWaitingCall/import/text', {
                        callTaskId: this.dataId,
                        phoneList: newArr,
                        needRemoveRepeat: this.ifNeedFilter?true:false,
                        day: this.ifNeedFilter?this.selectNumberObj.lastCallDate:null,
                        duration1: this.ifNeedFilter?this.selectNumberObj.lastAnswerDuration1:null,
                        duration2: this.ifNeedFilter?this.selectNumberObj.lastAnswerDuration2:null,
                        needRemoveWaitingCallRepeat: this.ifNeedReduceNumber?true:false,
                        needOriginRepeat: this.needOriginRepeat?true:false
                    })
                    .then(response=> {
                        setTimeout(()=>{
                            clearInterval(this.timer2)
                        },2000)
                        if(response.data.code == 0){
                            this.phoneInput = ''
                            this.ifComplete = true
                            this.queryImportInfo()
                            this.queryTaskNum()
                            this.queryDataNum()
                        }else{
                            this.$message.error(response.data.userTip);
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                        setTimeout(()=>{
                            clearInterval(this.timer2)
                        },2000)
                    });
                }else{
                    this.axios.post('/api/autoWaitingCall/addImportExcelInfo', {
                        callTaskId: this.dataId,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.successInsertInfo()
                            this.timer2 = setInterval(()=>{
                                this.queryImportInfo()
                            },2000)
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            successInsertInfo() {
                const formData = new FormData() 
                formData.append('file', this.originFormData.file)
                formData.append('callTaskId', this.dataId)
                formData.append('needRemoveRepeat', this.ifNeedFilter)
                formData.append('day', this.selectNumberObj.lastCallDate)
                formData.append('duration1', this.selectNumberObj.lastAnswerDuration1)
                formData.append('duration2', this.selectNumberObj.lastAnswerDuration2)
                formData.append('needRemoveWaitingCallRepeat', this.ifNeedReduceNumber)
                formData.append('needOriginRepeat', this.needOriginRepeat)
                this.newFormData = formData
                this.axios({
                    method: 'post',
                    url: '/api/autoWaitingCall/import/excel',
                    headers:{
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    data: this.newFormData,
                }).then((response) => {
                    setTimeout(()=>{
                        clearInterval(this.timer2)
                    },2000)
                    if(response.data.code == 0){
                        this.newFormData = ''
                        this.originFormData = ''
                        this.queryImportInfo()
                        this.queryTaskNum()
                        this.queryDataNum()
                        this.ifComplete = true
                    }else{
                        this.$message.error(response.data.userTip);
                        this.progressModal = false
                    }
                }).catch((error)=> {
                    console.log(error)
                    this.progressModal = false
                    setTimeout(()=>{
                        clearInterval(this.timer2)
                    },2000)
                })
            },
            onUpload() {
                this.$refs.pathClear.value = ''
            },
            customRequest(data) { // 上传提交
                this.newFormData = ''
                this.originFormData = data
            },
            querySelectData() {
                this.axios(
                   {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.dataId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        let list = response.data.data.detailList
                        if(list.length > 0){
                            for(let i = 0; i < list.length; i++){
                                if(list[i]['code'] == 'workTime'){
                                    this.timeItem = list[i]
                                }
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryTaskNum() {
                this.axios(
                   {
                        method:'get',
                        url:'/api/autoCallTask/count', 
                        params: {
                            id: this.dataId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.taskNumData = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryModuleList(arr) {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/associatedModuleList', 
                        params: {
                            moduleId: this.moduleId,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let data = response.data.data
                        for(let i = 0; i < data.length; i++){
                            for(let j = 0; j < arr.length; j++){
                                if(data[i]['id'] == arr[j]['moduleId']){
                                    data[i]['num'] = arr[j]['count']
                                }
                            }
                        }
                        this.moduleNumList = data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryDataNum() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/associatedModule/data/countList', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.dataId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let data = response.data.data
                        let arr = []
                        for(let i = 0; i < data.length; i++){
                            let obj = {}
                            obj.count = data[i]['count']
                            obj.moduleId = data[i]['id']
                            arr.push(obj)
                        }
                        this.queryModuleList(arr)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            cancelCopyShare() {
                this.setTimeModal = false
            },
            sureCopyShare() {
                console.log(this.timeList)
                this.setTimeModal = false
                for(let i = 0; i < this.timeList.length; i++){
                    for(let j = 0; j < this.timeList[i].length; j++){
                        if(this.timeList[i][j]['beginTime']!=null){
                            this.timeList[i][j]['beginTime'] = moment(this.timeList[i][j]['beginTime'], 'HH:mm').format('HH:mm')
                        }
                        if(this.timeList[i][j]['endTime']!=null){
                            this.timeList[i][j]['endTime'] = moment(this.timeList[i][j]['endTime'], 'HH:mm').format('HH:mm')
                        }
                    }
                }
                if(this.changeOrigin == 2){
                    this.saveEdit2()
                }
            },
            changeTime(item, type) {
                this.changeOrigin = type
                this.timeList = item.value.timeList
                for(let i = 0; i < this.timeList.length; i++){
                    for(let j = 0; j < this.timeList[i].length; j++){
                        if(this.timeList[i][j]['beginTime']!=null){
                            this.timeList[i][j]['beginTime'] = moment(this.timeList[i][j]['beginTime'], 'HH:mm')
                            this.timeList[i][j]['endTime'] = moment(this.timeList[i][j]['endTime'], 'HH:mm')
                        }
                    }
                }
                this.weekList = item.value.weekList
                this.setTimeModal = true
            },
            addTime() {
                let arr = []
                arr = [
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                ]
                this.timeList.push(arr)
            },
            changeTaskName(e) {
                this.taskName = e.target.value
            },
            changeDes(e) {
                this.taskDes = e.target.value
            },
            changeNotice(e) {
                this.noticeDes = e.target.value
            },
            changePerson(e) {
                this.chargePerson = e
            },
            downloadTemplate() {
                window.open('/api/autoWaitingCall/download/template/'+this.dataId, '_blank');
            },
            getModuleList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/list/containPhone', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.moduleList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            changeSelectedModule(val) {
                this.currentSelectedModule = val
                this.wordList = []
                this.currentSelectedWord = undefined
                this.queryWord()
                this.queryScreenData()
                if(val == 7){
                    this.querySenceList()
                }else{
                    this.currentSelectedSence = undefined
                }
            },
            changeSenceName(val) {
                this.currentSelectedSence = val
                this.queryScreenData2()
            },
            queryWord() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.currentSelectedModule+'/phoneField/list', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.wordList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            querySenceList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/employee/select/list', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalEmployeeList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            changeSelectedWord(val) {
                this.currentSelectedWord = val
            },
            queryScreenData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.currentSelectedModule+'/conditionList', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.viewScreenData = this.deepClone(response.data.data)
                        this.copyData = this.deepClone(response.data.data)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryScreenData2() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.currentSelectedModule+'/conditionList', 
                        params: {
                            digitalEmployeeId: this.currentSelectedSence,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.viewScreenData = this.deepClone(response.data.data)
                        this.copyData = this.deepClone(response.data.data)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            newSetCondition() {
                let data = this.deepClone(this.copyData)
                this.viewScreenData = data
                for(let i = 0; i < this.viewScreenData.length; i++){
                    this.$refs.child4[i].getPassData(this.viewScreenData[i],i)
                }
                this.$forceUpdate()
            },  
            deepClone(obj){
                let _obj = JSON.stringify(obj),
                objClone = JSON.parse(_obj);
                return objClone
            },
            selectConChange(item, index) {
                this.$refs.child4[index].getPassData(item,index)
            },
            queryAuthority() {
                this.axios(
                    {
                        method:'get',
                        url:'api/module/'+this.moduleId+'/operationAuthority', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.operationAuthority = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            cancelSelect() {
                this.selectNumberModal = false
            },
            nextSelect() {
                this.upLoadData()
                this.selectNumberModal = false
                this.importInfoData = {
                    canImport: false,
                    step1Msg: '正在初始化数据...请稍后',
                    step2Msg: '',
                    step3Msg: '',
                    step4Msg: '',
                    step5Msg: '',
                }
                this.progressModal = true
                this.ifComplete = false
            },
            cancelProgress() {
                if(this.ifComplete){
                    this.progressModal = false
                }else{
                    this.cancelImport()
                }
            },
            completeProgress() {
                this.progressModal = false
            },
            cancelImport() {
                this.axios.post('/api/autoWaitingCall/cancel', {
                    callTaskId: this.dataId,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        if(response.data.data.result){
                            this.$message.success('取消成功')
                        }else{
                            this.$message.error('当前已无法取消');
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            queryImportInfo(params) {
                this.axios(
                   {
                        method:'get',
                        url:'/api/autoWaitingCall/importInfo', 
                        params: {
                            callTaskId: this.dataId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        if(params == 'init'){
                            if(response.data.data.canImport){
                                this.normalModal = true
                                this.needOriginRepeat = true
                                this.ifNeedFilter = false
                                this.ifNeedReduceNumber = false
                            }else{
                                this.progressModal = true
                            }
                        }
                        this.importInfoData.canImport = response.data.data.canImport
                        this.importInfoData.step1Msg = response.data.data.step1Msg
                        this.importInfoData.step2Msg = response.data.data.step2Msg
                        this.importInfoData.step3Msg = response.data.data.step3Msg
                        this.importInfoData.step4Msg = response.data.data.step4Msg
                        this.importInfoData.step5Msg = response.data.data.step5Msg
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryIfCanImport(params) {
                this.axios(
                    {
                        method:'get',
                        url:'/api/autoWaitingCall/otherModuleImportInfo', 
                        params: {
                            callTaskId: this.dataId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        if(params == 'init'){
                            if(response.data.data.canImport){
                                this.join1Modal = true
                            }else{
                                this.progressOtherModal = true
                            }
                        }
                        this.importOtherInfoData.canImport = response.data.data.canImport
                        this.importOtherInfoData.step1Msg = response.data.data.step1Msg
                        this.importOtherInfoData.step2Msg = response.data.data.step2Msg
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            completeOtherProgress() {
                this.progressOtherModal = false
            },
            cancelChooseAccount() {
                this.chooseAccountModal = false
            },
            sureChooseAccount() {
                this.chooseAccountModal = false
            },
            onSelectChange1(selectedRowKeys) {
                this.selectedRowKeys1 = selectedRowKeys;
            },
            selectNumberFun() {
                this.chooseAccountModal = true
                this.flag = 0
                this.queryAccountData()
            },
            queryAccountData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/autoCallTask/callingNumberList', 
                        params: {
                            number: this.searchNum,
                            callTaskId: this.changeNumberType=='new'?'':this.dataId,
                            current: this.currentPage2,
                            size: this.pageSize2,
                            sortField: this.sortField,
                            sortMode: this.sortMode
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.numberData1 = response.data.data.allCallingNumberList.records
                        let selectedData = response.data.data.callTaskNumberList
                        this.totalNumber = response.data.data.allCallingNumberList.total
                        for(let i = 0; i < this.numberData1.length; i++){
                            this.dataObj[this.numberData1[i]['id']] = this.numberData1[i]['number']
                        }
                        if(this.flag < 1){
                            this.selectedRowKeys1 = []
                            for(let i = 0; i < selectedData.length; i++){
                                this.dataObj[selectedData[i]['id']] = selectedData[i]['number']
                            }
                            for(let i = 0; i < selectedData.length; i++){
                                this.selectedRowKeys1.push(selectedData[i]['id'])
                            }
                            this.flag ++
                        }
                        
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            inputChange() {
                clearTimeout(this.timer4)
                this.timer4 = setTimeout(()=>{
                    this.queryAccountData()
                },1500)
            },
            deleteSelectedNum(index) {
                this.selectedRowKeys1.splice(index, 1);
            },
            cleanSelected() {
                this.selectedRowKeys1 = []
            },
            pageChange2(current, pageSize) {
                this.currentPage2 = current
                this.pageSize2 = pageSize
                this.queryAccountData()
            },
            onShowSizeChange2(current, pageSize) {
                this.currentPage2 = current
                this.pageSize2 = pageSize
                this.queryAccountData()
            },
            selectSortCondition(data) {
                if(data==1&&this.sortField=='remainingMinutes'||(data==2&&this.sortField=='callCount')){
                    this.sortField = ''
                }else if(data == 1&&this.sortField!='remainingMinutes'){
                    this.sortField = 'remainingMinutes'
                }else if(data == 2&&this.sortField!='callCount'){
                    this.sortField = 'callCount'
                }
                this.queryAccountData()
            },
            selectSortMode(data) {
                if(data==1&&this.sortMode=='asc'||(data==2&&this.sortMode=='desc')){
                    this.sortMode = ''
                }else if(data == 1&&this.sortMode!='asc'){
                    this.sortMode = 'asc'
                }else if(data == 2&&this.sortMode!='desc'){
                    this.sortMode = 'desc'
                }
                this.queryAccountData()
            },
            tryListen3(value) {
                if (!this.audio) {
                    // 如果 Audio 对象不存在，则创建一个新的对象
                    this.audio = new Audio(value)
                }
                // 播放音频
                this.audio.play()
            },
            queryWiringGroupList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/autoCallTask/wiringGroupList', 
                        params: {
                            autoCallTaskId: this.dataId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.wiringGroupList = response.data.data.userExtensionList
                        this.statisticData = response.data.data
                        if(this.wiringGroupList.length == 0){
                            this.ifShowNoData = true
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            changeMonitorState(value) {
                if(value){
                    this.ifSign = ''
                }else{
                    this.ifSign = 2
                }
                
                if(value){
                    if(this.wiringGroupList!=0){
                        this.ifShowNoData = false
                    }else{
                        this.ifShowNoData = true
                    }
                    
                }else{
                    let count = 0
                    for(let i = 0; i < this.wiringGroupList.length; i++){
                        if(this.wiringGroupList[i]['signStatus'] == 1||this.wiringGroupList[i]['signStatus'] == 3){
                            count++
                        }
                    }
                    if(count!=0){
                        this.ifShowNoData = false
                    }else{
                        this.ifShowNoData = true
                    }
                }
            },
            getClassByType(status, signStatus) {
                if(status == 1){
                    if(signStatus == 1){
                        return "state1"
                    }else if(signStatus == 2){
                        return "state4"
                    }else if(signStatus == 3){
                        return "state5"
                    }
                }else if(status == 2){
                    return "state2"
                }else if(status == 3){
                    return "state3"
                }
            },
            changeLineGroup(e) {
                this.lineGroupId = e
                console.log(this.lineGroupId)
            },
            changeStatus(id, value) {
                console.log(id)
                console.log(value.key)
                this.axios.post('/api/extension/updateUserSignStatus', {
                    userId: id,
                    status: value.key
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryWiringGroupList()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            queryEditPermission() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/autoCallTask/canEdit', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.editPermissionValue = response.data.data.canEdit
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            fastAddNumber() {
                this.ifNeedReduceNumber = false
                this.ifNeedFilter = false
                this.currentSelectedModal = 1

                this.originFormData = ''
                this.newFormData = ''
                this.selectNumberObj = {
                    lastCallDate: null,
                    lastAnswerDuration1: null,
                    lastAnswerDuration2: null,
                }
                this.queryImportInfo('init')
                this.normalModal1 = true
            },
            fixedTemplateImportNum() {
                this.ifNeedReduceNumber = false
                this.ifNeedFilter = false
                this.currentSelectedModal = 2

                this.originFormData = ''
                this.newFormData = ''
                this.selectNumberObj = {
                    lastCallDate: null,
                    lastAnswerDuration1: null,
                    lastAnswerDuration2: null,
                }
                this.queryImportInfo('init')
                this.normalModal2 = true
            },
            otherModulesImport() {
                this.queryIfCanImport('init')
            },
            setCallTime() {
                this.changeTime(this.timeItem, 2)
            },
        },
        mounted: function() {
            this.dataId = this.$route.query.id
            this.moduleId = this.$route.query.moduleId
            this.queryAuthority()
            this.querySelectData()
            this.queryTaskNum()
            this.queryDataNum()
            this.queryCurrentState()
            this.getModuleList()
            this.queryWiringGroupList()
            this.queryEditPermission()
            this.timer = setInterval(()=>{
                this.queryTaskNum()
            },60000)
            this.timer3 = setInterval(()=>{
                this.queryWiringGroupList()
            },3000)
        },
        beforeRouteLeave(to, from, next){
            next();
            clearInterval(this.timer);
            clearInterval(this.timer3);
        },
    }
</script>

<style scoped lang="less">
    .call-detail{
        height: 100%;
        position: relative;
        background: rgba(240, 242, 243, 1);
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                // float: right;
                // width: 64px;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                // float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 64px 16px 68px 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    overflow-y: auto;
                    display: flex;
                    .left-content{
                        width: 288px;
                        height: 100%;
                        // background: #fff;
                        // border-radius: 4px;
                        // padding: 21px 24px;
                        display: flex;
                        flex-direction: column;
                        .related-module{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                            span{
                                width: 3px;
                                height: 14px;
                                float: left;
                                background: rgba(24, 144, 255, 1);
                                margin: 4px 7px 0 0;
                            }
                        }
                        .statistics-item{
                            margin-bottom: 24px;
                            .statistics-title{
                                color: rgba(188, 188, 188, 1);
                                font-size: 12px;
                            }
                            .statistics-value{
                                color: rgba(51, 51, 51, 1);
                                font-size: 14px;
                                margin-top: 9px;
                            }
                        }
                        .module-list{
                            padding-top: 16px;
                            li{
                                cursor: pointer;
                                padding: 8px 0;
                                i{
                                    font-size: 16px;
                                    color: rgba(51, 51, 51, 1);
                                    margin-left: 24px;
                                }
                                div{
                                    display: inline-block;
                                    color: rgba(18, 18, 18, 1);
                                    font-size: 14px;
                                    margin-left: 6px;
                                }
                                span{
                                    display: inline-block;
                                    padding: 0 6px;
                                    height: 18px;
                                    text-align: center;
                                    line-height: 18px;
                                    background: rgba(245, 87, 78, 1);
                                    color: #fff;
                                    border-radius: 4px;
                                    float: right;
                                    margin-right: 10px;
                                }
                            }
                            li.active{
                                background: rgba(24, 144, 255, 0.1);
                                border-radius: 19px;
                                i{
                                    color: rgba(24, 144, 255, 1);
                                }
                                div{
                                    color: rgba(24, 144, 255, 1);
                                }
                            }
                        }
                    }
                    .center-content{
                        flex: 1;
                        height: 100%;
                        margin: 0 24px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        display: flex;
                        flex-direction: column;
                        .basic-data{
                            margin-bottom: 16px;
                            .data-content{
                                width: 100%;
                                height: 144px;
                                background: #fff;
                                padding: 18px 0 0 24px;
                                border-radius: 4px;
                                div:nth-child(1){
                                    color: rgba(18, 18, 18, 1);
                                    font-size: 14px;
                                }
                                div:nth-child(2){
                                    color: rgba(102, 102, 102, 1);
                                    font-size: 36px;
                                    margin-top: 23px;
                                }
                            }
                        }
                        .chart-list{
                            margin-bottom: 16px;
                            .title{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            .echart-item{
                                height: 256px;
                                background: #fff;
                                padding: 18px 0 0 24px;
                                #myChart1,#myChart2{
                                    height: 217px;
                                }
                            }
                        }
                        .basic-info{
                            width: 100%;
                            min-height: 378px;
                            background: #fff;
                            border-radius: 4px;
                            padding: 24px;
                            .title{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            .col-left{
                                padding-right: 24px;
                            }
                            .col-right{
                                padding-left: 24px;
                            }
                            .row-list{
                                .col-list{
                                    margin-top: 19px;
                                    margin-bottom: 5px;
                                    min-height: 69px;
                                    .first-content{
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                    }
                                    .second-content{
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        margin-top: 9px;
                                        white-space: nowrap;          /* 不换行 */
                                        overflow: hidden;             /* 隐藏超出部分 */
                                        text-overflow: ellipsis;      /* 使用省略号 */
                                        img{
                                            width: 21px;
                                        }
                                        .change-head{
                                            color: rgba(24, 144, 255, 1);
                                            font-size: 12px;
                                            cursor: pointer;
                                            margin-left: 24px;
                                        }
                                    }
                                    .selection-range{
                                        margin-top: 9px;
                                        width: 100%;
                                    }
                                }
                            }
                        }  
                        .monitor-content{
                            margin-top: 16px;
                            background: #fff;
                            border-radius: 4px;
                            padding: 18px 24px 32px;
                            .monitor-box{
                                height: 70px;
                                width: 226px;
                                border-radius: 4px;
                                padding: 10px 12px;
                                display: inline-block;
                            }
                            .state1{
                                border: 1px solid rgba(229, 229, 229, 1);
                                margin: 0 17px 18px 0;
                            }
                            .state2{
                                border: 1px solid rgba(255, 141, 26, 1);
                                border-left: 4px solid rgba(255, 141, 26, 1);
                                margin: 0 17px 18px 0;
                            }
                            .state3{
                                border: 1px solid rgba(10, 191, 91, 1);
                                border-left: 4px solid rgba(10, 191, 91, 1);
                                background: rgba(10, 191, 91, 0.05);
                                margin: 0 17px 18px 0;
                            }
                            .state4{
                                background: rgba(242, 243, 245, 1);
                                border: 1px solid rgba(229, 229, 229, 1);
                                margin: 0 17px 18px 0;
                            }
                            .state5{
                                border: 1px solid rgba(229, 229, 229, 1);
                                margin: 0 17px 18px 0;
                            }
                        }
                    }
                    .right-content{
                        width: 288px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        .data-info{
                            width: 100%;
                            height: 280px;
                            background: #fff;
                            border-radius: 4px;
                            margin-bottom: 16px;
                            padding: 18px 24px;
                            .related-info{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                            ul{
                                li{
                                    padding-top: 24px;
                                    div:nth-child(1){
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                        margin-bottom: 4px;
                                    }
                                    div:nth-child(2){
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        .fast-operate{
                            width: 100%;
                            flex: 1;
                            background: #fff;
                            padding: 18px 24px;
                            .fast-do{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .footer-edit{
            height: 52px;
            line-height: 52px;
            text-align: center;
            position: absolute;
            bottom: 0px;
            left: 0px;
            z-index: 100;
            width: 100%;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0 0 0 0.06);
            button{
                margin-right: 8px;
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .time-modal{
        padding-bottom: 46px;
        .check-list{
            .check-item{
                display: inline-block;
                width: 158px;
                height: 32px;
            }
        }
    }
    .drawer-public{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 56px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 71px 16px 16px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                div:nth-child(1){
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                div:nth-child(2){
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding: 24px;
            background: #fff;
            .pagination-table{
                float: right;
                padding-top: 24px;
            }
        }
    }
    .join1-modal{
        padding-bottom: 46px;
        .condition{
            margin-bottom: 24px;
            .title{
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                margin: 0 0 8px;
            }
            .title1{
                padding-right: 32px;
            }
            .title2{
                padding: 0 16px;
            }
            .title3{
                padding-left: 32px;
            }
            .content1{
                padding-right: 32px;
                color: rgba(121, 136, 155, 1);
                .select-module{
                    width: 100%;
                }
            }
            .content2{
                padding: 0 16px;
                color: rgba(153, 153, 153, 1);
            }
            .content3{
                padding-left: 32px;
                color: rgba(153, 153, 153, 1);
                .select-module{
                    width: 100%;
                }
            }
        }
    }
    .join2-modal{
        padding-bottom: 46px;
    }
    .normal-modal{
        padding-bottom: 46px;
        .tab-normal{
            height: 400px;
            .filter-data{
                margin-top: 5px;
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
            }
        }
    }
    .select-number-modal{
        height: 185px;
        .select-list{
            color: rgba(80, 80, 80, 1);
            margin-bottom: 12px;
            display: flex;
            line-height: 32px;
            .select-title{
                display: inline-block;
                width: 90px;
            }
            .select-content{
                flex: 1;
                .select-input{
                    width: 120px;
                    margin-right: 12px;
                }
            }
        }
    }
    .progress-modal{
        // height: 483px;
        padding-bottom: 45px;
        .progress-content{
            // height: 375px;
            display: flex;
            .progress-step{
                display: inline-block;
                width: 24px;
                text-align: center;
                div{
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    color: #fff;
                    background: rgba(204, 204, 204, 1);
                    float: left;
                    border-radius: 12px;
                }
                span{
                    display: inline-block;
                    width: 2px;
                    height: 60px;
                    background: rgba(204, 204, 204, 1);
                    float: left;
                    margin-left: 11px;
                }
                span.active{
                    background: rgba(24, 144, 255, 1);
                }
                div.active{
                    color: #fff;
                    background: rgba(24, 144, 255, 1);
                }
            }
            .progress-body{
                margin-left: 11px;
                flex: 1;
                .progress-list{
                    height: 84px;
                    .progress-title{
                        color: rgba(18, 18, 18, 1);
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .choose-account-modal{
        .add-title{
            padding: 20px 20px 14px 0px;
            border-bottom: 1px solid rgba(229, 229, 229, 1);
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 14px;
                font-weight: bold;
            }
            i{
                float: right;
                color: rgba(158, 172, 196, 1);
                font-size: 16px;
                cursor: pointer;
            }
        }
        .choose-account-data{
            padding: 24px 0;
            .account-left-content{
                padding-right: 24px;
                border-right: 1px solid rgba(229, 229, 229, 1);
                .account-table{
                    margin-top: 24px;
                }
                .pagination-table{
                    float: right;
                    padding-top: 24px;
                }
                .sort-condition{
                    input{
                        width: 150px;
                    }
                    .sort-list{
                        display: inline-block;
                        padding: 0 12px;
                        height: 32px;
                        line-height: 32px;
                        background: #fafafa;
                        margin-left: 10px;
                        font-size: 14px;
                        cursor: pointer;
                        border-radius: 4px;
                    }
                    span.active{
                        background: #1890FF;
                        color: #fff;
                    }
                }
            }
            .account-right-content{
                padding-left: 24px;
                .choosed-title{
                    span:nth-child(1){
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                    }
                    span:nth-child(2){
                        color: rgba(24, 144, 255, 1);
                        font-size: 14px;
                        float: right;
                        cursor: pointer;
                    }
                }
                .choosed-content{
                    margin-top: 24px;
                    max-height: 305px;
                    overflow-y: auto;
                    .choosed-item{
                        display: inline-block;
                        min-width: 132px;
                        height: 32px;
                        margin: 0 8px 8px 0;
                        background: rgba(121, 136, 155, 0.1);
                        text-align: center;
                        line-height: 32px;
                        padding: 0 8px;
                        .choosed-phone{
                            color: rgba(121, 136, 155, 1);
                            font-size: 16px;
                            float: left;
                            margin-top: 7px;
                        }
                        .choosed-number{
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            display: inline-block;
                            min-width: 84px;
                            overflow: hidden;
                        }
                        .choosed-close{
                            color: rgba(153, 153, 153, 1);
                            font-size: 14px;
                            float: right;
                            margin-top: 8px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .btn-group{
            border-top: 1px solid rgba(229, 229, 229, 1);
            padding: 16px 20px 20px 0;
            height: 58px;
            div{
                float: right;
            }
            button{
                display: inline-block;
                width: 64px;
                height: 28px;
                margin-left: 8px;
                font-size: 13px;
            }
            button:nth-child(1){
                color: rgba(158, 172, 196, 1);
            }
        }
    }
</style>